import React, { Component } from "react";
import { Container, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Label, FormGroup, FormFeedback } from 'reactstrap';
import { getUserDevicesByEmail } from '../utils/userAPI';
import { deletehttp } from '../utils/deviceAPI';
import { updateAttributeHttp } from '../utils/deviceAPI';
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

export class DeviceSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            isSuccess: false,
            isValid : false,
            fetchedData: [],
            modalAttribute: false,
            modalDelete: false,
            formInputs: {
                accountId: '',
                thingName: '',
                thingDescription: '',
                thingTimeZone: 0,
                thingTemperature: '',
            },
        };
    }

    componentDidMount() {
        this.getUserDevicesInfo()
    }
    getUserDevicesInfo = async () => {
        let user = this.context.auth.username
        if (user) {
            getUserDevicesByEmail(user).then((data) => {
                this.setState({
                    fetchedData: data.deviceList,
                });
            }).catch(err => {
                console.log(err.message)
            })
        }
    }

    handleChange = e => {
        const value = e.target.value;
        const name = e.target.name
        this.setState({
            formInputs: {
                ...this.state.formInputs,
                [name]: value
            }
        })
    }
    toggle = (param) => {
        if (param === 0) {
            this.setState({
                modalAttribute: !this.state.modalAttribute
            })
        }
        else if (param === 1) {
            this.setState({
                modalDelete: !this.state.modalDelete
            })
        }
    }
    toggleUpdateAttribute(row, e) {
        //this.setState({
        //    formInputs: {
        //        thingTimeZone: 0,
        //        thingName: '',
        //        thingDescription: '',
        //        thingTemperature: '',
        //    }
        //})
        this.setState({
            modalAttribute: !this.state.modalAttribute,
            isValid: false,
            formInputs: {
                thingTimeZone: row.thingTimeZone,
                thingName: row.thingName,
                thingDescription: row.thingDescription,
                thingTemperature: ''
            }
        })
    }
    updateAttribute = e => {
        e.preventDefault();
        if (this.state.formInputs.thingTemperature === "" || undefined) {
            this.setState({
                isValid: true,
            })
        }
        else {
            this.setState({
                isValid: false,
            })
            let userEmail = this.context.auth.username
            let thingName = this.state.formInputs.thingName
            let timeZone = this.state.formInputs.thingTimeZone
            let temperature = this.state.formInputs.thingTemperature


            updateAttributeHttp(thingName, userEmail, timeZone, temperature).then(() => {
                this.setState({
                    isSuccess: true,
                    modalAttribute: !this.state.modalAttribute
                })
                this.displayAlert()

            }).catch(err => {
                this.setState({
                    isSuccess: false,
                    modalAttribute: !this.state.modalAttribute
                })
                this.displayAlert()
            })
        }
    }
    toggleDelete(row, e) {
        this.setState({
            modalDelete: !this.state.modalDelete,
            formInputs: {
                thingDescription: row.thingDescription,
                thingName: row.thingName,
            }
        })
    }
    deleteDevice = e => {
        e.preventDefault();
        let userEmail = this.context.auth.username
        let thingName = this.state.formInputs.thingName
        deletehttp(thingName, userEmail).then(() => {
            this.setState({
                isSuccess: true,
                modalDelete: !this.state.modalDelete
            })
            this.displayAlert()

        }).catch(err => {
            this.setState({
                isSuccess: false,
                modalDelete: !this.state.modalDelete
            })
            this.displayAlert()
        })
    }
    displayAlert() {
        this.setState({ isVisible: true }, () => {
            window.setTimeout(() => {
                window.location.reload();
            }, 2500)
        });
    }
    render() {
        const { fetchedData, isVisible, isSuccess, formInputs, modalAttribute, modalDelete } = this.state;
        const columns = [
            {
                name: 'ID',
                selector: row => row.thingName,
            },
            {
                name: 'Nickname',
                selector: row => row.thingDescription,
            },
            {
                name: 'Temperature',
                //cell: row => <span style={{ textAlign: "center" }}> ${ row.thingTimeZone }</span>,
                selector: row => row.thingTempUnit,
            },
            {
                name: 'Timezone',
                selector: row => row.thingTimeZone,
            },
            {
                name: 'Update',
                button: true,
                cell: row => <Button onClick={e => this.toggleUpdateAttribute(row, e)}>Update</Button>,
            },
            {
                name: 'Delete',
                button: true,
                cell: row => <Button color="danger" onClick={e => this.toggleDelete(row, e)}>Delete</Button>,
            },
        ];

        return (
            <>
                <Container className="my-5">
                    <div className="mb-4">
                        <h1 className="h2">Device Setting Update</h1>
                    </div>
                    {
                        fetchedData && fetchedData.length > 0 && (
                            <DataTable
                                columns={columns}
                                data={fetchedData}
                                pagination
                            />
                        )
                    }
                    <Modal isOpen={modalAttribute} toggle={e => this.toggle(0)}>
                        <ModalHeader toggle={e => this.toggle(0)}>Update {formInputs.thingDescription}</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="thingTimeZone">Update Timezone</Label>
                                <Input
                                    className="mt-2"
                                    type="number"
                                    name="thingTimeZone"
                                    id="thingTimeZone"
                                    min="-12"
                                    max="12"
                                    step="0.5"
                                    invalid={formInputs.thingTimeZone > 12 || formInputs.thingTimeZone < -12}
                                    value={formInputs.thingTimeZone}
                                    onChange={this.handleChange}
                                    required
                                 />
                            </FormGroup>
                            <FormGroup>
                                <Label for="thingTemperature">Update Temperature Unit</Label>
                                <Input
                                    type="select"
                                    name="thingTemperature"
                                    id="thingTemperature"
                                    value={formInputs.thingTemperature}
                                    onChange={this.handleChange}
                                    required
                                    invalid={this.state.isValid && formInputs.thingTemperature === ""}
                                >
                                    <option value="" hidden>Select temperature unit</option>
                                    <option value="Fahrenheit">
                                        Fahrenheit[F]
                                    </option>
                                    <option value="Celsius">
                                        Celsius[C]
                                    </option>
                                </Input>
                                <FormFeedback>This is required. Please select one of options.</FormFeedback>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.updateAttribute}>Update</Button>
                            <Button color="secondary" onClick={e => this.toggle(0)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={modalDelete} toggle={e => this.toggle(1)}>
                        <ModalHeader toggle={e => this.toggle(1)}>Delete Device</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete {formInputs.thingDescription} ({formInputs.thingName})?
                            It will be permanently deleted.
                    </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={this.deleteDevice}>Delete</Button>
                            <Button color="secondary" onClick={e => this.toggle(1)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </Container>
                <StyledAlert color={isSuccess ? "success" : "danger"} isOpen={isVisible}>
                    {isSuccess ? "Successfully Updated" : "Sorry, please try again"}
                </StyledAlert>
            </>
        );
    }
}
DeviceSetting.contextTypes = {
    auth: PropTypes.object
}
const StyledAlert = styled(Alert)`
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
`;