import React, { Component } from "react";
import { Row, Col, Container, Card, CardBody, Form, Label, Input, Button, FormText } from "reactstrap";
import * as deviceApi from '../utils/deviceAPI'
import { getUserDevicesByEmail } from '../utils/userAPI';
import PropTypes from 'prop-types';
import { browserStorage, browserStorageDeviceIdKey } from '../utils/browserStorage';

export class RealTimeSerial extends Component {

    BrowserStorageDeviceIdKey = "RealTimeSerialDeviceId";

    static displayName = RealTimeSerial.name;
    constructor(props) {
        super(props);
        this.state = {
            fetchedAllDevices: [],
            thingName: browserStorage.get(browserStorageDeviceIdKey) || "",
            serialPortRequest: '',
            serialPortResponse: '',
            loading: false,
            deviceIdReq: false
        };
        this.handleThingQueryState = this.handleThingQueryState.bind(this);
    }
    componentDidMount() {
        this.getUserDevicesInfo();
    }
    getUserDevicesInfo = async () => {
        let user = this.context.auth.username
        if (user) {
            getUserDevicesByEmail(user).then((data) => {
                this.setState({
                    fetchedAllDevices: data.deviceList,
                });
                if (data.deviceList.length && !browserStorage.get(browserStorageDeviceIdKey)) {
                    this.setState({
                        thingName: data.deviceList[0].thingName
                    });
                    this.handleThingQueryState({
                        target: {
                            value: data.deviceList[0].thingName
                        }
                    });
                } else if (browserStorage.get(browserStorageDeviceIdKey)) {
                    this.handleThingQueryState({
                        target: {
                            value: browserStorage.get(browserStorageDeviceIdKey)
                        }
                    }, true);
                }
            }).catch(err => {
                console.log(err.message)
            })
        }
    }
    handleThingQueryState = (e, init) => {
        this.setState({
            deviceIdReq: true
        });
        const { value } = e.target;
        deviceApi.gethttp(value).then((data) => {
            this.setState({
                thingName: value,
                serialPortResponse: data.serialPortResponse,
                serialPortRequest: data.serialPortRequest,
                deviceIdReq: false
            });
            if (init) {
                this.submitSerialRequest();
            }
        })
    }
    handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            [name]: value
        })
    }
    submitSerialRequest = e => {
        if (e) e.preventDefault();
        this.setState({
            deviceIdReq: true
        });
        const { thingName, serialPortRequest } = this.state
        const option = {
            thingName: thingName,
            serialPortRequest: serialPortRequest
        }
        deviceApi.patchhttp(thingName, option).then((data) => {
            this.setState({
                deviceIdReq: false
            });
            // Save selected value into Browser Storage for later use
            browserStorage.set(browserStorageDeviceIdKey, this.state.thingName);
        })
    }
    submitGetSerialResponse = e => {
        e.preventDefault();
        const { thingName } = this.state
        this.setState({
            loading: true
        })
        deviceApi.gethttp(thingName).then((data) => {
            this.setState({
                serialPortResponse: data.serialPortResponse,
                loading: false
            })
        }
        )
    }

    render() {
        const { thingName, serialPortRequest, serialPortResponse } = this.state
        return (
            <Container className="my-5">
                <h1 className="h2">Serial Port</h1>
                <Form className="my-3" onSubmit={this.submitSerialRequest}>
                    <Row form>
                        <Col sm={12} md={6}>
                            <Label for="thingName">Device ID</Label>
                            <Input
                                type="select"
                                id="thingName"
                                name="thingName"
                                value={thingName}
                                onChange={this.handleThingQueryState}
                                required
                                disabled={!this.state.fetchedAllDevices.length}
                            >
                                (!this.state.fetchedAllDevices.length) &&
                                {
                                    <option value="" hidden>Loading...</option>
                                }
                                {
                                    this.state.fetchedAllDevices && this.state.fetchedAllDevices.map(item => (
                                        <option key={item.thingName} value={item.thingName}>
                                            {item.thingName} - {(item.thingDescription).substring(0, 24)}
                                        </option>
                                    ))
                                }
                            </Input>
                        </Col>
                        <Col sm={12} md={6}>        
                            <Label for="serialPortRequest">Text Request</Label>
                                <Input
                                    type="text"
                                    name="serialPortRequest"
                                    id="serialPortRequest"
                                    value={serialPortRequest || ''}
                                    onChange={this.handleInputChange}
                                    placeholder="Enter text input"
                                    required
                                ></Input>
                        </Col>
                        <Col sm={12} md={6}>
                            <Button className="my-4" color="primary"
                                disabled={(this.state.deviceIdReq || !this.state.fetchedAllDevices.length)}
                            >
                                {
                                    (this.state.deviceIdReq) ? "Loading..." : "SEND TO PORT"
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Card>
                    <CardBody>

  
                            <Label for="serialResponse">Response from Device</Label>
                            <Input
                                type="text"
                                name="serialResponse"
                                id="serialResponse"
                                defaultValue={serialPortResponse}
                                readOnly
                            />

                        <FormText color="muted">Button will be activated when you selet a devie</FormText>
                        <Button className="my-3" onClick={this.submitGetSerialResponse} disabled={ thingName ? false : true }>
                            <i className="fas fa-redo"></i>
                            <span className="ml-2">REFRESH</span>
                        </Button>
                    </CardBody>
                </Card>
            </Container>
        );
    }



}
RealTimeSerial.contextTypes = {
    auth: PropTypes.object
}