import React, { Component } from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon,
    LinkedinIcon,
    LinkedinShareButton,
    WhatsappShareButton,
} from "react-share"
import { Container } from "reactstrap";
import ReactHelmet from '../components/Helmet';

import iotConsult1JpgImg from "../assets/images/iot-consultation01.jpg";
import iotConsult2JpgImg from "../assets/images/iot-consultation02.jpg";
import iotConsult3PngImg from "../assets/images/iot-consultation03.png";

export class IoTConsultation extends Component {
  
    render() {
        const shareUrl = 'https://iotlinks.com/iot-consultation';
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure"
                    description="Sensors and devices that communicate with the cloud using a form of connectivity make up a complete IoT solution, and once the communicated data reaches the cloud."
                    title="IoT Consultation | Gateway Alfa | iotLINKS"
                />
                <div className="blog-bg"></div>
                <Container>
                    <div className="my-5">
                        <center>
                            <h1 className="h2 text-light mb-5">IoT Consultation</h1>
                            <img
                                className="shadow rounded cover"
                                src={iotConsult1JpgImg}
                                alt="Internet of Things Consultation"
                                height="500px"
                                width="100%"
                            />
                        </center>
                        <div>
                            <EmailShareButton
                                className="m-1"
                                url={shareUrl}
                                subject={"What is the IoT development? by IoTLinks"}
                                body={"If you want to know more details, please visit our website"}
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <FacebookShareButton
                                className="m-1"
                                url={shareUrl}
                                hashtag="#InternetOfThings"
                                quote={"What is the IoT development, and what tools required?"}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <LinkedinShareButton
                                className="m-1"
                                url={shareUrl}
                                title={"What is the IoT development, and what tools required?"}
                                source={"iotLinks"}
                                summary={"The Internet of Things is known as a system of different interrelated computing devices, digital and mechanical machines and objects that are given UIDs (unique identifiers.)"}
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <WhatsappShareButton
                                className="m-1"
                                url={shareUrl}
                                title={"What is the IoT development, and what tools required?"}
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                    </div>
                    <div className="my-5">
                        <section className="mb-5">
                            <p>
                                iotLINKS acting as a bridge between the real-world physical parameters and a dashboard of collected data on a cloud platform. 
                                We at iotLINKS developed a very sophisticated infrastructure of hardware and software tools that can be applied to many different applications. 
                                We help our clients effectively to capitalize on IoT technology and related solutions, connecting technologies, such as vendors and customers through a futuristic business model and more importantly with minimal investment and efforts. 
                                By consulting with iotLINKS you can pave the road to digitization in much faster and reliable path than any other way. 
                                IoT consists of the connections of millions of devices to the internet using embedded software and sensors that communicate to and from the cloud, collected data will be pushed to the database in the cloud and commands or actuation signals will be sent out from the cloud to the end devices. 
                                IoT combines connectivity with sensors, devices, and people, enabling a form of secure communication between human and the machine, software, and hardware.
                            </p>
                        </section>
                        <section className="my-5">
                            <img
                                className="shadow"
                                src={iotConsult2JpgImg}
                                alt="iotLINKS helps to set up dashboard of collected data on a cloud platform"             
                                width="100%"
                            />
                        </section>
                        <section className="mt-5">
                            <p>
                                iotLINKS offers consulting services covering the following areas:
                             </p>
                            <ul>
                                <li>Collecting critical data</li>
                                <li>Help with better and more reliable service</li>
                                <li>Avoiding downtime for machinery and process by predictive maintenance</li>
                                <li>Improving manufacturing efficiency</li>
                                <li>Monitoring operations and process</li>
                                <li>And many more.....</li>
                            </ul>
                        </section>
                    </div>
                </Container>
                <section className="bg-light py-5">
                    <Container>
                        <img
                            className="mb-3"
                            src={iotConsult3PngImg}
                            alt="Internet of Things Consultation"
                            width="70px"
                        />
                        <p>
                            IoT solutions require reliability, security, and accessibility. iotLINKS will help its own
                            clients to implement and achieve the best IoT infrastructure to move their business to the
                            higher level in the new era of industry 4.0.
                        </p>
                        <p className="mt-5">
                            <strong>
                                Do not hesitate to contact our highly experienced team for consultation!
                            </strong>
                        </p>
                    </Container>
                </section>
            </>
        );
    }
}