import React, { Component } from 'react';
import { Container } from "reactstrap";
import ReactHelmet from '../../components/Helmet'
import { PageHeader, PageImg } from "../styles/Pages";

import iotAppJpgImg from "../../assets/images/iot-application-06.jpg";

export class AirQualityControl extends Component {
  
    render() {
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, application"
                    description="Air quality in your home and office is getting important as air pollution is a problem."
                    title="Air Quality Control | Gateway Alfa | iotLINKS"
                />
                <PageHeader>
                    <h1>Air Quality Control</h1>
                </PageHeader>
                <Container>
                    <PageImg src={iotAppJpgImg} />
                    <p>
                        Air quality in your home and office is getting important as air pollution is a problem, using our Gateway
                        you can implement a remote air quality monitor and see the quality of the air your breath in all the time.
                    </p>
                    <br /><br />
                </Container>
            </>
        );
    }
}