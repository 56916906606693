import React, { Component } from "react";
import { Row, Col, Container, Card, CardBody, CardTitle, FormText, Label, Input, FormGroup, Button, Form, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { postHttp } from '../utils/userAPI'

export class ForgotPassword extends Component {
    constructor(props) {
        super();
        this.state = {
            form: {
                email: ''
            },
            failMsg: {
                isOpen: false,
                msg: ''
            },
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let { name, value } = event.target;
        if (name === "email") {
            value = value.toLowerCase();
        }
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        const path = "forgotpassword"
        const data = { userEmail: this.state.form.email }

        postHttp(data, path)
            .then((res) => {
                this.props.history.push('/update-password');
            })
            .catch(err => {
                console.log(err.message)
                this.setState({
                    failMsg: {
                        isOpen: true,
                        msg: err.message
                    },
                })
            })
    }
    closeFailMsg = () => {
        this.setState({
            failMsg: {
                isOpen: false,
                msg: ''
            }
        })
    }
    render() {
        const { form, failMsg } = this.state
        return (
            <Container className="my-5">
                <Row className="justify-content-center">
                    <Col xs="12" sm="8">
                        <Card>
                            <CardBody>
                                <CardTitle className="text-center" tag="h3">Forgot your password?</CardTitle>
                            </CardBody>
                            <CardBody>
                                <Form onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <Label htmlFor="email">Email</Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Ex. example@gmail.com"
                                            onChange={this.handleChange}
                                            value={form.email}
                                            required
                                        />
                                        <FormText color="muted">Please provide a same email address when register</FormText>
                                    </FormGroup>
                                    {
                                        failMsg.isOpen && (
                                            <Alert isOpen={failMsg.isOpen} color="danger" toggle={this.closeFailMsg}>
                                                {failMsg.msg}
                                            </Alert>
                                        )
                                    }
                                    <div className="text-center">
                                        <Button color="primary">
                                            CONTINUE
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                        <p className="my-3 text-center lead">or</p>
                        <hr />
                        <p>Did you already recieve a verification code? <Link to="/update-password">Already recived a code</Link></p>
                        <p>You don't remember your email address? <a href="mailto:webmaster@example.com">Please contact to admin</a></p>
                    </Col>
                </Row>
            </Container>
        );
    }

}
