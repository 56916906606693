const DEVICE_API_URL = 'iotthing';
const DEVICE_ATTRIBUTE = 'iotthing/PatchDeviceAttribute';

export const gethttp = (thingName) => {
    return fetch(`${DEVICE_API_URL}/${thingName}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': sessionStorage["accessToken"],
            'Authentication': sessionStorage["idToken"]
        }
    }).then(async response => {
        try {
            const data = await response.json();
            return data
        } catch (error) {
            throw new Error(`HTTP Error ${response.status || ''}`)
        }
    })
}

export const posthttp = (email, accountid, nickname,timezone) => {
    return fetch(`${DEVICE_API_URL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage["accessToken"],
            'Authentication': sessionStorage["idToken"]
        },
        body: JSON.stringify({
            userEmailAddress: email,
            accountID: accountid,
            deviceDescription: nickname,
            timeZone:timezone
        })
    })
        .then(async response => {
            try {
                const data = await response.json();
                return data
            }
            catch {
                throw new Error(`HTTP Error ${response.status || ''}`)
            }
        })
}


export const patchhttp = (thingName, data) => {
    console.log(data)
    return fetch(DEVICE_API_URL + `/${thingName}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage["accessToken"],
            'Authentication': sessionStorage["idToken"]
        },
        body: JSON.stringify(data)
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            console.log(response)
            return true
        } else {
            const error = new Error(`HTTP Error ${response.status}`)
            console.log(error)
            throw error
        }
    })
}

export const deletehttp = (thingName, userEmail) => {
    return fetch(DEVICE_API_URL + `/${thingName}?UserEmail=${userEmail}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage["accessToken"],
            'Authentication': sessionStorage["idToken"]
        },
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            console.log(response)
            return true
        } else {
            const error = new Error(`HTTP Error ${response.status}`)
            console.log(error)
            throw error
        }
    })
}

export const updateAttributeHttp = (thingName, userEmail, timezone, temperature) => {
    console.log(thingName + "\n" + userEmail + "\n" + timezone + "\n" + temperature)
    return fetch(DEVICE_ATTRIBUTE + `/${thingName}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage["accessToken"],
            'Authentication': sessionStorage["idToken"]
        },
        body: JSON.stringify({
            userEmailAddress: userEmail,
            DeviceName: thingName,
            TimeZone: timezone,
            TemperatureUnit: temperature
        })
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            console.log(response)
            return true
        } else {
            const error = new Error(`HTTP Error ${response.status}`)
            console.log(error)
            throw error
        }
    })
}

