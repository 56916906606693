import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Collapse, Container, Navbar, Nav, NavbarBrand, NavbarToggler, NavItem, NavLink, DropdownToggle, DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import SignOutButton from './SignOutButton'
import styled from "styled-components";

import iotLinksLogoPngImg from "../assets/images/iotlinks-logo.png";

export class NavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            openDeviceMenu: false,
            openChartMenu: false,
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    render() {
        return (
            <>
                {
                    !this.context.auth.loggedIn && (
                        <TopNav className="d-none d-sm-block">
                            <Link className="btn btn-light btn-sm mx-1" to="/login">Login</Link>
                            <Link className="btn btn-secondary btn-sm mx-1" to="/register">Register</Link>
                        </TopNav>
                    )
                }
                <MainNav className="navbar-expand-sm navbar-toggleable-sm bg-white border-bottom box-shadow" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <Logo
                                alt="iotLinks"
                                src={iotLinksLogoPngImg}
                                width="120px"
                            />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <Nav className="navbar-nav flex-grow" navbar>
                                {
                                    this.context.auth.loggedIn ?
                                        (
                                            <>
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark" to="/dashboard"
                                                             onClick={() => this.toggleNavbar()}>
                                                        Dashboard
                                                    </NavLink>
                                                </NavItem>
                                                <UncontrolledDropdown nav inNavbar>
                                                    <DropdownToggle nav caret className="text-dark">
                                                        Data
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem className="text-primary" header>Historical Analytics</DropdownItem>
                                                        <DropdownNavItem>
                                                            <NavLink tag={Link} className="" to="/historical-chart"
                                                                     onClick={() => this.toggleNavbar()}>
                                                                Historical Chart
                                                            </NavLink>
                                                        </DropdownNavItem>
                                                        <DropdownItem className="text-primary" header>Real Time Data</DropdownItem>
                                                        <DropdownNavItem>
                                                            <NavLink tag={Link} className="" to="/realtime-temperature"
                                                                     onClick={() => this.toggleNavbar()}>
                                                                Temperature Port
                                                            </NavLink>
                                                        </DropdownNavItem>
                                                        <DropdownNavItem>
                                                            <NavLink tag={Link} className="" to="/realtime-adconverter"
                                                                     onClick={() => this.toggleNavbar()}>
                                                                AD Port
                                                            </NavLink>
                                                        </DropdownNavItem>
                                                        <DropdownNavItem>
                                                            <NavLink tag={Link} className="" to="/realtime-serial"
                                                                     onClick={() => this.toggleNavbar()}>
                                                                Serial Port
                                                            </NavLink>
                                                        </DropdownNavItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <UncontrolledDropdown nav inNavbar>
                                                    <DropdownToggle nav caret className="text-dark">
                                                        Settings
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem className="text-primary" header>Devices</DropdownItem>
                                                        <DropdownNavItem>
                                                            <NavLink tag={Link} className="" to="/device-register"
                                                                     onClick={() => this.toggleNavbar()}>
                                                                Register
                                                            </NavLink>
                                                        </DropdownNavItem>
                                                        <DropdownNavItem>
                                                            <NavLink tag={Link} className="" to="/device-setting"
                                                                     onClick={() => this.toggleNavbar()}>
                                                                Update
                                                            </NavLink>
                                                        </DropdownNavItem>
                                                        <DropdownItem className="text-primary" header>Port</DropdownItem>
                                                        <DropdownNavItem>
                                                            <NavLink tag={Link} className="" to="/port-setting"
                                                                     onClick={() => this.toggleNavbar()}>
                                                                Update
                                                            </NavLink>
                                                        </DropdownNavItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark" to="/profile"
                                                             onClick={() => this.toggleNavbar()}>Profile</NavLink>
                                                </NavItem>
                                                <SignOutButton logout={this.props.logout} collapseMenu={this.toggleNavbar} />
                                            </>
                                        ) :
                                        (
                                            <>
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark" to="/"
                                                             onClick={() => this.toggleNavbar()}>Home</NavLink>
                                                </NavItem>
                                                {/*
                                                 <UncontrolledDropdown nav inNavbar>
                                                    <DropdownToggle nav caret className="text-dark">
                                                        IoT Applications
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownNavItem>
                                                            <Link className="text-dark" to="/solar-panel">
                                                                Solar Panel
                                                            </Link>
                                                        </DropdownNavItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                 */}
                                                <UncontrolledDropdown nav inNavbar>
                                                    <DropdownToggle nav caret className="text-dark">
                                                        Gateway Products
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownNavItem>
                                                            <NavLink tag={Link} className="" to="/gateway-alpha"
                                                                     onClick={() => this.toggleNavbar()}>
                                                                Gateway Alfa
                                                            </NavLink>
                                                        </DropdownNavItem>
                                                        <DropdownNavItem>
                                                            <NavLink tag={Link} className="" to="/gateway-beta"
                                                                     onClick={() => this.toggleNavbar()}>
                                                                Gateway Beta
                                                            </NavLink>
                                                        </DropdownNavItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark" to="/iot-applications"
                                                             onClick={() => this.toggleNavbar()}>
                                                        IoT Applications
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark" to="/faq"
                                                             onClick={() => this.toggleNavbar()}>
                                                        FAQ
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark" to="/try-live-lab"
                                                             onClick={() => this.toggleNavbar()}>Live Lab</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <a className="text-dark nav-link" target="_blank" rel="noopener noreferrer" href="https://forum.iotlinks.com/"
                                                       onClick={() => this.toggleNavbar()}>
                                                        Forum
                                                    </a>
                                                </NavItem>
                                                <NavItem>
                                                    <a className="text-dark nav-link" target="_blank" rel="noopener noreferrer" href="https://arshon.store"
                                                       onClick={() => this.toggleNavbar()}>
                                                        Store
                                                    </a>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark d-block d-sm-none" to="/login"
                                                             onClick={() => this.toggleNavbar()}>Login</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark d-block d-sm-none" to="/register"
                                                             onClick={() => this.toggleNavbar()}>Register</NavLink>
                                                </NavItem>

                                            </>
                                        )
                                    }
                            </Nav>
                        </Collapse>
                    </Container>
                </MainNav>
            </>
        )
    }

}
NavMenu.contextTypes = {
    auth: PropTypes.shape({
        loggedIn: PropTypes.bool,
        username: PropTypes.string
    })
}
const TopNav = styled.div`
    text-align: right;
    font-size: 0.9rem;
    background-color: #314673;
    padding: 4px;
`;
const MainNav = styled(Navbar)`
    position: sticky;
    top: 0;
    z-index: 900;
  `;
const DropdownNavItem = styled(DropdownItem)`
  &:active {
      background-color: #efefef;
    }
  `;
const Logo = styled.img`
    vertical-align: inherit;
  `;