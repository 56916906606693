import React, { Component } from 'react';
import { Container } from "reactstrap";
import ReactHelmet from '../../components/Helmet'
import { PageHeader, PageImg } from "../styles/Pages";

import iotAppJpgImg from "../../assets/images/iot-application-07.jpg";

export class IndustrialApplications extends Component {
  
    render() {
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, application"
                    description="Industrial applications are limitless."
                    title="Industrial Applications | Gateway Alfa | iotLINKS"
                />
                <PageHeader>
                    <h1>Industrial Applications</h1>
                </PageHeader>
                <Container>
                    <PageImg src={iotAppJpgImg} />
                    <p>
                        Industrial applications are limitless: Predictive Maintenance, Remote Firmware Upgrade for equipment, Sensor
                        monitoring systems, actuator controls, data collection, location monitoring...
                    </p>
                    <br /><br />
                </Container>
            </>
        );
    }
}