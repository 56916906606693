const USERS_LOGIN_API_URL = 'useriotlinks/login';

export const getAuthState = () => {
    let user = sessionStorage.getItem("userName");
    let token = sessionStorage.getItem("idToken");
    // In the future, need user role column
    //console.log(sessionStorage)
    //console.log(token)
    return {
        userToken: token,
        username: user,
    }
}

export const logout = () => {
    return new Promise((resolve) => {
        window.sessionStorage.clear()
        resolve(true)
    })
}

export const login = async (body) => {
    const data = { userEmail: body.email, accessCode: body.password }
    let response = await fetch(`${USERS_LOGIN_API_URL}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data)
        }
    );
    const res = await response.json();

    if (res.isLoginSuccessful) {
        sessionStorage.setItem("accessToken", res.loginedUser.sessionTokens.accessToken);
        sessionStorage.setItem("idToken", res.loginedUser.sessionTokens.idToken);
        sessionStorage.setItem("refreshToken", res.loginedUser.sessionTokens.refreshToken);
        sessionStorage.setItem("expirationTime", res.loginedUser.sessionTokens.expirationTime);
        sessionStorage.setItem("issuedTime", res.loginedUser.sessionTokens.issuedTime);
        sessionStorage.setItem("userName", res.loginedUser.userID);

        let user = sessionStorage.getItem("userName");
        let token = sessionStorage.getItem("idToken");
        return {
            userToken: token,
            username: user
        }
    }
    else {
        var errMsg = res.loginMessage;
        const error = new Error(`${errMsg}`)
        throw error
    }
}
