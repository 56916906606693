import React, { Component } from 'react';
import { Row, Col, Container, Card, CardTitle, CardBody, Form, Input, Label, FormGroup, FormText, Button, Alert } from "reactstrap";
import { validatePasswordStrong } from '../helpers/validate'
import { postHttp } from '../utils/userAPI'

export class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                email: '',
                verificationCode: "",
                password: ""
            },
            formErrors: {
                minimum: 'Must be 8 Characters',
                uppercase: 'Must have uppercase',
                lowercase: 'Must have lowercase',
                numeric: 'Must have numeric characters'
            },
            formValidity: {
                minimum: false,
                uppercase: false,
                lowercase: false,
                numeric: false
            },
            successMsg: {
                isOpen: false,
                msg: ''
            },
            failMsg: {
                isOpen: false,
                msg: ''
            },
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target
        if (name === "password") {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value
                }
            }, function () { this.validatePassword(value) })
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value
                }
            })
        }
    }
    validatePassword(value) {
        const { formValidity, formErrors } = this.state
        const { validity, fieldValidationErrors } = validatePasswordStrong(formValidity, value, formErrors)
        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity,
        })
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        const { minimum, uppercase, lowercase, numeric } = this.state.formValidity
        const path = "updatepassword"
        const data = { userEmail: this.state.form.email, accessCode: this.state.form.verificationCode, newPassword: this.state.form.password }

        console.log(data)
        if (minimum && uppercase && lowercase && numeric) {
            postHttp(data, path)
                .then((res) => {
                    window.setTimeout(() => {
                        this.setState({
                            successMsg: {
                                isOpen: true,
                                msg: res
                            }
                        })
                    }, 3000)
                    window.setTimeout(() => {
                        this.props.history.push('/login');
                    }, 4500)
                })
                .catch(err => {
                    console.log(err.message)
                    this.setState({
                        failMsg: {
                            isOpen: true,
                            msg: err.message
                        },
                    })
                })
        }

        //let response = await fetch(`${USERS_UPDATE_API_URL}`,
        //    {
        //        method: 'POST',
        //        headers: {
        //            'Content-Type': 'application/json',
        //            'Accept': 'application/json',
        //        },
        //        credentials: 'include',
        //        body: JSON.stringify(formData)
        //    }
        //);
        //const res = await response.json();

        //if (res.isSuccessful) {
        //    alert(res.responseMessage);
        //    this.props.history.push('/login');
        //    console.log(res);

        //}
        //else {
        //    var resp = res.responseMessage;
        //    console.log(resp);
        //    alert(resp);
        //    this.setState({ isButtonDisabled: false });
        //}

    }
    closeFailMsg = () => {
        this.setState({
            failMsg: {
                isOpen: false,
                msg: ''
            }
        })
    }
    render() {
        const { form, formErrors, failMsg, successMsg } = this.state
        return (
            <>
                <Container className="container my-5">
                    <Row className="justify-content-center">
                        <Col sm="12" md="8">
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-center" tag="h3">Update Password</CardTitle>
                                </CardBody>
                                <CardBody>
                                    <Form
                                        onSubmit={this.handleSubmit}
                                    >
                                        <FormGroup>
                                            <Label htmlFor="email">Email</Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder="Ex. example@gmail.com"
                                                value={form.email}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <FormText color="muted">Please provide a same email address when register</FormText>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="verificationCode">Verification Code</Label>
                                            <Input
                                                id="verificationCode"
                                                name="verificationCode"
                                                type="text"
                                                placeholder="Enter verification code"
                                                value={form.verificationCode}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="password">New Password</Label>
                                            <Input
                                                type="password"
                                                name="password"
                                                placeholder="Enter password"
                                                value={form.password}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <FormText className="pl-2" color="danger">
                                                {formErrors.minimum}
                                            </FormText>
                                            <FormText className="pl-2" color="danger">
                                                {formErrors.uppercase}
                                            </FormText>
                                            <FormText className="pl-2" color="danger">
                                                {formErrors.lowercase}
                                            </FormText>
                                            <FormText className="pl-2" color="danger">
                                                {formErrors.numeric}
                                            </FormText>
                                        </FormGroup>
                                        {
                                            failMsg.isOpen && (
                                                <Alert isOpen={failMsg.isOpen} color="danger" toggle={this.closeFailMsg}>
                                                    {failMsg.msg}
                                                </Alert>
                                            )
                                        }
                                        <div className="text-center">
                                            <Button color="primary">
                                                UPDATE YOUR PASSWORD
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {
                    successMsg.isOpen && (
                        <Alert className="success-msg" color="success" isOpen={successMsg.isOpen}>
                            {successMsg.msg}
                        </Alert>
                    )
                }
            </>
        );
    }
}
