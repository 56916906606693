import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, Container, Card, CardBody } from 'reactstrap';
import { LineChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Line, Tooltip } from 'recharts'
import { getUserDevicesByEmail } from '../utils/userAPI';
import { gethttp } from '../utils/deviceAPI'
import PropTypes from 'prop-types';
import { browserStorage, browserStorageDeviceIdKey } from '../utils/browserStorage';

export class RealTimeAD extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deviceIdReq: false,
            fetchedAllDevices: [],
            searchInputs: {
                thingName: browserStorage.get(browserStorageDeviceIdKey) || "",
            },
            adconvertorDigitalValues: [],
            adconvertorBufferValues: '',
            adconvertorSampling: '',
            adconvertorUpdatingTime: '',
            adconvertorVoltageValue: '',
            timeInterval: '',
            recordingTime: '',
            updatingTime: '',
            message: ''
        };
    }

    componentDidMount() {
        this.getUserDevicesInfo()
    }
    getUserDevicesInfo = async () => {
        let user = this.context.auth.username
        if (user) {
            getUserDevicesByEmail(user).then((data) => {
                this.setState({
                    fetchedAllDevices: data.deviceList,
                });
                if (data.deviceList.length && !browserStorage.get(browserStorageDeviceIdKey)) {
                    this.setState({
                        searchInputs: {
                            thingName: data.deviceList[0].thingName
                        }
                    });
                } else if (browserStorage.get(browserStorageDeviceIdKey)) {
                    this.submitGet();
                }
            }).catch(err => {
                console.log(err.message)
            })
        }
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            searchInputs: {
                ...this.state.searchInputs,
                [name]: value
            }
        })
    }

    submitGet = (event) => {
        if (event) event.preventDefault();
        this.setState({
            adconvertorDigitalValues: [],
            message: '',
            adconvertorBufferValues: '',
            deviceIdReq: true
        })
        gethttp(this.state.searchInputs.thingName).then((data) => {
            if (data.adconvertorDigitalValues === null) {
                this.setState({
                    message: 'You have a 0 records'
                })
            }
            else {
                let samplingObj = data.adconvertorSampling;
                let updatingInterval = data.adconvertorUpdatingTime;
                let voltageValue = data.adconvertorVoltageValue;
                let timeIntervalObj = parseFloat((data.adconvertorBufferLength * samplingObj).toFixed(6));

                let dateObj = new Date(data.adconvertorTimeStamp)
                let hour = dateObj.getUTCHours();
                let minute = String(dateObj.getUTCMinutes()).padStart(2, "0");
                let second = String(dateObj.getUTCSeconds()).padStart(2, "0"); 
                let year = dateObj.getUTCFullYear();
                let month = dateObj.getUTCMonth() + 1;
                let date = dateObj.getUTCDate();
                let recordingTime =
                    year + "-" +
                    String(month).padStart(2, '0') + "-" +
                    String(date).padStart(2, '0') + ", " +
                    String(hour).padStart(2, '0') + ":" +
                    String(minute).padStart(2, '0') + ":" +
                    String(second).padStart(2, '0');

                let updateObj = new Date(data.adconvertorVoltageValueTimeStamp)
                let uhour = updateObj.getUTCHours();
                let uminute = String(updateObj.getUTCMinutes()).padStart(2, "0");
                let usecond = String(updateObj.getUTCSeconds()).padStart(2, "0");
                let uyear = updateObj.getUTCFullYear();
                let umonth = updateObj.getUTCMonth() + 1;
                let udate = updateObj.getUTCDate();
                let updatingTime =
                    uyear + "-" +
                    String(umonth).padStart(2, '0') + "-" +
                    String(udate).padStart(2, '0') + ", " +
                    String(uhour).padStart(2, '0') + ":" +
                    String(uminute).padStart(2, '0') + ":" +
                    String(usecond).padStart(2, '0');

                this.setState({
                    adconvertorDigitalValues: data.adconvertorDigitalValues,
                    adconvertorBufferValues: data.adconvertorBufferValues,
                    adconvertorSampling: samplingObj,
                    adconvertorUpdatingTime: updatingInterval,
                    adconvertorVoltageValue: voltageValue,
                    timeInterval: timeIntervalObj,
                    recordingTime: recordingTime,
                    updatingTime: updatingTime
                })
            }
            this.setState({
                deviceIdReq: false
            })
            // Save selected value to Browser Storage for later use
            browserStorage.set(browserStorageDeviceIdKey, this.state.searchInputs.thingName);
        }).catch(err => {
            console.log(err)
        })
    }
    render() {
        const { searchInputs, fetchedAllDevices, adconvertorBufferValues, timeInterval, adconvertorSampling, adconvertorUpdatingTime, adconvertorVoltageValue, recordingTime, updatingTime, adconvertorDigitalValues, message } = this.state;
        return (
            <Container className="my-5">
                <h1 className="h2">Get Real Time AD Converter Chart</h1>
                <Form className="mb-5 mt-5" onSubmit={this.submitGet}>
                    <Row form>
                        <Col xs={12} sm={8} lg={6}>
                            <FormGroup>
                                <Label for="thingName">Device ID</Label>
                                <Input
                                    type="select"
                                    id="thingName"
                                    name="thingName"
                                    value={searchInputs.thingName}
                                    onChange={this.handleChange}
                                    required
                                    disabled={!this.state.fetchedAllDevices.length}
                                >
                                    (!this.state.fetchedAllDevices.length) &&
                                    {
                                        <option value="" hidden>Loading...</option>
                                    }
                                    {
                                        fetchedAllDevices && fetchedAllDevices.map(item => (
                                            <option key={item.thingName} value={item.thingName}>
                                                {item.thingName} - {item.thingDescription}
                                            </option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={4} lg={2}>
                            <FormGroup className="d-flex flex-column">
                                <Label for="btn-getChart" className="invisible d-none d-sm-inline-block">Button</Label>
                                <Button color="primary" id="btn-getChart"
                                        disabled={(this.state.deviceIdReq || !this.state.fetchedAllDevices.length)}
                                >
                                    {
                                        (this.state.deviceIdReq) ? "Loading..." : "GET CHART"
                                    }
                                </Button>
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} md={6}>
                            <span className="font-weight-bold">Voltage [V]: </span>
                            <span>{adconvertorVoltageValue}</span>
                        </Col>
                        <Col sm={12} md={6}>
                            <span className="font-weight-bold">Interval between Samples [s]: </span>
                            <span>{adconvertorSampling}</span>
                        </Col>
                        <Col sm={12} md={6}>
                            <span className="font-weight-bold">Updating Time Interval [s]: </span>
                            <span>{adconvertorUpdatingTime}</span>
                        </Col>
                        <Col sm={12} md={6}>
                            <span className="font-weight-bold">Recording Period [s]: </span>
                            <span>{timeInterval}</span>
                        </Col>
                        <Col sm={12} md={6}>
                            <span className="font-weight-bold">Latest Updating Time: </span>
                            <span>{updatingTime}</span>
                        </Col>
                        <Col sm={12} md={6}>
                            <span className="font-weight-bold">Recording Time: </span>
                            <span>{recordingTime}</span>
                        </Col>
                    </Row>
                    <FormGroup className="mt-3">
                        <Label for="adconvertorBufferValues">AD Convertor Buffer Values:</Label>
                        <Input
                            type="text"
                            readOnly
                            id="adconvertorBufferValues"
                            name="adconvertorBufferValues"
                            onChange={this.onChange}
                            value={adconvertorBufferValues || ''}
                        />
                    </FormGroup>
                </Form>
                {
                    adconvertorDigitalValues && adconvertorDigitalValues.length === 0 &&
                    <span>{message}</span>
                }
                {
                    adconvertorDigitalValues && adconvertorDigitalValues.length > 0 &&
                    (
                        <Card>
                            <CardBody>
                                <div className="h-100">
                                    <div id="chart-container">
                                        <ResponsiveContainer>
                                            <LineChart
                                                data={adconvertorDigitalValues}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="recordNumber"
                                                    interval="preserveEnd"
                                                    label={{ value: "Sample", position: "insideBottomRight", dy: -35 }}
                                                />
                                                <YAxis
                                                    width={25}
                                                    interval="preserveEnd"
                                                />
                                                <Legend />
                                                <Tooltip />
                                                <Line
                                                    name="Voltage [V]"
                                                    type="monotone"
                                                    dataKey="recordValue"
                                                    stroke="#8884d8"
                                                    activeDot={{ r: 3 }}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    )
                }
            </Container>
        );
    }

}
RealTimeAD.contextTypes = {
    auth: PropTypes.object
}