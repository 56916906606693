import React, { Component } from 'react';
import ReactHelmet from '../components/Helmet'
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon,
    LinkedinIcon,
    LinkedinShareButton,
    WhatsappShareButton,
} from "react-share"
import styled from "styled-components";
import { Container } from "reactstrap";

import iotSol1JpgImg from "../assets/images/iot-solution01.jpg";
import iotSol2PngImg from "../assets/images/iot-solution02.png";
import iotSol3JpgImg from "../assets/images/iot-solution03.jpg";
import iotSol4PngImg from "../assets/images/iot-solution04.png";

export class IoTSolution extends Component {
    render() {
        const shareUrl = 'https://iotlinks.com/iot-solution';
        return (
            <>
        
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, solution"
                    description="The Internet of Things Solution is a way for people but businesses in particular to achieve tasks in a more efficient manner using IoT technology."
                    title="IoT Solution | Gateway Alfa | iotLINKS"
                />

                <div className="blog-bg"></div>
                <Container>
                    <div className="my-5">
                        <center>
                            <h1 className="h2 text-light mb-5">IoT Solution</h1>
                            <img
                                className="shadow rounded cover"
                                src={iotSol1JpgImg}
                                alt="Internet of Things Solution"
                                height="500px"
                                width="100%"
                            />
                        </center>
                        <div>
                            <EmailShareButton
                                className="m-1"
                                url={shareUrl}
                                subject={"What is the IoT solution? by IoTLinks"}
                                body={"If you want to know more details, please visit our website"}
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <FacebookShareButton
                                className="m-1"
                                url={shareUrl}
                                hashtag="#InternetOfThings"
                                quote={"What is the IoT solution?"}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <LinkedinShareButton
                                className="m-1"
                                url={shareUrl}
                                title={"What is the IoT solution?"}
                                source={"iotLinks"}
                                summary={"The Internet of Things Solution is a way for people but businesses in particular to achieve tasks in a more efficient manner using IoT technology."}
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <WhatsappShareButton
                                className="m-1"
                                url={shareUrl}
                                title={"What is the IoT solution?"}
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                    </div>
                </Container>
                <div className="mt-5">
                    <section className="mb-5">
                        <Container>
                            <Title>Internet of Things Solution</Title>
                            <p>
                                Nowadays, there are a lot of talks about the "IoT Solution" or the "Internet 
                                of Things". However, this might sound very confusing for non-technical people 
                                who are not experienced with technology. But In this section, you will learn 
                                what the "IoT Solution" is and its four main components.
                            </p>
                        </Container>
                    </section>
                    <section className="py-5 bg-light">
                        <Container>
                            <img
                                className="mb-3"
                                src={iotSol2PngImg}
                                alt="What is the IoT Solution"
                                width="70px"
                            />
                            <Title>What is the IoT Solution?</Title>                                  
                            <p>
                                The IoT Solution is a way for anyone to boost efficiency but businesses in particular. 
                                IoT solutions have helped businesses manage money, manage tasks, analyze prospects, 
                                transfer secure information, and many more. The goal of the IoT solution is to combine 
                                various physical and digital components to develop a communication path that oversees 
                                multiple levels in a business while being able to interact with other devices and people.
                            </p>
                            <p>
                                In other words, the Internet of Things is a bunch of devices talking to each other and 
                                helping each other to reach a goal. This goal might be simple or complex such as processing 
                                money or analyzing prospects. 
                            </p>
                            <p>
                                But, how does the IoT Solution work? The IoT Solution has 4 components that will be explained 
                                in the following section:
                            </p>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <img
                                className="shadow"
                                src={iotSol3JpgImg}
                                alt="how does the IoT Solution work"
                                width="100%"
                            />
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>Sensors / Devices</Title>  
                                <p>
                                    Firstly, we need to collect the data from somewhere or send data to somewhere. 
                                    This is where sensors and devices come into play. A sensor/device is defined 
                                    as anything that you can receive data from or send data to such as a Temperature 
                                    sensor or Humidity sensor, Light Switch, electric pump and many more.
                                </p>
                                <p>
                                    Keep in mind that it can be a stand-alone sensor like a temperature reader, 
                                    or it can be a bundled sensor in a device such as your phone since it has 
                                    (GPS, camera, keyboard, fingerprint, etc.)
                                </p>
                                <p>
                                    However, whether it's a stand-alone sensor or a bundled sensor, this is 
                                    the first step which is to collect data from the input device.
                                </p>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>Connectivity (Transfer of Data)</Title>  
                            <p>
                                Secondly, we need to transfer the data collected from our Sensors / Devices 
                                (Input) to the Server. In simple terms, a Server is a place where data is 
                                stored for processing or extraction. However, most companies nowadays use 
                                Cloud Servers. Now we need to find a way to transfer the collected data 
                                from our sensors and devices to these cloud servers. This can be done in 
                                multiple ways:
                            </p>
                            <ul>
                                <li>Cellular</li>
                                <li>Satellite</li>
                                <li>WiFi</li>
                                <li>Bluetooth</li>
                                <li>LPWAN</li>
                                <li>Ethernet</li>
                                <li>And many more</li>
                            </ul>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>Data Processing (Processing of Data)</Title> 
                            <p>
                                Alright! Now that we have transferred the data, we collected to our cloud 
                                servers, what now? The third component of the IoT Solution is Data Processing. 
                                This is where the data we have transferred will now be used to complete a task.
                                Some use cases for processing data could be:
                            </p>
                            <ul>
                                <li>Checking if the temperature of the house is too high (fire hazard)</li>
                                <li>Checking if the motion sensors of the house have been activated (intruder alert)</li>
                                <li>Checking if there is high humidity in your basement</li>
                            </ul>
                            <p>
                                Once this data is processed, we now need to let the user know what we find
                                because humans can't necessarily understand binary code on a computer. We need
                                to translate this data into human language. This is where the next step comes
                                into play.
                            </p>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>User Interface (IoT Dashboard)</Title> 
                            <p>
                                Let’s say that the cloud server processed and determined that there 
                                is an intruder in the house. How do we let the user know this? First, 
                                we need to translate computer language into human language. This is done 
                                through some smart coding into the User Interface. The User Interface (UI) 
                                is a place where the user can understand everything in human language. 
                                This is the place where they can lower the temperature of the house or alert 
                                relevant authorities of a potential intruder in their house.
                                Here are a few examples of popular User Interfaces:
                            </p>
                            <ul>
                                <li>Mobile phones</li>
                                <li>Home Dashboards</li>
                                <li>Websites</li>
                                <li>Software and Applications on Computers</li>
                            </ul>
                        </Container>
                    </section>
                    <section className="py-5 bg-light">
                        <Container>
                            <img
                                className="mb-3"
                                src={iotSol4PngImg}
                                alt=" The Complete IoT Solution"
                                width="70px"
                            />
                            <Title>Summary</Title> 
                            <p>
                                The Internet of Things is a bunch of technology working together to achieve 
                                a task often without the need of a human. The Internet of Things Solution 
                                (IoT Solution) is a way for people but businesses in particular to achieve 
                                tasks in a more efficient manner using IoT technology.
                                The Complete IoT Solution comprises of four components:
                            </p>
                            <p>
                                Sensors / Devices  -&gt; Connectivity (Transfer of Data) -&gt; Data Processing -&gt; User Interface (IoT Dashboard).
                            </p>
                        </Container>
                    </section>
                    </div>

            </>
        );
    }
}
const Title = styled.h2`
    font-size: 1.3rem;
    color: #3774b0;
`;