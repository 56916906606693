import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, Container, Card, CardBody } from 'reactstrap';
import { LineChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Line, Tooltip } from 'recharts';
import { getUserDevicesByEmail } from '../utils/userAPI';
import PropTypes from 'prop-types';
import { browserStorage, browserStorageDeviceIdKey, browserStorageHistoryChartKey } from '../utils/browserStorage';

export class HistoricalChart extends Component {

    constructor(props) {

        super(props);
        
        const todayHourAgo = new Date();
        todayHourAgo.setHours(todayHourAgo.getHours() - 1);
        const year = todayHourAgo.getFullYear(),
              month = String(todayHourAgo.getMonth() + 1).padStart(2, '0'),
              day = String(todayHourAgo.getDay()).padStart(2, '0'),
              hour = String(todayHourAgo.getHours()).padStart(2, '0'),
              min = String(todayHourAgo.getMinutes()).padStart(2, '0');

        let startChartDate = year + "-" + month + "-" + day,
            startChartTime = hour + ":" + min,
            chartDuration = 1;

        let selectedFormValues = browserStorage.get(browserStorageHistoryChartKey);
        if (selectedFormValues) {
            startChartDate = selectedFormValues.startChartDate;
            startChartTime = selectedFormValues.startChartTime;
            chartDuration = selectedFormValues.chartDuration;
        }

        this.state = {
            deviceIdReq: false,
            fetchedAllDevices: [],
            searchInputs: {
                thingName: browserStorage.get(browserStorageDeviceIdKey) || "",
                startChartDate: startChartDate,
                startChartTime: startChartTime,
                chartDuration: chartDuration
            },
            temperatures: [],
            unit: '',
            message: ''
        };
    }

    componentDidMount() {
        this.getUserDevicesInfo();
    }

    getUserDevicesInfo = async () => {
        let user = this.context.auth.username
        if (user) {
            getUserDevicesByEmail(user).then((data) => {
                this.setState({
                    fetchedAllDevices: data.deviceList,
                });
                if (data.deviceList.length && !browserStorage.get(browserStorageDeviceIdKey)) {
                    this.setState({
                        searchInputs: {
                            ...this.state.searchInputs,
                            thingName: data.deviceList[0].thingName
                        }
                    });
                } else if (browserStorage.get(browserStorageDeviceIdKey)) {
                    this.submitGet();
                }
            }).catch(err => {
                console.log(err.message)
            })
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            searchInputs: {
                ...this.state.searchInputs,
                [name]: value
            }
        })
    }

    submitGet = (event) => {
        if (event) event.preventDefault();
        this.setState({
            temperatures: [],
            message: '',
            deviceIdReq: true
        });
        this.getTemperatureUnit(this.state.searchInputs.thingName);
        var sdate = this.state.searchInputs.startChartDate;
        var stime = this.state.searchInputs.startChartTime;
        var starttime = new Date(sdate + " " + stime);
        //  const url = '/iotthing/gettemperresponse/' + this.state.searchInputs.thingName + '?startdt=' + starttime.toISOString() + '&hlength=' + this.state.searchInputs.chartDuration;
        const url = '/iotthing/gettemperresponse/' + this.state.searchInputs.thingName + '?startdt=' + starttime.toISOString() + '&hlength=' + this.state.searchInputs.chartDuration + '&tzoffset=' + starttime.getTimezoneOffset();
        fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage["accessToken"],
                'Authentication': sessionStorage["idToken"]
            }
        }).then(async response => {
            // // Add function to change data 2021-06-04T16:51:30Z-> hh:mm
            const tempdata = await response.json();
            if (tempdata.length > 0) {
                this.setState({
                    temperatures: tempdata,
                })
            } else {
                this.setState({
                    message: 'You have 0 record'
                })
            }
            this.setState({
                deviceIdReq: false
            });
            // Save selected value into Browser Storage for later use
            browserStorage.set(browserStorageDeviceIdKey, this.state.searchInputs.thingName);
            browserStorage.set(browserStorageHistoryChartKey, {
                startChartDate: sdate,
                startChartTime: stime,
                chartDuration: this.state.searchInputs.chartDuration
            });
        }).catch(err => console.log(err));
    }

    getTemperatureUnit(thingName) {
        this.state.fetchedAllDevices.forEach(el => {
            if (el.thingName === thingName) {
                this.setState({
                    unit: el.thingTempUnit
                })
            }
        })
    }

    render() {
        return (
            <Container className="my-5">
                <h1 className="h2">Get Historical Chart</h1>
                <Form className="mb-5 mt-5" onSubmit={this.submitGet}>
                    <Row form>
                        <Col xs={12} md={6} lg={5}>
                            <FormGroup>
                                <Label for="thingName">Device ID</Label>
                                <Input
                                    type="select"
                                    id="thingName"
                                    name="thingName"
                                    value={this.state.searchInputs.thingName}
                                    onChange={this.handleChange}
                                    required
                                    disabled={!this.state.fetchedAllDevices.length}
                                >
                                    (!this.state.fetchedAllDevices.length) &&
                                    {
                                        <option value="" hidden>Loading...</option>
                                    }
                                    {
                                        (this.state.fetchedAllDevices) && this.state.fetchedAllDevices.map(item => (
                                            <option key={item.thingName} value={item.thingName}>
                                                {item.thingName} - {(item.thingDescription).substring(0, 25)}
                                            </option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <FormGroup>
                                <FormGroup>
                                    <Label for="startChartDate">Date</Label>
                                    <Input
                                        type="date"
                                        name="startChartDate"
                                        id="startChartDate"
                                        placeholder="date placeholder"
                                        onChange={this.handleChange}
                                        value={this.state.searchInputs.startChartDate}
                                        required
                                    />
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6} lg={2}>
                            <FormGroup>
                                <Label for="startChartTime">Time</Label>
                                <Input
                                    type="time"
                                    name="startChartTime"
                                    id="startChartTime"
                                    placeholder="time placeholder"
                                    onChange={this.handleChange}
                                    value={this.state.searchInputs.startChartTime}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6} lg={2}>
                            <FormGroup>
                                <Label for="chartDuration">Chart Interval</Label>
                                <Input
                                    type="select"
                                    name="chartDuration"
                                    id="chartDuration"
                                    onChange={this.handleChange}
                                    value={this.state.searchInputs.chartDuration}
                                    required
                                >
                                    <option value="" hidden>Select interval</option>
                                    <option value={1}>1 hour</option>
                                    <option value={3}>3 hours</option>
                                    <option value={6}>6 hours</option>
                                    <option value={12}>12 hours</option>
                                    <option value={24}>24 hours</option>
                                </Input>
                            </FormGroup>

                        </Col>
                    </Row>


                    <Button color="primary" disabled={(this.state.deviceIdReq || !this.state.fetchedAllDevices.length)}>
                        {
                            (this.state.deviceIdReq) ? "Loading..." : "GET CHART"
                        }
                    </Button>
                </Form>
                <div>
                    {
                        this.state.temperatures && this.state.temperatures.length === 0 &&
                        <span>{this.state.message}</span>
                    }
                    {
                        this.state.temperatures && this.state.temperatures.length > 0 &&
                        (
                            <Card className="my-3 py-3">
                                <CardBody className="pl-0">
                                    <div id="chart-container">
                                        <ResponsiveContainer>
                                            <LineChart
                                                data={this.state.temperatures}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="time" interval="preserveEnd" />
                                                <YAxis interval="preserveEnd" width={40} />
                                                <Legend />
                                                <Tooltip />
                                                <Line
                                                    name={this.state.unit === "Celsius" ? "Temperature [C]" : "Temperature [F]"}
                                                    type="monotone"
                                                    dataKey="temperatureU"
                                                    stroke="#8884d8"
                                                    activeDot={{ r: 5 }}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </CardBody>
                            </Card>
                        )
                    }
                </div>
            </Container>
        );
    }

}
HistoricalChart.contextTypes = {
    auth: PropTypes.object
}