import React, { Component } from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon,
    LinkedinIcon,
    LinkedinShareButton,
    WhatsappShareButton,
} from "react-share"
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import ReactHelmet from '../components/Helmet';

import iotDev1JpgImg from "../assets/images/iot-development01.jpg";
import iotDev2JpgImg from "../assets/images/iot-development02.jpg";
import iotDev3JpgImg from "../assets/images/iot-development03.jpg";

export class IoTDevelopment extends Component {
    render() {
        const shareUrl = 'https://iotlinks.com/iot-development';
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure"
                    description="Internet of Things is able to gather and exchange tons of data in real time by the help of embedded sensors."
                    title="IoT Development | Gateway Alfa | iotLINKS"
                />
                <div className="blog-bg"></div>
                <Container>
                    <div className="my-5">
                        <center>
                            <h1 className="h2 text-light mb-5">Internet of Things Development</h1>
                            <img
                                className="shadow rounded cover"
                                src={iotDev1JpgImg}
                                alt="Internet of Things Development"
                                height="500px"
                                width="100%"
                            />
                        </center>
                        <div>
                            <EmailShareButton
                                className="m-1"
                                url={shareUrl}
                                subject={"What is the IoT development? by IoTLinks"}
                                body={"If you want to know more details, please visit our website"}
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <FacebookShareButton
                                className="m-1"
                                url={shareUrl}
                                hashtag="#InternetOfThings"
                                quote={"What is the IoT development, and what tools required?"}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <LinkedinShareButton
                                className="m-1"
                                url={shareUrl}
                                title={"What is the IoT development, and what tools required?"}
                                source={"iotLinks"}
                                summary={"The Internet of Things is known as a system of different interrelated computing devices, digital and mechanical machines and objects that are given UIDs (unique identifiers.)"}
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <WhatsappShareButton
                                className="m-1"
                                url={shareUrl}
                                title={"What is the IoT development, and what tools required?"}
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                    </div>
                    <div className="my-5">
                        <section className="mb-5">
                            <Title>What tools are required for IoT development?</Title>
                            <p>
                                IoT, or the Internet of Things, is known as a system of different interrelated
                                computing devices, digital and mechanical machines and objects that are given
                                UIDs (unique identifiers.) They are capable of transferring data over a
                                network without the need of human-to-machine or human-to-human interaction.
                                This growing network of connected objects is growing rapidly. It is able to
                                gather and exchange tons of data in real time by the help of embedded sensors.
                                Things like lights, thermostats, refrigerators, cars, and many other
                                applications are all connected to IoT.
                            </p>
                            <p>
                                When it comes to IoT, it helps people in living and working in a smarter
                                manner, including having complete control of their lives. Moreover, IoT is an
                                essential factor, when it comes to businesses as it provides them with a
                                glimpse into their system and how they work. Through this, businesses receive
                                insight about every single thing, whether it is their supply chain,
                                performance of the machines, logistics operations, and so on.
                            </p>
                            <p>
                                Through IoT, companies can automate their processes, which helps in reducing
                                labor costs. Plus, there is reduced amount of waste and service delivery gets
                                improved. Hence, it costs less to manufacture and then deliver goods,
                                including transparency with customer transactions. That is why, IoT is
                                considered as a highly important technology of everyday life, and it will
                                continue to grow as more companies become aware of its importance.
                            </p>
                            <p>
                                Furthermore, numerous remarkable products have been created, thanks to IoT
                                development. There are companies that have provided IoT solutions in order to
                                create hardware and software designs that have helped IoT developers in
                                creating extraordinary IoT applications and devices. So, what tools and parts
                                are required for Internet of Things development? Continue reading to find out.
                            </p>
                        </section>
                        <section className="my-5">
                            <img
                                className="shadow"
                                src={iotDev2JpgImg}
                                alt="What tools are required for IoT development"
                                width="100%"
                            />
                        </section>
                        <section className="my-5">
                            <Title>Eclipse IoT</Title>
                            <p>
                                With this tool, a user can develop, promote, and adopt open source IoT
                                technologies. This tool is ideal for building IoT devices, gateways, and Cloud
                                platforms. It supports numerous projects that involve IoT, such as open-source
                                implementation of IoT protocols, tools for utilizing Lua programming language
                                (known as the best programming language for IoT), and application services and
                                framework.
                            </p>
                        </section>
                        <Row className="my-5">
                            <Col sm={12} md={6}>
                                <div className="bg-light pt-5 pb-3 px-4 mx-1 h-100">
                                    <Title>Arduino</Title>
                                    <p>
                                        This company is based in Italy and it creates interactive microcontroller
                                        boards and objects. It’s known as an open-source prototyping platform and it
                                        offers both IoT software and hardware. In the case of a software, it includes
                                        IDE (Integrated Development Environment), while hardware specifications
                                        involve interactive electronics. When it comes to IoT development tools, this
                                        is the most preferable IDEs, and this platform is simple enough to use.
                                    </p>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="bg-light  pt-5 pb-3 px-4 mx-1 h-100">
                                    <Title>Tessel 2</Title>
                                    <p>
                                        Tessel 2 is used for building the basic IoT applications and prototypes. It
                                        helps via its various sensors and modules. By using Tessel 2 board, developers
                                        can gain Wi-Fi connectivity, Ethernet connectivity, a misco USB port, two USB
                                        ports, 64MB of RAM, and 32MB of Flash. Extra modules can also be incorporated
                                        such as accelerometers, GPS, RFID, and so on.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <section className="my-5">
                            <img
                                className="shadow"
                                src={iotDev3JpgImg}
                                alt="Internet of Things Development"
                                width="100%"
                            />
                        </section>
                        <section className="my-5">
                            <Title>Platform IoT</Title>
                            <p>
                                This cross-platform IoT IDE has an integrated debugger. It’s ideal for mobile
                                application development, which means developers can utilize a suitable IoT
                                environment for development purposes. They can easily install it as a plug-in
                                or port the IDE on Atom editor. It’s compatible with over 400 embedded boards.
                                Plus, it has over twenty development platforms and frameworks. It also comes
                                with a great interface, making is easy to use.
                            </p>
                        </section>
                        <section className="my-5">
                            <Title>Kinoma</Title>
                            <p>
                                Known as a Marvell semiconductor hardware prototyping platform, Kinoma can
                                enable three different projects. Two products are present in order to support
                                these projects; Element Board and Kinoma Create. When it comes to Element
                                Board, it is known as the smallest IoT product platform that’s powered by
                                JavaScript. When it comes to Kinoma Create, it is a hardware kit used for
                                prototyping IoT enabled devices and electronics. It comes with supporting
                                essentials such as integrated Wi-Fi, microphone, BLE (Bluetooth Low Energy),
                                touch screen, and speaker.
                            </p>
                        </section>
                        <section className="my-5">
                            <Title>M2M Labs Mainspring</Title>
                            <p>
                                This is not only an IoT platform, it is also an open source application
                                framework. M2M refers to machine to machine applications that are used in
                                fields of fleet management and remote monitoring. Moreover, it supports
                                functionality such as normalization and validation of data, data retrieval
                                processes, flexible modeling of devices, and device configuration. It’s based
                                on Cassandra, Java, Apache, and NoSQL database.
                            </p>
                        </section>

                    </div>
                </Container>
            </>
        );
    }
}
const Title = styled.h2`
    font-size: 1.3rem;
    color: #3774b0;
`;