import React, { Component } from "react";
import { Container } from "reactstrap";

export class LiveLabFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
   
    render() {
        return (
            <>
                <Container className="my-5">
                    <h1>Thank you for using the lab</h1>          
                </Container>
            </>
        );
    }
}