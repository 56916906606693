import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
} from "reactstrap";

export class Footer extends Component {
    render() {
        return (
            <footer className="footer border-top p-4">
                <Container>
                    <Row>
                        <Col className="my-2" md="3" xs="12">
                            <h3 className="title">iotLINKS</h3>
                            <ul className="list-group list-group-horizontal no-bullets">
                                <li>
                                    <a
                                        href="https://www.youtube.com/user/maazziar"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-youtube h4 mr-3" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.linkedin.com/company/arshon-technology-inc-/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-linkedin h4 mr-3" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.facebook.com/ArshonTech/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-facebook h4 mr-3" />
                                    </a>
                                </li>
                            </ul>
                            <small className="text-muted">
                                649 Mission St, 5th Floor,<br />
                                San Francisco, CA 94105
                            </small>
                        </Col>
                        <Col className="my-2" md="3" xs="12">
                            <h5 className="font-weight-bold text-uppercase">Links</h5>
                            <ul className="list-group no-bullets">
                                <li className="my-1">
                                    <Link className="text-dark" to="/iot-solution" tag={Link}>
                                        IoT Solution
                                    </Link>
                                </li>
                                <li className="my-1">
                                    <Link className="text-dark" to="/internet-of-things" tag={Link}>
                                        Internet Of Things
                                    </Link>
                                </li>
                                <li className="my-1">
                                    <Link className="text-dark" to="/iot-consultation" tag={Link}>
                                        IoT Consultation
                                    </Link>
                                </li>
                                <li className="my-1">
                                    <Link className="text-dark" to="/iot-development" tag={Link}>
                                        IoT Development
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col className="my-2" md="3" xs="12">
                            {/*<h5 className="font-weight-bold text-uppercase">*/}
                            {/*    Family Site*/}
                            {/*</h5>*/}
                            <ul className="list-group no-bullets">
                                <li className="my-1">
                                    <a
                                        className="text-dark"
                                        href="https://arshon.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Arshon Technology
                                    </a>
                                </li>
                                <li className="my-1">
                                    <a
                                        className="text-dark"
                                        href="https://arshon.store"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Buy it Here
                                     </a>
                                </li>
                            </ul>
                        </Col>
                        <Col className="my-2" md="3" xs="12">   
                            <Link to="/terms-and-conditions">
                                Terms and Conditions
                            </Link>
                           
                        </Col>
                    </Row>
                </Container>
                <div className="footer-copyright text-center py-1">

                
                    <small>
                    &copy; {new Date().getFullYear()} all copyright reserved to&nbsp;
                        <Link to="/" tag={Link}>
                            iotLINKS by Arshon Technology
                        </Link>
                    </small>
                </div>
            </footer>
        );
    }
}
