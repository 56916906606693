import React, { Component } from "react";
import ReactHelmet from "../components/Helmet";
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon,
    LinkedinIcon,
    LinkedinShareButton,
    WhatsappShareButton,
} from "react-share"
import {
    Container,
    Row,
    Col
} from "reactstrap";
import styled from "styled-components";

import iot01JpgImg from "../assets/images/internet-of-things01.jpg";
import iot02PngImg from "../assets/images/internet-of-things02.png";
import iot03JpgImg from "../assets/images/internet-of-things03.jpg";
import iot04PngImg from "../assets/images/internet-of-things04.png";
import iot05JpgImg from "../assets/images/internet-of-things05.jpg";
import iot06JpgImg from "../assets/images/internet-of-things06.jpg";
import iot07JpgImg from "../assets/images/internet-of-things07.jpg";
import iot08JpgImg from "../assets/images/internet-of-things08.jpg";
import iot09JpgImg from "../assets/images/internet-of-things09.jpg";
import iot10JpgImg from "../assets/images/internet-of-things10.jpg";
import iot11PngImg from "../assets/images/internet-of-things11.png";
import iot12PngImg from "../assets/images/internet-of-things12.png";

export class InternetOfThings extends Component {
    render() {
        const shareUrl = 'https://iotlinks.com/internet-of-things';
        return (
            <>
      
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure"
                    description="Sensors and devices that communicate with the cloud using a form of connectivity make up a complete IoT solution, and once the communicated data reaches the cloud."
                    title="Internet of Things | Gateway Alfa | iotLINKS"
                />
     
                <div className="blog-bg"></div>
                <Container>
                    <div className="my-5">
                        <center>
                            <h1 className="h2 text-light mb-5">Internet of Things</h1>
                            <img
                                className="shadow rounded cover"
                                src={iot01JpgImg}
                                alt="Internet of Things"
                                height="500px"
                                width="100%"
                            />
                        </center>
                        <div>
                            <EmailShareButton
                                className="m-1"
                                url={shareUrl}
                                subject={"What is the Internet of Things? by IoTLinks"}
                                body={"If you want to know more details, please visit our website"}
                            >
                                <EmailIcon size={30} round />
                            </EmailShareButton>
                            <FacebookShareButton
                                className="m-1"
                                url={shareUrl}
                                hashtag="#InternetOfThings"
                                quote={"What is the Internet of Things, and how does it work?"}
                            >
                                <FacebookIcon size={30} round />
                            </FacebookShareButton>
                            <LinkedinShareButton
                                className="m-1"
                                url={shareUrl}
                                title={"What is the Internet of Things, and how does it work?"}
                                source={"iotLinks"}
                                summary={"The future of IoT is very exciting since more and more innovations keep coming which means that it’s never too late to start investing in an IoT system that will benefit your business and is a market to look out for."}
                            >
                                <LinkedinIcon size={30} round />
                            </LinkedinShareButton>
                            <WhatsappShareButton
                                className="m-1"
                                url={shareUrl}
                                title={"What is the Internet of Things, and how does it work?"}
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                    </div>
                </Container>
                <div className="my-5">
                    <section className="mb-5">
                        <Container>
                            <Title>What is the Internet of Things, and how does it work?</Title>
                            <p>
                                The Internet of Things or IoT can be a confusing world for most
                                non-technical people. However, this section will hopefully
                                clarify all misconceptions and misunderstandings you might have.
                                Without further a due, let’s jump into it.
                                    </p>
                        </Container>
                    </section>
                    <section className="py-5 bg-light">
                        <Container>
                            <img
                                className="mb-3"
                                src={iot02PngImg}
                                alt="How Does IoT Work"
                                width="70px"
                            />
                            <Title>How Does IoT Work?</Title>
                        <p>
                            The Internet of Things or IoT is a system of computing devices
                            that work together often without the help of a human to
                            achieve a goal. Each computing device is provided with its
                            unique identifier or UIDs to distinguish them from other
                            computing devices and these devices are also equipped with the
                            ability to transfer data over a network.
                         </p>
                        <p>
                            To put it in perspective, The Internet of Things can be
                            compared to a camera. The camera has 4 divisions – Input of
                            data, Transfer of data, Processing of data, and Output of
                            data. Let’s say you want to take a picture. First, you need to
                            press the picture button (Input of data), once pressed the
                            camera will send a signal throughout the system (Transfer of
                            data), after this transfer of signal the camera will now
                            analyze what the signal means (Processing of data), and
                            finally once the signal is analyzed it will realize that the
                            signal means it’s time to take a picture and it will take the
                            picture (Output of Data). Here is a more in-depth explanation
                            of this process (insert the previous article).
                        </p>
                        <p>
                            As you can see, a single action can often take many steps to
                            complete such as taking a picture. Therefore, businesses are
                            now using the Internet of Things to make this process more
                            streamlined and efficient. This is what we call the Complete
                            IoT Solution (insert the previous article).
                        </p>

                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <img
                                className="shadow"
                                src={iot03JpgImg}
                                alt="Why Businesses Are Investing in IoT"
                                width="100%"
                            />
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>Why Businesses Are Investing in IoT</Title>
                            <p>
                                Different businesses have different operating protocols,
                                processes, goals, etc. The best thing about IoT is that it’s
                                completely customizable to your basic needs. The Internet of
                                things not only helps people gain control over their day to
                                dryness lives but also offers businesses a more efficient way
                                of operating. Here are some ways the Internet of Things is
                                helping businesses:
                            </p>
                            <ul>
                                <li>
                                    Providing businesses, a real-time overview of how their
                                    businesses are doing.
                                </li>
                                <li>
                                    Deliver insights into how devices are operating such as
                                    performances of machines and logistics.
                                </li>
                                <li>Metrics to improve business operation efficiency</li>
                                <li>Metrics to improve customer service</li>
                                <li>Save time and money</li>
                                <li>Reduce labor hours</li>
                                <li>
                                    Make better business decisions based on data collected
                                </li>
                                <li>And many more</li>
                            </ul>
                            <p>
                                Therefore, investing in such a system can be the difference
                                between having a mediocre business to a thriving business.
                                However, the Internet of Things is not only applicable to
                                businesses as mentioned before. It is also improving the lives
                                of people. Here are some ways the Internet of Things is
                                helping people:
                            </p>
                            <ul>
                                <li>
                                    Providing businesses, a real-time overview of how their
                                    businesses are doing.
                                </li>
                                <li>
                                    Deliver insights into how devices are operating such as
                                    performances of machines and logistics.
                                </li>
                                <li>Metrics to improve business operation efficiency</li>
                                <li>Metrics to improve customer service</li>
                                <li>Save time and money</li>
                                <li>Reduce labor hours</li>
                                <li>
                                    Make better business decisions based on data collected
                                </li>
                                <li>And many more</li>
                            </ul>
                            <p>
                                    And of course, since IoT is still very new and there are lots
                                    to discover. It is also becoming of the most important pieces
                                    of technology that we are depending on, and it will continue
                                    to be picked up by various businesses and it will only get
                                    more competitive until it becomes the deciding factor between
                                    a good business and a bad business.
                            </p>
                        </Container>
                    </section>
                    <section className="py-5 bg-light">
                        <Container>
                            <img
                                className="mb-3"
                                src={iot04PngImg}
                                alt="what Industries IoT is being used"
                                width="70px"
                            />
                            <Title>In what Industries IoT is being used?</Title>
                            <p>
                                As of now, IoT is most seen in manufacturing, transportation,
                                and utility organizations. However, IoT is being used in every
                                business sector. For example, IoT devices are being used in
                                agriculture to help farmers farm more efficiently. This is
                                done by sensors that collect data on rainfall, temperature,
                                humidity, soil content, and many others. IoT is also helping
                                with automating farming techniques to reduce labor hours.
                            </p>
                            <p>
                                 IoT is also being seen in creating smart cities which to help
                                 reduce energy and waste consumption and recycle waste into
                                 energy. IoT can also be used in simpler tasks such as making
                                 sure all equipment is running smoothly.
                            </p>
                            <p>
                                 IoT is seen in every business sector since it can accomplish
                                 big tasks and small tasks. It is seen in Food services, waste
                                 management services, forestry, fishing, arts, entertainment,
                                 education, finance, healthcare, information, and many more.
                            </p>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>Biggest Advantage and Disadvantage of IoT</Title>
                            <p>
                                Despite all the buzz and great things that the Internet of
                                Things brings to the table. It also has its downsides. As you
                                have hopefully learned by now, the Internet of Things is a
                                system comprising of devices that are connected. This allows
                                them to communicate and work together to achieve a task. But
                                this “connection” to the “billions” of other devices can
                                backfire as we will see.
                            </p>
                        </Container>
                    </section>
                    <section>
                        <Container>
                            <Row>
                                <Col sm={12} md={4}>
                                    <img
                                        className="shadow my-1"
                                        src={iot05JpgImg}
                                        alt="Internet of Things Security"
                                        width="100%"
                                    />
                                </Col>
                                <Col sm={12} md={4}>
                                    <img
                                        className="shadow my-1"
                                        src={iot06JpgImg}
                                        alt="Internet of Things Bug"
                                        width="100%"
                                    />
                                </Col>
                                <Col sm={12} md={4}>
                                    <img
                                        className="shadow my-1"
                                        src={iot07JpgImg}
                                        alt="Internet of Things Device Management"
                                        width="100%"
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>#1 Disadvantage – Security and Privacy Issues</Title>
                            <p>
                                The first disadvantage of IoT is the fact that it’s connected to billions of
                                other devices. Since the Internet of Things is usually a system of devices
                                connected to billions of other devices, a single successful hacker attack can
                                dismantle the entire system. Therefore, IoT Security and Privacy are major
                                concerns to think about before incorporating them into your business.
                            </p>
                            <p>
                                Think about it as a military base where only
                                specific authorized people can get in. It would make sense to
                                have only one entrance and exit right? Now imagine that same
                                military base but with a billion ways to get in and out. This
                                makes everything so much harder to secure and monitor. If a
                                single entrance has been breached, then the entire military
                                base collapses. This is IoT’s biggest downside, it’s the fact
                                that it has billions of vulnerabilities and once one of them
                                has been exploited, the entire system collapses.
                            </p>
                            <p>
                                One of the worst IoT attacks in history happened back in October of 2016,
                                this was the largest DDoS attack ever. You don’t need to know
                                about the technical details on how this attack was possible,
                                but it led to huge sectors of the internet going down such as
                                Netflix, Reddit, CNN, and The Guardian. This IoT attack was
                                called The Dyn Attack and it was made possible by a Botnet
                                called Mirai.
                            </p>
                            <p>
                                Additionally, hackers aren’t the only threat to
                                IoT systems. Companies who make and distribute these IoT
                                systems are also under surveillance since they can use these
                                systems to collect personal data and sell it to advertisers or
                                leak it to the public.
                            </p>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>#2 Disadvantage – Bug Fixing</Title>
                            <p>
                                The second disadvantage of IoT is again because it’s connected
                                to billions of other devices. If there happens to be an error
                                or a bug in one device that’s connected to the system. Then,
                                it might be hard to track down which device is at fault and if
                                it’s creating other problems within the system. This is
                                because a connected device often relies on other connected
                                devices and if one of the devices fails, then the devices that
                                depended on the failed device will also fail. This cycle can
                                go on thousands of times until it’s almost impossible to track
                                down, especially in a system where there are billions of
                                devices.
                            </p>
                            <p>
                                However, the one thing we have learned from
                                technology is that it’s always evolving. This means that
                                solutions and processing power will always grow faster and
                                better. Therefore, in the future, we can hopefully develop a
                                way to track these errors and manage them more efficiently.
                            </p>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>#3 Disadvantage – Device Management</Title>
                            <p>
                                Companies that use IoT systems usually have billions of
                                devices running the system. These companies might have a hard
                                time dealing with the massive number of devices. Since these
                                devices often have to be regularly checked upon to make sure
                                everything is working smoothly, and it can also be hard to
                                collect and manage the data that’s coming from all these
                                devices. Not to mention that the designing and development
                                already takes lots of resources and is quite complicated.
                            </p>
                        </Container>
                    </section>
                    <section>
                        <Container>
                            <Row>
                                <Col sm={12} md={4}>
                                    <img
                                        className="shadow my-1"
                                        src={iot08JpgImg}
                                        alt="Internet of Things Task Automation"
                                        width="100%"
                                    />
                                </Col>
                                <Col sm={12} md={4}>
                                    <img
                                        className="shadow my-1"
                                        src={iot09JpgImg}
                                        alt="Internet of Things Better Business Decisions"
                                        width="100%"
                                    />
                                </Col>
                                <Col sm={12} md={4}>
                                    <img
                                        className="shadow my-1"
                                        src={iot10JpgImg}
                                        alt="Internet of Things Save Time and Money"
                                        width="100%"
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>#1 Advantage – Task Automation</Title>
                            <p>
                                As we know by now, the main purpose of an IoT system is to
                                connect devices and enable them to communicate with each other
                                to achieve a task. With an IoT system, this task process is
                                done automatically with hardly any human intervention.
                            </p>
                            <p>
                                IoT systems can often automate tasks that are often mundane and
                                repetitive to reduce labor hours and increase workplace
                                happiness. This gives your employees the mental and physical
                                resources to concentrate on the more vital tasks of your
                                operations.
                            </p>
                            <p>
                                IoT Task Automation not only brings the benefit of
                                automating tasks, but it also brings other benefits with it
                                such as reduced labor hours, minimized human effort, save
                                time, etc.
                            </p>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>#2 Advantage – Better Business Decisions</Title>
                                <p>
                                    Since IoT Systems often have lots of input devices. We tend to
                                    get lots of data in return, and more data leads to better
                                    business decisions. This is another benefit of an IoT system
                                    which is the ability to have the data that you need to make
                                    the right decision.
                                </p>
                                <p>
                                    An example of business decisions you might
                                    need to make is resource allocation. How many resources do we
                                    want to allocate to this department? How many resources is
                                    this department wasting and can we move it to somewhere else?
                                    These questions often come up in the process and the one thing
                                    they have in common is that they both need data to support a
                                    decision. Fortunately, you have all the data you need at your
                                    fingertips because of your IoT system.
                                </p>
                                <p>
                                    Another example would
                                    be if we needed to make a business assessment of what’s
                                    working and what’s not. The only way to truly this out is by
                                    looking at data. With IoT systems, you have access to these
                                    data and it will help inform you to make the right decision.
                                    In conclusion, if you have an IoT system, you can access data
                                    that you need to make those critical business decisions.
                                    Without it, you’re going in blind.
                                </p>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <Title>#3 Advantage – Save Time and Money!</Title>
                                <p>
                                    With IoT systems, you can cut out a lot of labor hours which means
                                    you get to save lots of time, and this is especially true for
                                    companies who have lots of employees doing the same thing. IoT
                                    systems can also save money in the process by having only 1 system
                                    performing all the mundane and repetitive tasks instead of 10,000
                                    employees which need to be paid at an hourly rate plus the extra
                                    fees that might come with it.
                                </p>
                                <p>
                                    Here are some examples of how your
                                    business cans save time and money: In one company, IoT systems
                                    have saved electricity bills by creating an automation system that
                                    automatically dims lights at a certain time because lightbulbs
                                    that are dimmed by 50% on average last 20 times longer. This
                                    company has saved 20% in energy consumption in one year, which is
                                    a lot of money saved since one of the highest stable expenses of
                                    businesses is utilities.
                                </p>
                                <p>
                                    Another example would be on a petrochemical plant, and you can imagine how
                                    much resources and time was needed to inspect a large vessel that stores
                                    millions of liters of fuel. Inspecting a fuel vessel is an important task to
                                    make sure there is not leaking, cracks or any damage in the vessel build
                                    itself because one crack can ruin the entire plant.
                                </p>
                                <p>
                                    However, with IoT devices like drones, you can collect data and pass It
                                    through another device quite easily! This means you only need to send in one
                                    drone that has a stable cloud connection to capture the inspection data and
                                    you’re all set! This job usually took 6 weeks to complete but now it takes
                                    under 15 minutes and the amount of money saved is at least $1 Million. Not to
                                    mention the amount of time and money you save from future inspections and
                                    future part purchases if there were any damages.
                                </p>
                        </Container>
                    </section>
                    <section className="py-5 bg-light">
                        <Container>
                            <img
                                className="mb-3"
                                src={iot11PngImg}
                                alt="History of IoT"
                                width="70px"
                            />
                        <Title>History of IoT</Title>
                            <p>
                                The first IoT example occurred before it was even given the name “Internet of
                                Things”. It was a Coca-Cola machine in the early ’80s. A student at Stanford
                                was tried of checking if the vending machine had any cola in it. So, this
                                student gathered a few friends to develop a system to connect the vending
                                machine via the ARPANET (precursor to today’s internet) which enabled them to
                                remotely check the status of the vending machines cola.
                            </p>
                            <p>
                                However, as mentioned it wasn’t until 1999 until the term “Internet of Things”
                                was made. It was the title of a presentation Ashton made as a brand manager
                                for Procter & gamble. In his presentation, he shared the new technology at the
                                time called radio frequency identification (RFID). These RFIDs could be used
                                on their products to allow identification and tracking since RFIDs can
                                transfer small bits of data wirelessly. This meant that customers would be
                                able to check if the store had a stock of an object and where it’s located.
                            </p>
                            <p>
                                Later, Ashton gave hundred of presentations more to small and big corporations
                                about the potential of RFID chips and how they can not only track and locate
                                each product but also communicate to other pieces of technology and machinery
                                via a wireless network and all this was because of one incident Ashton
                                encountered.
                            </p>
                            <p>
                                One day, Ashton was concerned that every time he went to his local P&G store
                                and a certain shade of brown lipstick seemed to always be out of stock. So, he
                                checked in with the P&G’s supply chain and they say it’s available in the
                                warehouse. You can imagine that customers going in and out of the warehouse
                                itself isn’t very efficient. Ashton wanted to know where the lipstick was,
                                what’s happening to it, where it’s going if it’s in stock, and many more. But
                                how is he going to do this? And that’s when he thought about the RFIDs.
                            </p>
                        </Container>
                    </section>
                    <section className="my-5">
                        <Container>
                            <img
                                className="mb-3"
                                src={iot12PngImg}
                                alt="Future of IoT"
                                width="70px"
                            />
                        <Title>Future of IoT</Title>
                            <p>
                                Since the time Ashton thought about the RFID idea. RFID chips have gotten
                                better and better, cheaper and cheaper, and the more investments companies
                                made, the more efficient they got. And they will continue to be cheaper,
                                better, more efficient as time goes on. By the time 2010 rolled around,
                                corporations were already jumping on the bandwagon with IBM starting Smarter
                                Planet, Mckinsey writing reports on the Internet of Things, Cisco declaring
                                that IoT was born to the point that there are more devices connected to the
                                internet than people are living.
                            </p>
                            <p>
                                The Internet of Things is now transforming and reshaping how businesses are
                                operated, and how lives are lived. The number of devices connected to the
                                internet is only increasing and is highly unlikely to ever crash. A report
                                published in August 2021, shows that as we approach the end of 2021, there
                                will be 35.82 billion IoT devices installed worldwide, and this is expected to
                                double by 2025. For comparison, there were 22 billion IoT devices installed
                                worldwide throughout 2018. Another statistic shows that the amount of money
                                invested has surpassed $1 trillion in 2020 alone and the market value for the
                                Internet of Things is expected to be $4 trillion by 2025.
                            </p>
                            <p>
                                In conclusion, the future of IoT is very exciting since more and more
                                innovations keep coming which means that it’s never too late to start
                                investing in an IoT system that will benefit your business and is a market to
                                look out for.
                            </p>
                        </Container>
                    </section>
                </div>
            </>
        );
    }
}
const Title = styled.h2`
    font-size: 1.3rem;
    color: #3774b0;
`;