import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Alert, FormGroup, Label, Input, FormText, Form } from 'reactstrap';
import { postHttp } from '../utils/userAPI'

export class ModalResendCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            isSuccess: false,
            modal: this.props.modal,
            form: {
                email: '',
            },
        };
    }
    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }
    toggle = () => {
        this.props.onClickModal();
    }
    handleSubmit = async (e) => {
        e.preventDefault()
        const path = "resendcode"
        const data = { userEmail: this.state.form.email }

        postHttp(data, path)
            .then((res) => {
                this.setState({
                    message: res,
                    isSuccess: true
                })
            })
            .catch(err => {
                console.log(err.message)
                this.setState({
                    message: err.message,
                    isSuccess: false
                })
            })
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Resend Verification Codes</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label htmlFor="email">Email</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="Enter email"
                                    value={this.state.form.email}
                                    onChange={this.handleChange}
                                    required
                                />
                                <FormText color="muted">Please provide a same email address when register</FormText>
                            </FormGroup>
                            {
                                this.state.message !== "" &&
                                (
                                    <Alert color={this.state.isSuccess ? "success" : "danger"}>
                                        { this.state.message}
                                    </Alert>
                                )
                            }
                            <div className="text-center">
                                <Button color="primary">SEND CODE</Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
