import React, { Component } from 'react';
import { Container } from "reactstrap";
import ReactHelmet from '../components/Helmet';
import { PageHeader } from "./styles/Pages";

export class GatewayBeta extends Component {
  
    render() {
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, application"
                    description="Gateway Beta"
                    title="Gateway Beta | iotLINKS"
                />
                <PageHeader>
                    <h1>Gateway Beta</h1>
                </PageHeader>
                <Container>
                    <div style={{ height: "500px" }}>
                        <br/><br/>
                        <h3>Coming Soon ...</h3>
                    </div>
                </Container>
            </>
        );
    }
}