import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from "reactstrap";
import ReactHelmet from '../components/Helmet';
import { PageHeader } from "./styles/Pages";
import styled from "styled-components";

import iotAppHeadJpgImg from "../assets/images/iot-application-header-bg.jpg";
import iotApp1JpgImg from "../assets/images/iot-application-01.jpg";
import iotApp2JpgImg from "../assets/images/iot-application-02.jpg";
import iotApp3JpgImg from "../assets/images/iot-application-03.jpg";
import iotApp4JpgImg from "../assets/images/iot-application-04.jpg";
import iotApp5JpgImg from "../assets/images/iot-application-05.jpg";
import iotApp6JpgImg from "../assets/images/iot-application-06.jpg";
import iotApp7JpgImg from "../assets/images/iot-application-07.jpg";

export class IoTApplication extends Component {
  
    render() {
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, application"
                    description="Arshon IoT list of applcations"
                    title="IoT Application | Gateway Alfa | iotLINKS"
                />
                <PageHeader bg={iotAppHeadJpgImg}>
                    <h1>IoT Applications</h1>
                </PageHeader>
                <Container>
                    <Items>
                        <Item bg={iotApp2JpgImg} to="/iot-applications/water-pump-control">
                            <strong>Water Pump Control</strong>
                        </Item>
                        <Item bg={iotApp3JpgImg} to="/iot-applications/solar-panels-power-monitoring-cleaning">
                            <strong>Solar Panels Power Monitoring and Cleaning</strong>
                        </Item>
                        <Item bg={iotApp4JpgImg} to="/iot-applications/pet-tracking-system">
                            <strong>Pet Tracking System</strong>
                        </Item>
                        <Item bg={iotApp5JpgImg} to="/iot-applications/remote-access-control">
                            <strong>Remote Access Control</strong>
                        </Item>
                        <Item bg={iotApp6JpgImg} to="/iot-applications/air-quality-control">
                            <strong>Air Quality Control</strong>
                        </Item>
                        <Item bg={iotApp7JpgImg} to="/iot-applications/industrial-applications">
                            <strong>Industrial applications</strong>                            
                        </Item>
                        <Item bg={iotApp1JpgImg} to="/iot-applications/agricultural-irrigation-system">
                            <strong>Agricultural Irrigation System</strong>
                        </Item>
                        <Item empty="ture" to="#" />
                    </Items>
                </Container>
            </>
        );
    }
}

const Items = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    padding: 60px 0 40px;
    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Item = styled(Link)`
    display: flex;
    height: 400px;
    background: url(${props => props.bg}) no-repeat center -60px ${props => props.empty ? '' : '#eee'};
    background-size: cover;
    margin: 0 15px 30px 0;
    padding: 20px;
    align-items: end;
    color: black;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    ${
        props => !!(props.empty) && 'cursor: default; pointer-events: none;'
    }
    :hover {
        ${
            props => !(props.empty) && 'box-shadow: 0px 0px 10px 5px #bbb;'
        }
    }
    :nth-of-type(even) {
        margin-left: 15px;
    }
    strong {
        margin-bottom: -4px;
        font-size: 1.3rem;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
    }
    :hover {
        text-decoration: none !important;
        color: black;
    }
    p {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        margin: 0 0 40px !important;
        width: calc(100vw - 30px);
    }
`;