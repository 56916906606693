import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Container, Card, CardBody, CardTitle, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import { getUserByEmail } from '../utils/userAPI'
import styled from "styled-components";

import dashboard1PngImg from "../assets/images/dashboard01.png";
import dashboard2PngImg from "../assets/images/dashboard02.png";

export class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fname:''
        };
    }
    componentDidMount() {
        this.getUserInfo()
        console.log(process.env.NODE_ENV);
    }
    getUserInfo = async () => {
        let user = this.context.auth.username
        if (user) {
            getUserByEmail(user).then((data) => {
                this.setState({
                    fname: data.firstName,
                });
            }).catch(err => {
                console.log(err.message)
            })
        }
    }
    render() {
        return (
            <>
                <Container className="my-5">
                    <div className="mb-4">
                    <h1 className="h2">Dashboard</h1>
                        <p>Welcome, {this.state.fname}!</p>
                    </div>
                    <Row>
                        <Col sm="12" lg="6" xl="3">
                            <BlueCard className="shadow mx-2 my-4">
                                <CardBody className="text-center">                       
                                    <span className="fa-stack fa-4x">
                                        <i className="fa fa-circle fa-stack-2x icon-background" style={{ color: "#007bff8a" }}></i>
                                        <i className="fas fa-tablet-alt fa-stack-1x text-light"></i>
                                    </span> 
                                </CardBody>
                                <CardBody className="text-center">
                                    <CardTitle className="text-center text-light" tag="h6">Activate your device</CardTitle>  
                                    <Link className="text-light" to="/device-register">Go to register</Link>
                                </CardBody>
                            </BlueCard>
                        </Col>
                        <Col sm="12" lg="6" xl="3">
                            <GreenCard className="shadow mx-2 my-4">
                                <CardBody className="text-center">
                                    <span className="fa-stack fa-4x">
                                        <i className="fa fa-circle fa-stack-2x icon-background" style={{ color: "#2167188a" }}></i>
                                        <i className="far fa-user fa-stack-1x text-light"></i>
                                    </span>
                                </CardBody>
                                <CardBody className="text-center">
                                    <CardTitle className="text-center text-light" tag="h6">Update your profile</CardTitle>
                                    <Link className="text-light" to="/profile">Go to profile</Link>
                                </CardBody>
                            </GreenCard>
                        </Col>
                        <Col sm="12" lg="6" xl="3">
                            <YellowCard className="shadow mx-2 my-4">
                                <CardBody className="text-center">
                                    <span className="fa-stack fa-4x">
                                        <i className="fa fa-circle fa-stack-2x icon-background" style={{ color: "#d37500bd" }}></i>
                                        <i className="far fa-chart-bar fa-stack-1x text-light"></i>
                                    </span>
                                </CardBody>
                                <CardBody className="text-center">
                                    <CardTitle className="text-center text-light" tag="h6">Manage your data</CardTitle>
                                    <Link className="text-light" to="/historical-chart">Go to detail</Link>
                                </CardBody>
                            </YellowCard>          
                        </Col>
                        <Col sm="12" lg="6" xl="3">
                            <RedCard className="shadow mx-2 my-4">
                                <CardBody className="text-center">
                                    <span className="fa-stack fa-4x">
                                        <i className="fa fa-circle fa-stack-2x icon-background" style={{ color: "#f9a6dfbd" }}></i>
                                        <i className="fas fa-edit fa-stack-1x text-light"></i>
                                    </span>
                                </CardBody>
                                <CardBody className="text-center">
                                    <CardTitle className="text-center text-light" tag="h6">Setup device ports</CardTitle>
                                    <Link className="text-light" to="/device-setting">Go to setting</Link>
                                </CardBody>
                            </RedCard>   
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Card className="shadow mx-2 my-4 bg-light">
                                <CardBody className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <CardTitle tag="h6">Check Video Tutorials</CardTitle>
                                        <CardText>Learn how to use iotlinks.com</CardText>
                                        <Link to="/device-register">Go to details</Link>
                                     </div>
                                    <img
                                        src={dashboard1PngImg}
                                        alt="tutorials"
                                        width="80px"
                                        height="auto"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="12" md="6">
                            <Card className="shadow mx-2 my-4 bg-light">
                                <CardBody className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <CardTitle tag="h6">Need Consultant</CardTitle>
                                        <CardText>We provide professional engineering consultant.</CardText>
                                        <a href="https://forum.iotlinks.com" target="_blank" rel="noopener noreferrer">Get in touch</a>
                                    </div>
                                    <img
                                        src={dashboard2PngImg}
                                        alt="consultant"
                                        width="80px"
                                        height="auto"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );

    }
}
Dashboard.contextTypes = {
    auth: PropTypes.object
}
const BlueCard = styled(Card)`
    background: linear-gradient(45deg,#4099ff, #314673);
`;
const GreenCard = styled(Card)`
    background: linear-gradient(45deg, #1E8A75, #314673);
`;
const YellowCard = styled(Card)`
    background: linear-gradient(45deg, #FBA830, #314673);
`;
const RedCard = styled(Card)`
    background: linear-gradient(45deg, #B55D6D, #314673);
`;