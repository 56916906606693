export const browserStorageDeviceIdKey = "SelectedDeviceId";
export const browserStorageHistoryChartKey = "SelectedHistoryChart";

export const browserStorage = {
    set: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
    get: (key) => {
        const item = localStorage.getItem(key);
        try {
            return JSON.parse(item); // Is Object/Array
        } catch (err) {
            return item; // Is Not Object/Array
        }
    },
    remove: (key) => {
        localStorage.removeItem(key);
    }
};