import React, { Component } from "react";
import { Container, Button, Form, FormGroup, Label, Input, FormText, FormFeedback, Alert } from 'reactstrap';
import { validatePasswordStrong } from '../helpers/validate'
import { changePassword } from '../utils/userAPI'
import styled from 'styled-components';

export class FormProfilePwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successMsg: {
                isOpen: false,
                msg: ''
            },
            failMsg: {
                isOpen: false,
                msg: ''
            },
            form: {
                oldPassword: '',
                password: '',
                passwordConfirmation: '',
            },
            formErrors: {
                minimum: 'Must be 8 Characters',
                uppercase: 'Must have uppercase',
                lowercase: 'Must have lowercase',
                numeric: 'Must have numeric characters',
                passwordConfirmation: '',
            },
            formValidity: {
                minimum: false,
                uppercase: false,
                lowercase: false,
                numeric: false,
                passwordConfirmation: false
            },
        };

    }
    handleChange = (event) => {
        const { name, value } = event.target
        if (name === "oldPassword") {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value
                }
            })
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value
                }
            }, function () { this.validateField(name, value) })
        }
    }

    validateField(name, value) {
        if (name === "password") {
            const { formValidity, formErrors } = this.state
            const { validity, fieldValidationErrors } = validatePasswordStrong(formValidity, value, formErrors)
            this.setState({
                ...this.state,
                formErrors: fieldValidationErrors,
                formValidity: validity,
            })
            if (this.state.form.passwordConfirmation !== '') {
                if (this.state.form.passwordConfirmation !== value) {
                    this.setState({
                        formErrors: {
                            ...this.state.formErrors,
                            passwordConfirmation: 'Confirm password should be matched',
                        },
                        formValidity: {
                            ...this.state.formValidity,
                            passwordConfirmation: false
                        },
                    })
                } else {
                    this.setState({
                        formErrors: {
                            ...this.state.formErrors,
                            passwordConfirmation: '',
                        },
                        formValidity: {
                            ...this.state.formValidity,
                            passwordConfirmation: true
                        },
                    })
                }

            }
        }
        else if (name === 'passwordConfirmation') {
            if (this.state.form.password !== value) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        passwordConfirmation: 'Confirm password should be matched',
                    },
                    formValidity: {
                        ...this.state.formValidity,
                        passwordConfirmation: false
                    },
                })
            } else {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        passwordConfirmation: '',
                    },
                    formValidity: {
                        ...this.state.formValidity,
                        passwordConfirmation: true
                    },
                })
            }
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        const { minimum, uppercase, lowercase, numeric, passwordConfirmation } = this.state.formValidity

        if (minimum && uppercase && lowercase && numeric && passwordConfirmation) {
            const formData = { userEmail: this.props.user, currentPassword: this.state.form.oldPassword, newPassword: this.state.form.password }
            const path = 'changepassword'
            changePassword(formData, path)
                .then((res) => {
                    this.setState({
                        failMsg: {
                            isOpen: false,
                        },
                        successMsg: {
                            isOpen: true,
                            msg: res
                        },
                        form: {
                            oldPassword: '',
                            password: '',
                            passwordConfirmation: '',
                        },
                    }, () => {
                        window.setTimeout(() => {
                            this.setState({
                                successMsg: {
                                    isOpen: false,
                                    msg: ''
                                },
                            })
                        }, 3500)
                    })
                })
                .catch(err => {
                    this.setState({
                        failMsg: {
                            isOpen: true,
                            msg: err.message
                        },
                        form: {
                            oldPassword: '',
                            password: '',
                            passwordConfirmation: '',
                        },
                    })
                })
        }
    }
    closeFailMsg = () => {
        this.setState({
            failMsg: {
                isOpen: false,
                msg: ''
            }
        })
    }

    render() {
        const { form, formErrors, successMsg, failMsg } = this.state
        return (
            <>
                <Container className="border border-top-0">
                    <Form className="px-2 py-4" onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="oldPassword">Current Password</Label>
                            <Input
                                type="password"
                                name="oldPassword"
                                id="oldPassword"
                                placeholder="Enter current password"
                                value={form.oldPassword}
                                onChange={this.handleChange}
                                required
                            />
                            <div className="invalid-feedback">
                                {this.state.formErrors.oldPassword}
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label for="password">New Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                value={form.password}
                                onChange={this.handleChange}
                                placeholder="Enter new password"
                                required
                            />
                            <FormText className="pl-2" color="danger">
                                {formErrors.minimum}
                            </FormText>
                            <FormText className="pl-2" color="danger">
                                {formErrors.uppercase}
                            </FormText>
                            <FormText className="pl-2" color="danger">
                                {formErrors.lowercase}
                            </FormText>
                            <FormText className="pl-2" color="danger">
                                {formErrors.numeric}
                            </FormText>
                            <div className="invalid-feedback">
                                {this.state.formErrors.password}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="passwordConfirmation">Confirm Password</Label>
                            <Input
                                type="password"
                                name="passwordConfirmation"
                                id="passwordConfirmation"
                                value={form.passwordConfirmation}
                                onChange={this.handleChange}
                                placeholder="Confirm new password"
                                required
                                invalid={!this.state.formValidity.passwordConfirmation && this.state.formErrors.passwordConfirmation !== ''}
                            />
                            <FormFeedback>{this.state.formErrors.passwordConfirmation}</FormFeedback>
                        </FormGroup>
                        {
                            failMsg.isOpen && (
                                <Alert isOpen={failMsg.isOpen} color="danger" toggle={this.closeFailMsg}>
                                    {failMsg.msg}
                                </Alert>
                            )
                        }
                        <Button color="primary">CHANGE YOUR PASSWORD</Button>

                    </Form>
                </Container>
                {
                    successMsg.isOpen && (
                        <StyledAlert color="success" isOpen={successMsg.isOpen}>
                            {successMsg.msg}
                        </StyledAlert>
                    )
                }
            </>
        );
    }
}
const StyledAlert = styled(Alert)`
  padding: 20px;
  position: fixed;
  left: 0 !important;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
`;
export default FormProfilePwd;