import React, { Component } from 'react';
import { Container } from "reactstrap";
import ReactHelmet from '../components/Helmet';
import { PageHeader, PageH2, PageH3 } from "./styles/Pages";
import styled from 'styled-components';

import gatewayAlphaPagePngImg from '../assets/images/gateway-alpha-page.png';
import SpecPdf from '../assets/documents/AST1207-Gateway-Alfa-Specification-V1.pdf';

export class GatewayAlpha extends Component {
  
    render() {

        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, application"
                    description="Gateway Alfa (Alpha) is a low cost Gateway to connect your devices to the Cloud."
                    title="Gateway Alfa (Alpha) | iotLINKS"
                />
                <PageHeader>
                    <h1>Gateway Alfa (Alpha)</h1>
                </PageHeader>

                <Container>

                    <ImgTop src={gatewayAlphaPagePngImg} />

                    <PageH2>
                        Gateway Alfa (Alpha)
                        <DownloadSpec href={SpecPdf} target="_blank">
                            Download Specification
                        </DownloadSpec>
                    </PageH2>

                    <Clear />

                    <p>Gateway Alfa (Alpha) is a low cost WIFI Gateway which provide basic cloud conjunctivitis with a lot of port and flexibility. Gateway Alfa (Alpha) is designed to connect to local WIFI network and through the Internet connect to iotLINKS cloud and Dashboard platform.</p>
                    <p>All port and features of this powerful Gateway are explained in below:</p>

                    <br/>
                    <PageH3>Internal Temperature Sensor</PageH3>

                    <p>Gateway Alfa (Alpha) has an internal Temperature sensor which measure the device temperature and it can be used for monitoring the device temperature as well as environment around the device. The accuracy of the measurement for outside measurement is not very high but may be enough for a lot of applications.</p>
                    <p>For example, if you embed the device into your product, you can always have a sense of temperature of your device by reading the value of this internal sensor.</p>

                    <VideoContainer>
                        <VideoHeader>Internal Temperature Sensor</VideoHeader>
                        <VideoWrap>
                            <iframe title="Internal Temperature Sensor" frameborder="0" allow="fullscreen;" allowfullscreen
                                    src="https://www.youtube.com/embed/IPkscwJkq9Y?rel=0&enablejsapi=1" />
                        </VideoWrap>
                    </VideoContainer>

                    <br />
                    <PageH3>ADC Port</PageH3>

                    <p>ADC or Analog to Digital Converter on board is very useful port associated with Gateway Alfa (Alpha) and it can be used a lot of different application where the needs of Analog to Digital conversion is needed. The Analog input is reverse polarity and over voltage protected and the user can measure a voltage signal between 0 to 10 volts. The sampling can be adjusted as well as the memory buffer size.</p>

                    <VideoContainer>
                        <VideoHeader>Measuring an analog voltage using Gateway Alfa (Alpha)</VideoHeader>
                        <VideoWrap>
                            <iframe title="Measuring an analog voltage using Gateway Alfa (Alpha)" frameborder="0" allow="fullscreen;" allowfullscreen
                                    src="https://www.youtube.com/embed/JiiiXSJIocE?rel=0&enablejsapi=1" />
                        </VideoWrap>
                    </VideoContainer>

                    <br />
                    <PageH3>Relay Contacts</PageH3>

                    <p>Gateway Alfa (Alpha) is equipped with a UL rated 120VAC/240VAC/10A relay and it provides bot normally open and normally closed contacted.</p>
                    <p>This relay can also be controlled via the dashboard and considered as one of the essential features of this Gateway.</p>

                    <VideoContainer>
                        <VideoHeader>Operating a Relay Contact remotely using Gateway Alfa (Alpha)</VideoHeader>
                        <VideoWrap>
                            <iframe title="Operating a Relay Contact remotely using Gateway Alfa (Alpha)" frameborder="0" allow="fullscreen;" allowfullscreen
                                    src="https://www.youtube.com/embed/nCwrMt9m5fU?rel=0&enablejsapi=1" />
                        </VideoWrap>
                    </VideoContainer>

                    <br />
                    <PageH3>PWM Output</PageH3>

                    <p>PWM or Pulse Width Modulation is a standard signal for intensity control, for example a motor speed can be controller by this signal, or an intensity of LED light or the power setting of a electric heater can be controller via this signal. Gateway Alfa (Alpha) is capable of controlling the frequency and Duty Cycle of this signal in a very reasonable range and its facilities some intensity control applications remotely via the iotLINKS dashboard.</p>
                    
                    <VideoContainer>
                        <VideoHeader>Controlling PWM frequency and Duty Cycle, using Gateway Alfa (Alpha)</VideoHeader>
                        <VideoWrap>
                            <iframe title="Controlling PWM frequency and Duty Cycle, using Gateway Alfa (Alpha)" frameborder="0" allow="fullscreen;" allowfullscreen
                                    src="https://www.youtube.com/embed/RcFH4nhYSIg?rel=0&enablejsapi=1" />
                        </VideoWrap>
                    </VideoContainer>

                    <br />
                    <PageH3>Serial Port</PageH3>

                    <p>Serial Port is the most important port of this device, you can expand the Gateway to pretty much any other sensor or device and opportunities are limitless, for example you can connect a LORA Transceiver to this port and convert your Gateway Alfa (Alpha) to a LORA Gateway, or you can connect a sensor such as humidity sensor or pressure sensor to this serial port and send the pressure data to cloud. Or you can connect the serial port to a Relay expander board to expand the number of Relay devices to a higher number.</p>

                    <VideoContainer>
                        <VideoHeader>Using the Serial Port in Gateway Alfa (Alpha) as an Expansion of Functionality</VideoHeader>
                        <VideoWrap>
                            <iframe title="Using the Serial Port in Gateway Alfa (Alpha) as an Expansion of Functionality" frameborder="0" allow="fullscreen;" allowfullscreen
                                    src="https://www.youtube.com/embed/9ygfgCLkRN4?rel=0&enablejsapi=1" />
                        </VideoWrap>
                    </VideoContainer>

                    <br /><br />
                    
                </Container>
            </>
        );
    }
}

const ImgTop = styled.img`
    display: block;
    width: 70%;
    margin: 0 auto 60px;
    @media only screen and (max-width: 768px) {
        margin: 10px auto 30px;
        width: 90vw;
    }
`;

const VideoContainer = styled.div`
    margin: 40px 0;
    padding: 20px 30px 30px;
    background: #eee;
    border-radius: 5px;
`;

const VideoHeader = styled.h4`
    margin: 0 0 20px;
    text-align: center;
`;

const VideoWrap = styled.div`
    width: 100%; height: 0;
    background: #eee;
    border: solid 1px #ccc;
    text-align: center;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    iframe {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
    }
`;

const DownloadSpec = styled.a`
    display: block;
    padding: 10px 20px;
    background: #3774b0;
    color: white !important;
    float: right;
    border-radius: 5px;
    cursor: pointer;
    :hover {
        text-decoration: none !important;
        background: #2C669E;
    }
    @media only screen and (max-width: 768px) {
        margin: 20px 0;
    }
`;

const Clear = styled.div`
    clear: both;
`;