import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Input, Label, Card, CardBody, CardHeader, CardFooter, FormFeedback, Alert } from 'reactstrap'
import * as deviceApi from '../utils/deviceAPI'
import { getUserDevicesByEmail } from '../utils/userAPI';
import styled from 'styled-components';
import PropTypes from 'prop-types'
import { browserStorage, browserStorageDeviceIdKey } from '../utils/browserStorage';

export class PortSetting extends Component {

    static displayName = PortSetting.name;
    BrowserStorageDeviceIdKey = "PortSettingDeviceId";

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            isSuccess: false,
            fetchedData: [],
            relayContactDisplay: 'Energized(Future development)',
            formInputs: {
                // Device
                thingName: browserStorage.get(browserStorageDeviceIdKey) || "",
                deviceDescription: '',
                thingModel: '',
                thingFirmVer: '',
                thingHardVer: '',
                // ADC
                adconvertorStatus: false,
                adconvertorSampling: '',
                adconvertorUpdatingTime: '',
                adconvertorBufferLength: '',
                // Temp
                tempSensorStatus: false,
                tempSensorUpdateInterval: '',
                // PWM
                pulseWidthModulatorStatus: false,
                pulseWidthModulatorDutyCycle: '',
                pulseWidthModulatorFrequency: '',
                // RC
                //relayContactStatus: false,
                relayContactOutput: false,
                // Serial Port
                serialPortStatus: false,
                serialPortBaudRate: '',
                serialPortStopBits: '',
                serialPortDataBits: '',
                serialPortBufferSize: '',
                serialPortParity: ''
            },
            deviceIdReq: false
        };
    }
    componentDidMount() {
        this.getUserDevicesInfo();
    }
    getUserDevicesInfo = async () => {
        let user = this.context.auth.username
        if (user) {
            getUserDevicesByEmail(user).then((data) => {
                this.setState({
                    fetchedData: data.deviceList,
                });
                if (data.deviceList.length && !browserStorage.get(browserStorageDeviceIdKey)) {
                    this.setState({
                        formInputs: {
                            ...this.state.formInputs,
                            thingName: data.deviceList[0].thingName
                        }
                    });
                    this.handleThingQueryState({
                        target: {
                            value: data.deviceList[0].thingName
                        }
                    });
                } else if (browserStorage.get(browserStorageDeviceIdKey)) {
                    this.handleThingQueryState({
                        target: {
                            value: browserStorage.get(browserStorageDeviceIdKey)
                        }
                    });
                }
            }).catch(err => {
                console.log(err.message)
            })
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            formInputs: {
                ...this.state.formInputs,
                [name]: value
            }
        })
    }
    onShowAlert = () => {
        this.setState({ visible: true }, () => {
            window.setTimeout(() => {
                this.setState({ visible: false })
            }, 2000)
        });
    }
    handleThingQueryState = (event) => {
        this.setState({
            deviceIdReq: true
        });
        const { value } = event.target;
        console.log(value)
        deviceApi.gethttp(value).then((data) => {
            console.log(data)
            this.setState({
                deviceIdReq: false,
                formInputs: {
                    thingName: value,
                    thingModel: data.modelName,
                    deviceDescription: data.deviceDescription,
                    thingFirmVer: data.firmwareVersion,
                    thingHardVer: data.hardwareVersion,
                    tempSensorValue: data.tempSensorValue,
                    tempSensorUpdateInterval: data.tempSensorUpdateInterval,
                    tempSensorStatus: data.tempSensorStatus,
                    relayContactOutput: data.relayContactOutput,
                    pulseWidthModulatorStatus: data.pulseWidthModulatorStatus,
                    pulseWidthModulatorDutyCycle: data.pulseWidthModulatorDutyCycle,
                    pulseWidthModulatorFrequency: data.pulseWidthModulatorFrequency,
                    adconvertorStatus: data.adconvertorStatus,
                    adconvertorSampling: data.adconvertorSampling,
                    adconvertorUpdatingTime: data.adconvertorUpdatingTime,
                    adconvertorBufferLength: data.adconvertorBufferLength,
                    adconvertorBufferValues: data.adconvertorBufferValues,
                    serialPortStatus: data.serialPortStatus,
                    serialPortBaudRate: data.serialPortBaudRate,
                    serialPortStopBits: data.serialPortStopBits,
                    serialPortDataBits: data.serialPortDataBits,
                    serialPortBufferSize: data.serialPortBufferSize,
                    serialPortParity: data.serialPortParity,
                }
            })
            // Save selected value into Browser Storage for later use
            browserStorage.set(browserStorageDeviceIdKey, this.state.formInputs.thingName);
        }
        )
    }

    submitDescription = e => {
        e.preventDefault();
        let { thingName, deviceDescription } = this.state.formInputs
        const option = {
            thingName: thingName,
            deviceDescription: deviceDescription,
        }
        this.setState({
            deviceIdReq: true
        });
        deviceApi.patchhttp(thingName, option).then(() => {
            this.setState({
                isSuccess: true,
                deviceIdReq: false
            })
            this.displayAlert()
        })
            .catch(err => {
                this.setState({ isSuccess: false })
                this.displayAlert()
            })
    }
    submitTempSensorPort = e => {
        e.preventDefault();
        let { thingName, tempSensorStatus, tempSensorUpdateInterval } = this.state.formInputs

        var option;
        if (this.state.formInputs.tempSensorStatus === false)
        {
            option = { thingName: thingName, tempSensorStatus: tempSensorStatus }
        }
        else
        {
            option = { thingName: thingName, tempSensorStatus: tempSensorStatus, tempSensorUpdateInterval: tempSensorUpdateInterval }
        };
        deviceApi.patchhttp(thingName, option).then(() => {
            this.setState({ isSuccess: true })
            this.displayAlert()
        })
            .catch(err => {
                this.setState({ isSuccess: false })
                console.log(err.message)
                this.displayAlert()
            })
    }
    submitRCOutputPort = e => {
        e.preventDefault();
        let { thingName, relayContactOutput } = this.state.formInputs
  
        const option = {
                thingName: thingName,
                relayContactOutput: relayContactOutput,
        };
        deviceApi.patchhttp(thingName, option).then(() => {
            this.setState({ isSuccess: true})
            this.displayAlert()
        })
            .catch(err => {
                this.setState({ isSuccess: false})
                this.displayAlert()
            })
    }
    submitADConvrtorPort = e => {
        e.preventDefault();
        let { thingName, adconvertorStatus, adconvertorBufferLength, adconvertorSampling, adconvertorUpdatingTime } = this.state.formInputs

        var option;
        if (this.state.formInputs.adconvertorStatus === false) {
            option = { thingName: thingName, adconvertorStatus: adconvertorStatus }
        }
        else {
            option = {
                thingName: thingName, adconvertorStatus: adconvertorStatus, adconvertorBufferLength: adconvertorBufferLength,
                adconvertorSampling: adconvertorSampling, adconvertorUpdatingTime: adconvertorUpdatingTime
            }
        };
         deviceApi.patchhttp(thingName, option).then(() => {
            this.setState({ isSuccess: true })
            this.displayAlert()
        })
            .catch(err => {
                this.setState({ isSuccess: false })
                this.displayAlert()
            })
    }
 
    submitPWModulatorPort = e => {
        e.preventDefault();
        let { thingName, pulseWidthModulatorStatus, pulseWidthModulatorFrequency, pulseWidthModulatorDutyCycle } = this.state.formInputs

        var option;
        if (this.state.formInputs.pulseWidthModulatorStatus === false)
           {
            option = {thingName: thingName, pulseWidthModulatorStatus: pulseWidthModulatorStatus }
           }
        else {
           option = { thingName: thingName, pulseWidthModulatorStatus: pulseWidthModulatorStatus, pulseWidthModulatorFrequency: pulseWidthModulatorFrequency,
                       pulseWidthModulatorDutyCycle: pulseWidthModulatorDutyCycle
                   }
        };
        deviceApi.patchhttp(thingName, option).then(() => {
            this.setState({ isSuccess: true })
            this.displayAlert()
        })
            .catch(err => {
                this.setState({ isSuccess: false })
                this.displayAlert()
            })
    }
    submitSerialPort = e => {
        e.preventDefault();
        let { thingName, serialPortStatus, serialPortBaudRate, serialPortDataBits, serialPortStopBits, serialPortParity, serialPortBufferSize } = this.state.formInputs
        var option;
        if (this.state.formInputs.serialPortStatus === false) {
            option = { thingName: thingName, serialPortStatus: serialPortStatus }
        }
        else {
            option = {
                thingName: thingName, serialPortStatus: serialPortStatus, serialPortBaudRate: serialPortBaudRate,
                serialPortStopBits: serialPortStopBits, serialPortDataBits: serialPortDataBits, serialPortParity: serialPortParity,
                serialPortBufferSize: serialPortBufferSize
            }
        };

        deviceApi.patchhttp(thingName, option).then(() => {
            this.setState({ isSuccess: true })
            this.displayAlert()
        })
            .catch(err => {
                this.setState({ isSuccess: false })
                this.displayAlert()
            })

    }
    displayAlert() {
        this.setState({ isVisible: true }, () => {
            window.setTimeout(() => {
                this.setState({ isVisible: false })
            }, 3000)
        });
    }
    render() {
        const { fetchedData, formInputs, isVisible, isSuccess } = this.state
        return (
            <div>
                <Container className="my-5">
                    <div className="mb-4">
                        <h1 className="h2">Port Setting Update</h1>
                    </div>
                    <div>
                        <Card>
                            <CardBody>
                                <Row form>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <Label for="thingName">Select your device</Label>
                                            <Input
                                                type="select"
                                                name="thingName"
                                                id="thingName"
                                                value={formInputs.thingName}
                                                onChange={this.handleThingQueryState}
                                                disabled={!fetchedData.length}
                                            >
                                                (!fetchedData.length) &&
                                                {
                                                    <option value="" hidden>Loading...</option>
                                                }
                                                {
                                                    fetchedData && fetchedData.map(item => (
                                                    <option key={item.thingName} value={item.thingName}>
                                                        {item.thingName} ({item.thingDescription})
                                                    </option>
                                                    ))
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <FormGroup>
                                            <Label for="deviceDescription">Please enter nickname</Label>
                                            <Input
                                                type="text"
                                                name="deviceDescription"
                                                id="deviceDescription"
                                                value={formInputs.deviceDescription}
                                                onChange={this.handleInputChange}
                                                placeholder="ex-Toronto Office 1"
                                                maxLength="30"
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12} md={4}>
                                        <FormGroup>
                                            <Label for="thingModel">Device Model Name</Label>
                                            <Input
                                                type="text"
                                                name="thingModel"
                                                id="thingModel"
                                                value={formInputs.thingModel || ''}
                                                readOnly
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <FormGroup>
                                            <Label for="thingFirmVer">Firmware Version</Label>
                                            <Input
                                                type="text"
                                                name="thingFirmVer"
                                                id="thingFirmVer"
                                                value={formInputs.thingFirmVer || ''}
                                                readOnly
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <FormGroup>
                                            <Label for="thingHardVer">Hadware Version</Label>
                                            <Input
                                                type="text"
                                                name="thingHardVer"
                                                id="thingHardVer"
                                                value={formInputs.thingHardVer || ''}
                                                readOnly
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button style={{ display: formInputs.thingName ? 'inherit' : 'none' }}
                                        onClick={this.submitDescription} color="primary"
                                        disabled={(this.state.deviceIdReq || !fetchedData.length)}
                                >
                                    {
                                        (this.state.deviceIdReq) ? "Loading..." : "UPDATE NICKNAME"
                                    }
                                </Button>
                            </CardBody>
                        </Card>
                        <div style={{ display: formInputs.thingName ? 'inherit' : 'none' }}>
                            <Row form>
                                <Col className="my-3" sm={12} md={6}>
                                    <Card>
                                        <Form onSubmit={this.submitTempSensorPort}>
                                                <CardHeader>
                                                    <div className="toggle-switch">
                                                        <input
                                                            type="checkbox"
                                                            name="tempSensorStatus"
                                                            className="toggle-switch-checkbox"
                                                            id="tempSensorStatus"
                                                            onChange={this.handleInputChange}
                                                            checked={formInputs.tempSensorStatus || false}
                                                        />
                                                        <label className="toggle-switch-label" htmlFor="tempSensorStatus">
                                                            <span
                                                                className="toggle-switch-inner"
                                                                data-yes="ON"
                                                                data-no="OFF"
                                                            />
                                                            <span
                                                                className="toggle-switch-switch"
                                                            />
                                                        </label>
                                                    </div>
                                                    <span>Temperature Sensor</span>
                                            </CardHeader>
                                                <CardBody className={formInputs.tempSensorStatus ? 'd-block' : 'd-none'} style={{ height: '130px' }}>
                                                <FormGroup>
                                                    <Label for="tempSensorUpdateInterval">Refresh Time (1~255) [min]</Label>
                                                    <Input
                                                        type="number"
                                                        name="tempSensorUpdateInterval"
                                                        id="tempSensorUpdateInterval"
                                                        value={formInputs.tempSensorUpdateInterval}
                                                        onChange={this.handleInputChange}
                                                        invalid={formInputs.tempSensorUpdateInterval < 1 || formInputs.tempSensorUpdateInterval > 255}
                                                        min="1"
                                                        max="255"
                                                    />
                                                    <FormFeedback>Range: 1 ~ 255</FormFeedback>
                                                </FormGroup>
                                            </CardBody>
                                            <CardFooter><Button block color="primary">UPDATE TEMPERATURE PORT</Button></CardFooter>
                                        </Form>
                                    </Card>
                                </Col>
                                <Col className="my-3" sm={12} md={6}>
                                    <Card>
                                            <CardHeader>
                                                <div className="toggle-switch">
                                                    <input
                                                        type="checkbox"
                                                        name="relayContactOutput"
                                                        className="toggle-switch-checkbox"
                                                        id="relayContactOutput"
                                                        onChange={this.handleInputChange}
                                                        checked={formInputs.relayContactOutput || false}
                                                    />
                                                    <label className="toggle-switch-label" htmlFor="relayContactOutput">
                                                        <span
                                                            className="toggle-switch-inner"
                                                            data-yes="ON"
                                                            data-no="OFF"
                                                        />
                                                        <span
                                                            className="toggle-switch-switch"
                                                        />
                                                    </label>
                                                </div>
                                                <span>Relay Contact Output</span>
                                        </CardHeader>
                                            <CardBody className={formInputs.relayContactOutput ? 'd-block' : 'd-none'} style={{ height: '130px' }}>
                                                <p className="mt-4 text-center">{this.state.relayContactDisplay}</p>
                                        </CardBody>
                                        <CardFooter><Button onClick={this.submitRCOutputPort} block color="primary">UPDATE RELAY CONTACT PORT</Button></CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                            <Row form>
                                <Col className="my-3" md={12} lg={6}>
                                    <Card>
                                        <Form onSubmit={this.submitADConvrtorPort}>
                                            <CardHeader>
                                                    <div className="toggle-switch">
                                                        <input
                                                            type="checkbox"
                                                            name="adconvertorStatus"
                                                            className="toggle-switch-checkbox"
                                                            id="adconvertorStatus"
                                                            onChange={this.handleInputChange}
                                                            checked={formInputs.adconvertorStatus || false}
                                                        />
                                                        <label className="toggle-switch-label" htmlFor="adconvertorStatus">
                                                            <span
                                                                className="toggle-switch-inner"
                                                                data-yes="ON"
                                                                data-no="OFF"
                                                            />
                                                            <span
                                                                className="toggle-switch-switch"
                                                            />
                                                        </label>
                                                    </div>
                                                    <span>AD Converter</span>
                                            </CardHeader>
                                                <CardBody className={formInputs.adconvertorStatus ? 'd-block' : 'd-none'}>
                                                <FormGroup>
                                                    <Label for="adconvertorBufferLength">Buffer Length (1~1000) [word]</Label>
                                                    <Input
                                                        type="number"
                                                        name="adconvertorBufferLength"
                                                        id="adconvertorBufferLength"
                                                        value={formInputs.adconvertorBufferLength}
                                                        onChange={this.handleInputChange}
                                                        invalid={formInputs.adconvertorBufferLength < 1 || formInputs.adconvertorBufferLength > 1000}
                                                        min="1"
                                                        max="1000"
                                                    />
                                                    <FormFeedback>Range: 1 ~ 1000</FormFeedback>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="adconvertorSampling">Seconds between Samples (1~255)</Label>
                                                    <Input
                                                        type="number"
                                                        name="adconvertorSampling"
                                                        id="adconvertorSampling"
                                                        value={this.state.formInputs.adconvertorSampling}
                                                        onChange={this.handleInputChange}
                                                        invalid={formInputs.adconvertorSampling < 1 || formInputs.adconvertorSampling > 255}
                                                        min="1"
                                                        max="255"
                                                    />
                                                    <FormFeedback>Range: 1 ~ 255</FormFeedback>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="adconvertorUpdatingTime">Voltage Value Updating Interval (0~60) [sec]</Label>
                                                    <Input
                                                        type="number"
                                                        name="adconvertorUpdatingTime"
                                                        id="adconvertorUpdatingTime"
                                                        value={this.state.formInputs.adconvertorUpdatingTime}
                                                        onChange={this.handleInputChange}
                                                        invalid={formInputs.adconvertorUpdatingTime < 0 || formInputs.adconvertorUpdatingTime > 60}
                                                        min="0"
                                                        max="60"
                                                    />
                                                    <FormFeedback>Range: 0 ~ 60</FormFeedback>
                                                </FormGroup>
                                            </CardBody>
                                            <CardFooter><Button block color="primary">UPDATE ADC PORT</Button></CardFooter>
                                        </Form>
                                    </Card>
                                </Col>

                                <Col className="my-3" md={12} lg={6}>
                                    <Card>
                                        <Form onSubmit={this.submitPWModulatorPort}>
                                            <CardHeader>
                                                <div className="toggle-switch">
                                                    <input
                                                        type="checkbox"
                                                        name="pulseWidthModulatorStatus"
                                                        className="toggle-switch-checkbox"
                                                        id="pulseWidthModulatorStatus"    
                                                        onChange={this.handleInputChange}
                                                        checked={formInputs.pulseWidthModulatorStatus || false}
                                                     />
                                                        <label className="toggle-switch-label" htmlFor="pulseWidthModulatorStatus">
                                                            <span
                                                                className="toggle-switch-inner"     
                                                                data-yes="ON"
                                                                data-no="OFF"
                                                            />
                                                            <span
                                                                className="toggle-switch-switch"
                                                            />
                                                        </label>
                                                    </div>
                                                    <span>Pulse Width Modulator</span>
                                                </CardHeader>
                                                <CardBody className={formInputs.pulseWidthModulatorStatus ? 'd-block' : 'd-none'}>
                                                <FormGroup>
                                                    <Label for="pulseWidthModulatorDutyCycle">Duty Cycle (1~99)</Label>
                                                    <Input
                                                        type="number"
                                                        name="pulseWidthModulatorDutyCycle"
                                                        id="pulseWidthModulatorDutyCycle"
                                                        value={formInputs.pulseWidthModulatorDutyCycle || ''}
                                                        onChange={this.handleInputChange}
                                                        invalid={formInputs.pulseWidthModulatorDutyCycle < 1 || formInputs.pulseWidthModulatorDutyCycle > 99}
                                                        min="1"
                                                        max="99"
                                                    />
                                                    <FormFeedback>Range: 1 ~ 99</FormFeedback>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="pulseWidthModulatorFrequency">Frequency (20~20000) [Hz]</Label>
                                                    <Input
                                                        type="number"
                                                        name="pulseWidthModulatorFrequency"
                                                        id="pulseWidthModulatorFrequency"
                                                        value={formInputs.pulseWidthModulatorFrequency}
                                                        onChange={this.handleInputChange}
                                                        invalid={formInputs.pulseWidthModulatorFrequency < 20 || formInputs.pulseWidthModulatorFrequency > 20000}
                                                        min="20"
                                                        max="20000"
                                                    />
                                                    <FormFeedback>Range: 20 ~ 20000</FormFeedback>
                                                </FormGroup>
                                            </CardBody>
                                            <CardFooter><Button block color="primary">UPDATE PWM PORT</Button></CardFooter>
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                            <Row form>
                                <Col className="my-3" md={12} lg={6}>
                                    <Card>
                                        <Form onSubmit={this.submitSerialPort}>
                                            <CardHeader>
                                                    <div className="toggle-switch">
                                                        <input
                                                            type="checkbox"
                                                            name="serialPortStatus"
                                                            className="toggle-switch-checkbox"
                                                            id="serialPortStatus"
                                                            onChange={this.handleInputChange}
                                                            checked={formInputs.serialPortStatus || false}
                                                        />
                                                        <label className="toggle-switch-label" htmlFor="serialPortStatus">
                                                            <span
                                                                className="toggle-switch-inner"
                                                                data-yes="ON"
                                                                data-no="OFF"
                                                            />
                                                            <span
                                                                className="toggle-switch-switch"
                                                            />
                                                        </label>
                                                    </div>
                                                    <span>Serial Port</span>
                                            </CardHeader>
                                            <CardBody style={{ display: formInputs.serialPortStatus ? 'block' : 'none' }}>
                                                <FormGroup>
                                                    <Label for="serialPortBaudRate">Custom Baudrate (9600~115200)</Label>
                                                    <Input
                                                        type="number"
                                                        name="serialPortBaudRate"
                                                        id="serialPortBaudRate"
                                                        value={formInputs.serialPortBaudRate}
                                                        onChange={this.handleInputChange}
                                                        invalid={formInputs.serialPortBaudRate < 9600 || formInputs.serialPortBaudRate > 115200}
                                                        min="9600"
                                                        max="115200"
                                                    />
                                                    <FormFeedback>Range: 9600 ~ 115200</FormFeedback>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="serialPortDataBits">Custom Data Bits</Label>
                                                    <Input
                                                        type="select"
                                                        name="serialPortDataBits"
                                                        id="serialPortDataBits"
                                                        value={formInputs.serialPortDataBits || ''}
                                                        onChange={this.handleInputChange}
                                                    >
                                                        <option value="" hidden>Select one of options</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="serialPortStopBits">Custom Stop Bits</Label>
                                                    <Input
                                                        type="select"
                                                        name="serialPortStopBits"
                                                        id="serialPortStopBits"
                                                        value={formInputs.serialPortStopBits}
                                                        onChange={this.handleInputChange}
                                                    >
                                                        <option value="" hidden>Select one of options</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="serialPortParity">Custom Parity</Label>
                                                    <Input
                                                        type="select"
                                                        name="serialPortParity"
                                                        id="serialPortParity"
                                                        value={formInputs.serialPortParity|| ''}
                                                        onChange={this.handleInputChange}
                                                    >
                                                        <option value="" hidden>Select one of options</option>
                                                        <option value="None">None</option>
                                                        <option value="Even">Even</option>
                                                        <option value="Odd">Odd</option>
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="serialPortBufferSize">Receive Buffer Size (1~2500) [byte]</Label>
                                                    <Input
                                                        type="number"
                                                        name="serialPortBufferSize"
                                                        id="serialPortBufferSize"
                                                        value={formInputs.serialPortBufferSize}
                                                        onChange={this.handleInputChange}
                                                        invalid={formInputs.serialPortBufferSize < 1 || formInputs.serialPortBufferSize > 2500}
                                                        min="1"
                                                        max="2500"
                                                    />
                                                    <FormFeedback>Range: 1 ~ 2500</FormFeedback>
                                                </FormGroup>
                                            </CardBody>
                                            <CardFooter><Button block color="primary">UPDATE SERIAL PORT</Button></CardFooter>
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </Container>
                <StyledAlert color={isSuccess ? "success" : "danger"} isOpen={isVisible} style={{ position: 'fixed', top: 0, width: '100%', textAlign: "center" }}>
                    {isSuccess? "Successfully Updated": "Sorry, please try again"}
                </StyledAlert>
            </div>
        );
    }
}
PortSetting.contextTypes = {
    auth: PropTypes.object
}
const StyledAlert = styled(Alert)`
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
`;
