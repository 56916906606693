import React, { Component } from 'react';
import { postHttp } from '../utils/userAPI'
import { ModalResendCode } from '../components/ModalResendCode'
import { Row, Col, Container, Card, CardTitle, CardBody, Form, Input, Label, FormGroup, CardSubtitle, Button, FormText, Alert } from "reactstrap";

export class EmailVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            hasEmail: false,
            form: {
                email: '',
                verificationCode: "",
            },
            failMsg: {
                isOpen: false,
                msg: ''
            },
            successMsg: {
                isOpen: false,
                msg: ''
            },
        };
    }
    componentDidMount() {
        this.props.location.state
            && this.props.location.state.email
            && this.setState({
                form: { email: this.props.location.state.email },
                hasEmail: true
            })
    }
    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }
    openModal = () => {
        this.setState({ modal: true })
    }
    closeModal = () => {
        this.setState({ modal: false })
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        const path = "verifyaccesscode"
        const data = { userEmail: this.state.form.email, accessCode: this.state.form.verificationCode }
 
        postHttp(data, path)
            .then((res) => {
                this.setState({
                    successMsg: {
                        isOpen: true,
                        msg: res
                    }, }, () => {
                    window.setTimeout(() => {
                        this.setState({
                            successMsg: {
                                isOpen: false,
                            },
                        })
                    }, 3000)
                    window.setTimeout(() => {
                        this.props.history.push('/login');
                    }, 4500)
                })
            })
            .catch(err => {
                console.log(err.message)
                this.setState({
                    failMsg: {
                        isOpen: true,
                        msg: err.message
                    },
                })
            })
    }

    closeFailMsg = () => {
        this.setState({
            failMsg: {
                isOpen: false,
                msg: ''
            }
        })
    }

    render() {
        const { form, hasEmail, successMsg, failMsg } = this.state
        return (
            <>
                <Container className="container my-5">
                    <Row className="justify-content-center">
                        <Col sm="12" md="8">
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-center" tag="h3">Email Verification</CardTitle>
                                    <Form
                                        onSubmit={this.handleSubmit}
                                    >
                                        {
                                            hasEmail && form.email !== '' ?
                                                <CardSubtitle className="my-2 text-muted">We sent verification code to you. Please check your {this.state.form.email} address.</CardSubtitle>
                                                :
                                                (
                                                    <FormGroup>
                                                        <Label htmlFor="email">Email</Label>
                                                        <Input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            placeholder="Ex. example@gmail.com"
                                                            value={form.email}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        <FormText color="muted">Please provide a same email address when register</FormText>
                                                    </FormGroup>
                                                )
                                        }
                                        <FormGroup>
                                            <Label htmlFor="verificationCode">Verification Code</Label>
                                            <Input
                                                id="verificationCode"
                                                name="verificationCode"
                                                type="text"
                                                placeholder="Enter verification code"
                                                value={form.verificationCode || ''}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </FormGroup>
                                        {
                                            failMsg.isOpen && (
                                                <Alert isOpen={failMsg.isOpen} color="danger" toggle={this.closeFailMsg}>
                                                    {failMsg.msg}
                                                </Alert>
                                            )
                                        }
                                        {/*{*/}
                                        {/*    message && !isSucess && (*/}
                                        {/*        <Alert className="mt-3" color="danger">*/}
                                        {/*            {this.state.message}*/}
                                        {/*        </Alert>*/}
                                        {/*    )}*/}
                                        <div className="text-center">
                                            <Button color="primary">
                                                VERIFY YOUR EMAIL
                                        </Button>
                                        </div>


                                    </Form>
                                </CardBody>
                            </Card>
                            <Row className="mt-4">
                                <Col xs="12" sm="6">
                                    <Button color="link" onClick={this.openModal}>Resend Code</Button>
                                    <ModalResendCode modal={this.state.modal} onClickModal={this.closeModal} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                {
                    successMsg.isOpen && (
                        <Alert className="success-msg" color="success" isOpen={successMsg.isOpen}>
                            {successMsg.msg}
                        </Alert>
                    )
                }
            </>
        );
    }
}
