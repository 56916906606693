//export const FormValidationService = {
//    testValidateField: function (formValidity, name, value, formErrors, password) {
//            const fieldValidationErrors = formErrors;
//            const validity = formValidity;
//            const isEmail = name === "email";
//            const isPasswordConfirmation = name === "passwordConfirmation"
//            const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

//            validity[name] = value.length > 0;
//            fieldValidationErrors[name] = validity[name]
//                ? ""
//                : "It is required and cannot be empty";
//            if (validity[name]) {
 
//                if (isEmail) {
//                    validity[name] = emailTest.test(value);
//                    fieldValidationErrors[name] = validity[name]
//                        ? ""
//                        : "It should be a valid email address";
//                }
//                if (isPasswordConfirmation) {
//                    validity[name] = value === password
//                    fieldValidationErrors[name] = validity[name] ? '' : 'It should match password, please enter again'
//                }
//            }
//            return { validity, fieldValidationErrors }
//    },

//};

export const validatePasswordStrong = (formValidity, value, formErrors) => {
    const fieldValidationErrors = formErrors;
    const validity = formValidity;
    const lowerRgx = new RegExp("^(?=.*[a-z])");
    const upperRgx = new RegExp("^(?=.*[A-Z])");
    const numericRgx = new RegExp("^(?=.*[0-9])");
    const minimumRgx = new RegExp("^(?=.{8,})");

    if (minimumRgx.test(value)) {
        validity.minimum = true
        fieldValidationErrors.minimum = ''
    } else {
        validity.minimum = false
        fieldValidationErrors.minimum = 'Must be 8 Characters'
    }
    if (upperRgx.test(value)) {
        validity.uppercase = true
        fieldValidationErrors.uppercase = ''
    } else {
        validity.uppercase = false
        fieldValidationErrors.uppercase = 'Must have uppercase'
    }
    if (lowerRgx.test(value)) {
        validity.lowercase = true
        fieldValidationErrors.lowercase = ''
    } else {
        validity.lowercase = false
        fieldValidationErrors.lowercase = 'Must have lowercase'
    }
    if (numericRgx.test(value)) {
        validity.numeric = true
        fieldValidationErrors.numeric = ''
    } else {
        validity.numeric = false
        fieldValidationErrors.numeric = 'Must have numeric characters'
    }
    return { validity, fieldValidationErrors }
}