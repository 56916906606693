import React, { Component } from 'react';
import { Container } from "reactstrap";
import ReactHelmet from '../../components/Helmet'
import { PageHeader, PageImg } from "../styles/Pages";

import iotAppJpgImg from "../../assets/images/iot-application-01.jpg";

export class AgriculturalSystem extends Component {
  
    render() {
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, application"
                    description="Irrigation Control System is one of the possible applications of IoT and simply you can use the technology."
                    title="Agricultural Irrigation System | Gateway Alfa | iotLINKS"
                />
                <PageHeader>
                    <h1>Agricultural Irrigation System</h1>
                </PageHeader>
                <Container>
                    <PageImg src={iotAppJpgImg} />
                    <p>
                        Irrigation Control System is one of the possible applications of IoT and simply you can use the technology to
                        measure the humidity in soil at multiple points with wireless technology and according to the humidity level
                        you can control irrigation system to flow water into the soil, considering the situation of fresh water and
                        less precipitation is some areas of the world, smart irrigation system is a must for successful agriculture.
                    </p>
                    <br/><br/>
                </Container>
            </>
        );
    }
}