import React, { Component } from 'react';
import { Container } from "reactstrap";
import ReactHelmet from '../../components/Helmet'
import { PageHeader, PageImg } from "../styles/Pages";

import iotAppJpgImg from "../../assets/images/iot-application-05.jpg";

export class RemoteAccessControl extends Component {
  
    render() {
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, application"
                    description="Imagine you can remotely open your door or your Garage door when you family arrived at home?"
                    title="Remote Access Control | Gateway Alfa | iotLINKS"
                />
                <PageHeader>
                    <h1>Remote Access Control</h1>
                </PageHeader>
                <Container>
                    <PageImg src={iotAppJpgImg} />
                    <p>
                        Imagine you can remotely open your door or your Garage door when you family arrived at home? Or get notified
                        if someone shows up at your door?
                    </p>
                    <br /><br />
                </Container>
            </>
        );
    }
}