import React, { Component } from 'react';
import { Container } from "reactstrap";
import ReactHelmet from '../../components/Helmet'
import { PageHeader, PageImg } from "../styles/Pages";

import iotAppJpgImg from "../../assets/images/iot-application-03.jpg";

export class SolarPanelsPower extends Component {
  
    render() {
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, application"
                    description="Solar farms are usually remote, so cleaning the panels and recording the performance of the system needs."
                    title="Solar Panels Power Monitoring and Cleaning | Gateway Alfa | iotLINKS"
                />
                <PageHeader>
                    <h1>Solar Panels Power Monitoring and Cleaning</h1>
                </PageHeader>
                <Container>
                    <PageImg src={iotAppJpgImg} />
                    <p>
                        Solar farms are usually remote, so cleaning the panels and recording the performance of the system needs
                        frequent travel to the remote site, while using an IoT remote access, you can schedule cleaning such as water
                        pressure cleaning as well transferring some critical information of the solar farm to the cloud.
                    </p>
                    <br /><br />
                </Container>
            </>
        );
    }
}