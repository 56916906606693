import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router';
import { Button } from 'reactstrap';

const SignOutButton = ({ logout, collapseMenu }, context) => (
    context.auth.loggedIn ? (
        <Button color="link" onClick={() => {
            logout().then(() => <Redirect to="/" />);
            collapseMenu();
        }}>Sign out</Button>
    ) : null
)

SignOutButton.contextTypes = {
    router: PropTypes.object,
    auth: PropTypes.object
}

SignOutButton.propTypes = {
    logout: PropTypes.func.isRequired
}

export default SignOutButton