import React, { Component } from 'react';
import { Container } from "reactstrap";
import ReactHelmet from '../../components/Helmet'
import { PageHeader, PageImg } from "../styles/Pages";

import iotAppJpgImg from "../../assets/images/iot-application-02.jpg";

export class WaterPump extends Component {
  
    render() {
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, application"
                    description="Using our Gateways, you can control a remote pump to turn ON/OFF or you can schedule it to operation as needed."
                    title="Water Pump Control | Gateway Alfa | iotLINKS"
                />
                <PageHeader>
                    <h1>Water Pump Control</h1>
                </PageHeader>
                <Container>
                    <PageImg src={iotAppJpgImg} />
                    <p>
                        Using our Gateways, you can control a remote pump to turn ON/OFF or you can schedule it to operation as needed.
                        You may also detect issues around your motor pump such as overheating, excessive vibration, water pressure or
                        any other parameter is in your interest. The remote factories or farms can benefit from such an IoT application.
                    </p>
                    <br /><br />
                </Container>
            </>
        );
    }
}