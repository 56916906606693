const USER_API_URL = 'useriotlinks'
const USER_DEVICES_URL = 'useriotlinks/getdevicelist'

export const register = async (data) => {
    let response = await fetch('useriotlinks', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data)
    })
    const res = await response.json();
    if (res.isSuccessful) {
        console.log(res)
        return res.isSuccessful
    }
    else {
        let errMsg = res.responseMessage;
        console.log(res)
        console.log(errMsg);
        const error = new Error(`Error! ${errMsg}`)
        throw error
    }
}
export const postHttp = async (data, path) => {
    let response = await fetch(`${USER_API_URL}/${path}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    })
    const res = await response.json();
    if (res.isSuccessful) {
        console.log(res)
        return res.responseMessage
    }
    else {
        let errMsg = res.responseMessage;
        console.log(res)
        console.log(errMsg);
        const error = new Error(`Error! ${errMsg}`)
        throw error
    }
}
export const putHttp = async (data, user) => {
    let response = await fetch(`${USER_API_URL}/${user}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage["accessToken"],
            'Authentication': sessionStorage["idToken"]
        },
        body: JSON.stringify(data)
    })
    const res = await response.json();
    if (res.isSuccessful) {
        return res.responseMessage
    }
    else {
        let errMsg = res.responseMessage;
        const error = new Error(`Error! ${errMsg}`)
        throw error
    }
}
export const getUserByEmail = async (user) => {
    let response = await fetch(`${USER_API_URL}/${user}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage["accessToken"],
            'Authentication': sessionStorage["idToken"]
        }
    })
    if (response.status >= 200 && response.status < 300) {
        const res = await response.json();
        return res
    } else {
        const error = new Error(`Unexpected Error. (Error status: ${response.status}). Please try again. If you keep seeing this error, please contact to the admin.`)
        throw error
    }
}

export const getUserDevicesByEmail = async (user) => {
    let response = await fetch(`${USER_DEVICES_URL}/${user}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage["accessToken"],
            'Authentication': sessionStorage["idToken"]
        }
    })
    if (response.status >= 200 && response.status < 300) {
        const res = await response.json();
        return res
    } else {
        const error = new Error(`Unexpected Error. (Error status: ${response.status}). Please try again. If you keep seeing this error, please contact to the admin.`)
        throw error
    }
}




export const changePassword = async (data, path) => {
    let response = await fetch(`${USER_API_URL}/${path}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': sessionStorage["accessToken"],
            'Authentication': sessionStorage["idToken"]
        },
        credentials: 'include',
        body: JSON.stringify(data)
    })
    const res = await response.json();
    if (res.isSuccessful) {
        return res.responseMessage
    }
    else {
        let errMsg = res.responseMessage;
        const error = new Error(`Error! ${errMsg}`)
        throw error
    }
}

//////////////////////////////////
//const checkStatus = (response) => {
//    console.log(response)
//    if (response.status >= 200 && response.status < 300) {
//        console.log(response)
//        return response
//    } else {
//        console.log(response.status)
//        console.log(response.text())
//        const error = new Error(`Error ${response.statusText}`)
//        error.status = response.statusText
//        error.response = response
//        console.log(response)
//        throw error
//    }
//}


//export const getUserByEmail = (email) => {
//    const url = 'User/getinfo?username=';
//    fetch(url + email,
//        {
//            method: 'GET',
//            headers: {
//                'Content-Type': 'application/json'
//            }
//        })
//        .then(function (response) {
//            return response.json()
//        }).then(res => checkStatus(res))
//        .then((res) => console.log(res))

//}

//export const postHttp = (path, data, token = getAuthState().userToken) => {
//    return fetch(path, {
//        method: 'POST',
//        headers: {
//            'Accept': 'application/json',
//            'Content-Type': 'application/json',
//            'Authorization': `Bearer ${token}`
//        },
//        body: JSON.stringify(data)
//    }).then(response => checkStatus(response))
//        .then(response => response.json())
//}

//export const getHttp = (path, token = getAuthState().userToken) => {
//    return fetch(path, {
//        headers: {
//            'Accept': 'application/json',
//            'Content-Type': 'application/json',
//            'Authorization': `Bearer ${token}`
//        }
//    }).then(response => checkStatus(response))
//        .then(response => response.json())
//}

//export const deleteHttp = (path, token = getAuthState().userToken) => {
//    return fetch(path, {
//        method: 'DELETE',
//        headers: {
//            'Accept': 'application/json',
//            'Content-Type': 'application/json',
//            'Authorization': `Bearer ${token}`
//        }
//    }).then(response => checkStatus(response))
//        .then(response => response.json())
//}

//export const patchHttp = (path, data, token = getAuthState().userToken) => {
//    return fetch(path, {
//        method: 'PATCH',
//        headers: {
//            'Accept': 'application/json',
//            'Content-Type': 'application/json',
//            'Authorization': `Bearer ${token}`
//        },
//        body: JSON.stringify(data)
//    }).then(response => checkStatus(response))
//        .then(response => response.json())
//}