import React, { Component } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { Row, Col, Container, Card, CardBody, FormGroup, Form, Label, Input, Button } from "reactstrap";
import { getUserDevicesByEmail } from '../utils/userAPI';
import { gethttp } from '../utils/deviceAPI'
import PropTypes from 'prop-types';
import { browserStorage, browserStorageDeviceIdKey } from '../utils/browserStorage';

export class RealTimeTemp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deviceIdReq: false,
            fetchedAllDevices: [],
            searchInputs: {
                thingName: browserStorage.get(browserStorageDeviceIdKey) || "",
            },
            loading: true,
            tempSensorValue: 0,
            tempSensorTime: '',
            unit: '',
            message: "Chart will be displayed"
        };
        this.populateData = this.populateData.bind(this);
    }
    componentDidMount() {
        this.getUserDevicesInfo()
    }
    getUserDevicesInfo = async () => {
        let user = this.context.auth.username
        if (user) {
            getUserDevicesByEmail(user).then((data) => {
                this.setState({
                    fetchedAllDevices: data.deviceList,
                });
                if (data.deviceList.length && !browserStorage.get(browserStorageDeviceIdKey)) {
                    this.setState({
                        searchInputs: {
                            thingName: data.deviceList[0].thingName
                        }
                    });
                } else if (browserStorage.get(browserStorageDeviceIdKey)) {
                    this.submitGet();
                }
            }).catch(err => {
                console.log(err.message)
            })
        }
    }
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            searchInputs: {
                ...this.state.searchInputs,
                [name]: value
            }
        })
    }
    populateData() {
        this.setState({
            message: '',
            deviceIdReq: true
        })
        gethttp(this.state.searchInputs.thingName).then((data) => {
            if (data.tempSensorStatus) {
                let dateObj = new Date(data.tempSensorTimeStamp)
                let hour = dateObj.getUTCHours();
                let minute = String(dateObj.getUTCMinutes()).padStart(2, "0");
                let year = dateObj.getUTCFullYear();
                let month = dateObj.getUTCMonth() + 1;
                let date = dateObj.getUTCDate();
                let second = dateObj.getUTCSeconds();
                let sensorTime =
                    year + "-" +
                    String(month).padStart(2, '0') + "-" +
                    String(date).padStart(2, '0') + ", " +
                    String(hour).padStart(2, '0') + ":" +
                    String(minute).padStart(2, '0') + ":" +
                    String(second).padStart(2, '0');
                this.setState({
                    tempSensorValue: data.tempSensorValue,
                    tempSensorTime: sensorTime,
                    loading: false
                })
            } else {
                clearInterval(this.timer)
                this.setState({
                    loading: true,
                    message: "Temperature sensor is inactivated. Please activate your temperature sensor."
                })
            }
            this.setState({
                deviceIdReq: false
            })
            // Save selected value into Browser Storage for later use
            browserStorage.set(browserStorageDeviceIdKey, this.state.searchInputs.thingName);
        }).catch(err => {
            clearInterval(this.timer)
            console.log(err)
        })
    }

    submitGet = e => {
        if (e) e.preventDefault();
        this.getTemperatureUnit(this.state.searchInputs.thingName);
        if (this.timer) {
            clearInterval(this.timer)
        }
        this.populateData();
        this.timer = setInterval(() => this.populateData(), 30000);
    }

    getTemperatureUnit(thingName) {
        this.state.fetchedAllDevices.forEach(el => {
            if (el.thingName === thingName) {
                this.setState({
                    unit: el.thingTempUnit
                })
            }
        })
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }
    static renderGuageChart(temperature, time, unit) {
        return (
            <>
                <span>Last Update: {time} </span>
                <div className="mt-5 text-center">
                    {
                        unit === "Fahrenheit" ?
                            <ReactSpeedometer
                                height={200}
                                forceRender={true}
                                minValue={-4}
                                maxValue={122}
                                value={temperature}
                                needleHeightRatio={0.7}
                                needleColor="dimgray"
                                segments={7}
                                segmentColors={["#256eff", "#256eff", "#3DDC97", "#3DDC97", "#3DDC97", "#FF495C", "#FF495C"]}
                                currentValueText={`Value[F]: ${temperature}`}
                            /> :
                            <ReactSpeedometer
                                height={200}
                                forceRender={true}
                                minValue={-20} 
                                maxValue={50}
                                value={temperature}
                                needleHeightRatio={0.7}
                                needleColor="dimgray"
                                segments={7}
                                segmentColors={["#256eff", "#256eff", "#3DDC97", "#3DDC97", "#3DDC97", "#FF495C", "#FF495C"]}
                                currentValueText={`Value[C]: ${temperature}`}
                            />
                    }
                </div>
            </>
        );
    }
    render() {
        let contents = this.state.loading ?
            <p className="text-muted">{this.state.message}</p> :
            RealTimeTemp.renderGuageChart(this.state.tempSensorValue, this.state.tempSensorTime, this.state.unit)
        return (
            <Container className="my-5">
                <h1 className="h2">Internal Temperature Gauge</h1>
                <Form className="mb-5 mt-5" onSubmit={this.submitGet}>
                    <Row form>
                        <Col sm={12} md={9}>
                            <Label for="thingName">Device ID</Label>
                            <Input
                                type="select"
                                id="thingName"
                                name="thingName"
                                value={this.state.searchInputs.thingName}
                                onChange={this.handleInputChange}
                                required
                                disabled={!this.state.fetchedAllDevices.length}
                            >
                                (!this.state.fetchedAllDevices.length) &&
                                {
                                    <option value="" hidden>Loading...</option>
                                }
                                {
                                    this.state.fetchedAllDevices && this.state.fetchedAllDevices.map(item => (
                                        <option key={item.thingName} value={item.thingName}>
                                            {item.thingName} - {(item.thingDescription)}
                                        </option>
                                    ))
                                }
                            </Input>
                        </Col>
                        <Col sm={12} md={3}>
                            <FormGroup className="d-flex flex-column">
                                <Label for="chart-button" className="invisible">Button</Label>
                                <Button color="primary" id="chart-button"
                                        disabled={(this.state.deviceIdReq || !this.state.fetchedAllDevices.length)}
                                >
                                    {
                                        (this.state.deviceIdReq) ? "Loading..." : "GET TEMPERATURE"
                                    }
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <Card>
                    <CardBody>
                        {contents}
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
RealTimeTemp.contextTypes = {
    auth: PropTypes.object
}
