import React, { Component } from 'react';
import { Container, FormText, Button } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import tryLabJpg from '../assets/images/try-live-lab.jpg';

export class LiveLabTry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValidReCAPTCHA: false,
            isDisplayed: false,
        }
        this._reCaptchaRef = React.createRef();  
    }
    onChange = (value) => {
        this._reCaptchaRef = value
        this.setState({
            isValidReCAPTCHA: (typeof this._reCaptchaRef === 'string')
        })
    }
    requestLiveLab = async (event) => {
        event.preventDefault();
        this.setState({ isDisplayed: false })

        const data = {
            encodedResponse: this._reCaptchaRef
        }
         // (1) Verify recaptcha
        if (this.state.isValidReCAPTCHA) {
             // (2) Sends HTTP POST request New Lab request (URL = siteurl/livelab)
            fetch('livelab', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include',
                body: data
            })
                .then(res => res.json())
                .then(data => {
             // (3) Stores LLID and LineNumber values in the Session memory
                    sessionStorage.setItem('llid', data.llid);
                    sessionStorage.setItem('lineNumber', data.lineNumber);
             // (4) Calls Waiting Live Lab page
                    this.props.history.push({
                        pathname: '/live-lab-waiting',
                        state: {
                            fromTryPage: true
                        }
                    });
                })
                .catch(err => console.log(err))
        } else {
            this.setState({
                isDisplayed: true
            })
        }
    }
    render() {
        return (
            <>
                <Container className='my-5'>
                    <Head>You can take control over our Live Lab from anywhere in the world.</Head>
                    <Content>
                        <div>
                            <h2>Try Live Lab</h2>
                            <div className='my-4' style={{ margin: "30px 0" }}>
                                <ReCAPTCHA
                                    onChange={this.onChange}
                                    ref={this._reCaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                />
                                {
                                    this.state.isDisplayed && !this.state.isValidReCAPTCHA && 
                                    <FormText color='danger'>
                                        Required
                                    </FormText>
                                }
                            </div>
                            <Button onClick={this.requestLiveLab}>Try Live Lab</Button>
                        </div>
                        <InfoGraphic src={tryLabJpg} />
                    </Content>
                </Container>
            </>
        );
    }
}

const Head = styled.h1`
    text-align: center;
    font-size: 1.9rem;
    color: #314673;
    margin: 50px 0 70px;
`;

const Content = styled.div`
    display: flex;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const InfoGraphic = styled.img`
    width: 500px;
    margin-left: auto;
    @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 30px;
    }
`;