import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "reactstrap";
import ReactHelmet from '../components/Helmet'
import styled from "styled-components";

import home1PngImg from "../assets/images/home01.png";
import home2JpgImg from "../assets/images/home02.jpg";

export class Home extends Component {
    render() {
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure"
                    description="Gateway Alfa is a low cost Gateway to connect your devices to the Cloud."
                    title="iotLINKS - the easy way to build the future of IoT"
                />
                <div className="main-wrapper py-5">
                    <Container>
                        <Row className="align-items-center">
                            <Col md={12} lg={6} className="py-3">
                                <H1>
                                    <span className="h1 d-block">Gateway Alfa</span>
                                    <span className="h3 d-block">The easy way to build the future of IoT</span>
                                </H1>
                                <Link to="/internet-of-things" className="my-3 btn btn-primary btn-round">
                                    More Details of iotLINKS
                                </Link>
                            </Col>
                            <Col className="text-right" md={12} lg={6}>
                                <img
                                    className="main-img"
                                    src={home1PngImg}
                                    alt="IoTLinks"
                                />

                            </Col>
                        </Row>
                    </Container>
                </div>
                <section>
                    <Container className="my-5">
                        <H2>Internet of Things or IoT</H2>
                        <p>
                            Internet of Things or IoT seems a complicated system but using iotLINKS platform you can set up your own IoT application without knowing much about coding or hardware implementation.
                        </p>
                        <p>
                            Our plug and play platform provides a very flexible environment, so the user can adapt the system to their application easily and without much costs.
                            Sign up today to start your first application by only $20 investment and put your creativity into work!
                        </p>
                        <div className="text-center">
                        <img
                            className="pt-3"
                                src={home2JpgImg}
                            alt="Gateway Alfa, IoT Sensor and Devices"
                            style={{ width: '100%' }}
                        />
                        </div>
                    </Container>
                </section>
            </>
        );
    }
}
const H1 = styled.h1`
    color: #3774b0 !important;
`;
const H2 = styled.h2`
    color: #3774b0 !important;
`;