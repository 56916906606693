import React, { Component } from 'react';
import { Container, Col, Button, Label, Input, Form } from 'reactstrap';
import styled from "styled-components";

export class LiveLab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 120,
            sec: '00',
            min: '02',
            adconvertorVoltageValue: '...',
            formInputs: {
                pulseWidthModulatorDutyCycle: '',
                servomotorPosition: '',
                relayContactOutput: false,
            },
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            formInputs: {
                ...this.state.formInputs,
                [name]: value
            }
        }, function () {
            if (target.type === 'checkbox') {
                this.handleSubmit(event, true);
            }
        })
    }
    componentDidMount() {

        if (!this.props.location.state || !this.props.location.state.fromWaitingPage) {

            this.props.history.push({ pathname: '/try-live-lab' });

        } else {

            window.history.replaceState({}, document.title);

            let labDevice = sessionStorage.getItem('labDeviceName');
            let llid = sessionStorage.getItem('llid');
            if (labDevice) { // Error riased: title: 'Unauthorized', status: 401 
                fetch(`livelab/controllab/${llid}?currentLLID=${llid}&labThingName=${labDevice}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    /* body: JSON.stringify(labDevice)*/
                })
                    .then(res => res.json())
                    .then(data => {
                        console.log(data);
                        this.setState({
                            adconvertorVoltageValue: (data && data.adconvertorVoltageValue)
                                ? parseFloat(data.adconvertorVoltageValue + 0.2).toFixed(3)
                                : "...",
                            formInputs: {
                                pulseWidthModulatorDutyCycle: data.pulseWidthModulatorDutyCycle,
                                relayContactOutput: data.relayContactOutput,
                                servomotorPosition: (data.pulseWidthModulatorDutyCycle - 15) * 3,
                            }
                        })
                    })
                    .catch(err => console.log(err))
            }

            // 120 seconds interval timer
            this.timer = setInterval(() => this.runTimer(), 1000);
        }

        window.addEventListener("beforeunload", this.onWindowUnload);
    }
    runTimer() {
        if (this.state.count >= 0) {
            let minute = Math.floor(this.state.count / 60);
            let seconds =
                this.state.count % 60 < 10
                    ? '0' + (this.state.count % 60)
                    : this.state.count % 60;
            this.setState((prevState) => ({
                min: String(minute).padStart(2, '0'),
                sec: String(seconds).padStart(2, '0'),
                count: prevState.count - 1,
            }));
            let labDevice = sessionStorage.getItem('labDeviceName');
            let llid = sessionStorage.getItem('llid');
            if (labDevice) { // Error riased: title: 'Unauthorized', status: 401 
                fetch(`livelab/controllab/${llid}?currentLLID=${llid}&labThingName=${labDevice}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    /* body: JSON.stringify(labDevice)*/
                })
                    .then(res => res.json())
                    .then(data => {
                        console.log(data);
                        this.setState({
                            adconvertorVoltageValue: (data && data.adconvertorVoltageValue)
                                ? parseFloat(data.adconvertorVoltageValue + 0.2).toFixed(3)
                                : "...",
                        })
                    })
                    .catch(err => console.log(err))
            }


        } else {
            this.finishLab();
        }
    }
    finishLab = (feedback = true) => {
        clearInterval(this.timer);
        //// Sends HTTP PUT request Finish Request (URL=siteurl/livelab/finishlab/LLID) 
        let llid = sessionStorage.getItem('llid');
        let labDevice = sessionStorage.getItem('labDeviceName');
        fetch(`livelab/finishlab/${llid}?currentLLID=${llid}&labThingName=${labDevice}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(res => res.json())
            .then(data => {
                console.log(data); // The backend responds with negative number
            })
            .catch(err => console.log(err))
        // When the page receives this number, demonstrates 'Thank You for Live Lab Testing' to the user
        if (feedback) {
            this.props.history.push({ pathname: '/live-lab-feedback' });
        }
    }
    onWindowUnload = e => {
        console.log("Window Unload");
        e.preventDefault();
        this.finishLab(false);
    };
    componentWillUnmount() {
        clearInterval(this.timer);
        window.removeEventListener("beforeunload", this.onWindowUnload);
    }
    handleSubmit = async (event, isCheckbox = false) => {
        event.preventDefault()
        // Sends HTTP PATCH request (URL=stieurl/iotthing/labdevicename) to IotThing backend controller
        let pulsemodulation = 15 + parseFloat(this.state.formInputs.servomotorPosition) / 3;
        let options = {
            pulseWidthModulatorDutyCycle: parseInt(pulsemodulation),
            relayContactOutput: this.state.formInputs.relayContactOutput,
            thingName: sessionStorage.getItem('labDeviceName')
        }
        if (isCheckbox) {
            delete options.pulseWidthModulatorDutyCycle;
        }
        let labDevice = sessionStorage.getItem('labDeviceName');
        let llid = sessionStorage.getItem('llid');
        if (labDevice) { // Error riased: title: 'Unauthorized', status: 401 
            fetch(`livelab/controllab/${llid}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(options)
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })
                .catch(err => console.log(err))
        }

    }
    render() {
        const { min, sec, formInputs, adconvertorVoltageValue } = this.state;
        return (
            <>
                <Container className='my-5'>
                    <H1>You have 2 minutes to try our Live Lab</H1>
                    <OL>
                        <li>You can turn the light ON and OFF</li>
                        <li>See the voltage generated by solar cell before and after</li>
                        <li>Adjust the angle of the servomotor (0 ~ 180 degree)</li>
                    </OL>
                    <Content>
                        <Col className='my-3' sm={12} md={8}>
                            <Video>
                                <iframe
                                    className='m-0 p-0 d-block border-0'
                                    src='https://open.ivideon.com/embed/v2/?server=100-sXrsflyxFuj25d7UOIbkYp&camera=0&width=&height=&lang=en&autoplay=1&ap'
                                    width={'100%'}
                                    height={450}
                                    frameBorder={0}
                                    allowFullScreen
                                    title='Live lab realtime video'
                                />
                            </Video>
                        </Col>
                        <Col className='my-3' sm={12} md={4}>
                            <FormContainer>
                                <Form onSubmit={this.handleSubmit}>
                                    <FormGroupEl className='d-flex flex-column' timer="true">
                                        <small>Remaining Access Time</small>
                                        <strong>
                                            {min}:{sec}
                                        </strong>
                                    </FormGroupEl>
                                    <FormGroupEl>
                                        <Label className='font-weight-bold' for='relayContactOutput'>LED Light</Label>
                                        <br />
                                        <div className='toggle-switch'>
                                            <input
                                                type='checkbox'
                                                id='relayContactOutput'
                                                name='relayContactOutput'
                                                className='toggle-switch-checkbox'
                                                onChange={this.handleInputChange}
                                                checked={formInputs.relayContactOutput || false}
                                            />
                                            <label className='toggle-switch-label' htmlFor='relayContactOutput'>
                                                <OnOff
                                                    className='toggle-switch-inner'
                                                    data-yes='ON'
                                                    data-no='OFF'
                                                />
                                                <span className='toggle-switch-switch' />
                                            </label>
                                        </div>
                                        <SwitchText on={(formInputs.relayContactOutput) ? "true" : ""}>
                                            {formInputs.relayContactOutput ? 'Energized' : 'Deenergized'}
                                        </SwitchText>
                                        <Label for='servomotorPosition' className='font-weight-bold'>Servomotor Position (0~180)</Label>
                                        <InputContainer>
                                            <InputPulse
                                                type='number'
                                                id='servomotorPosition'
                                                name='servomotorPosition'
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}
                                                value={
                                                    (parseInt(formInputs.servomotorPosition))
                                                        ? parseInt(formInputs.servomotorPosition)
                                                        : ''
                                                }
                                                min='0'
                                                max='180'
                                                required
                                            />
                                            <UpdateButton>Set</UpdateButton>
                                        </InputContainer>
                                    </FormGroupEl>
                                    <FormGroupEl className='d-flex flex-column' timer="true">
                                        <small>Voltage [V]</small>
                                        <strong>{adconvertorVoltageValue}</strong>
                                    </FormGroupEl>
                                </Form>
                            </FormContainer>
                        </Col>
                    </Content>
                </Container>
            </>
        );
    }
}

const H1 = styled.h1`
    font-size: 2.4rem;
    font-weight: 700;
    color: #314673;
    margin-bottom: 0;
 small {
        font-size: 50%;
        margin-left: 10px;
    }
`;

const Content = styled.div`
    margin-top: 10px;
    display: flex;
`;

const Video = styled.div`
    overflow: hidden;
    border-radius: 10px;
`;

const FormContainer = styled.div`
    background: #eef7ff;
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
`;

const FormGroupEl = styled.div`
    padding: 20px;
    ${props => (props.timer)
        ? "background:#72b4fe;color:white;text-align:center;"
        : ""
    }
    small {
        font-size: 1rem;
        font-weight: bold;
    }
    strong {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: bold;
    }
`;

const SwitchText = styled.div`
    font-size: smaller;
    margin-bottom: 15px;
    ${props => (props.on)
        ? "color:#2884d9;"
        : ""
    }
`;

const InputContainer = styled.div`
    display: flex;
`;

const UpdateButton = styled(Button)`
    background: #314673;
    color: white;
    width: 150px;
    border: none;
    border-radius: 10px;
    margin-left: 10px;
    :hover {
        background: #315b89;
    }
`;

const OnOff = styled.span`
    :before {
        background: #2884d9;
    }
`;

const InputPulse = styled(Input)`
    border-radius: 10px;
    border: none;
`;

const OL = styled.ol`
    margin-top: 10px;
    padding-left: 30px;
    li {
        font-size: smaller;
    }
`;