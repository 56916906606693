import React, { Component } from 'react';
import { Row, Col, Container, Card, CardTitle, CardBody, Form, Input, Label, FormGroup, Button, Alert, FormText } from "reactstrap";
import { register } from '../utils/userAPI'
import { validatePasswordStrong } from '../helpers/validate'
import ReCAPTCHA from "react-google-recaptcha";

export class Signup extends Component {
    constructor() {
        super()
        this.state = {
            recaptch: '',
            isValidReCAPTCHA: false,
            form: {   
                email: '',
                password: '',
                fname: '',
                lname: '',
                street: '',
                address2: '',
                city: '',
                state: '',
                postcode: '',
                country: '',
                phone: '',
            },
            formErrors: {
                minimum: 'Must be 8 Characters',
                uppercase: 'Must have uppercase',
                lowercase: 'Must have lowercase',
                numeric: 'Must have numeric characters'
            },
            formValidity: {
                minimum: false,
                uppercase: false,
                lowercase: false,
                numeric: false,
            },
            failMsg: {
                isOpen: false,
                msg: ''
            },
        }
        this._reCaptchaRef = React.createRef();
    }

    componentDidMount() {
        //console.log("didMount - reCaptcha Ref-", this._reCaptchaRef);
    }
    onChange = (value) => {
        this._reCaptchaRef = value
        this.setState({
            isValidReCAPTCHA: (typeof this._reCaptchaRef === 'string')
        })
    }
    handleChange = (event) => {
        let { name, value } = event.target
        if (name === "email") {
            value = value.toLowerCase();
        }
        if (name === "password") {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value
                }
            }, function () { this.validatePassword(value) })
        } else if (name === 'phone') {
            const regex = /^[+\d]?(?:[\d\s]*)$/;
            if (regex.test(event.target.value)) {
                let val = event.target.value;
                this.setState({
                    form: {
                        ...this.state.form,
                        phone: val
                    }
                })
            }
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value
                }
            })
        }

    }
    validatePassword(value) {
        const { formValidity, formErrors } = this.state
        const { validity, fieldValidationErrors } = validatePasswordStrong(formValidity, value, formErrors)
        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity,
        })
    }

    handleRegisterSubmit = async (event) => {
        event.preventDefault()
        const { minimum, uppercase, lowercase, numeric } = this.state.formValidity

        const data = {
            encodedResponse: this._reCaptchaRef,
            password: this.state.form.password, userEmail: this.state.form.email, firstName: this.state.form.fname, lastName: this.state.form.lname,
            streetAddress: this.state.form.street, streetAddressTwo: this.state.form.address2, city: this.state.form.city,
            stateProvince: this.state.form.state, country: this.state.form.country, zipPostalCode: this.state.form.postcode, phoneNumber: this.state.form.phone
        }
        if (minimum && uppercase && lowercase && numeric) {
            if (this.state.isValidReCAPTCHA) {
                register(data)
                    .then(() => this.props.history.push({ pathname: '/email-verification', state: { email: this.state.form.email } }))
                    .catch(err => {
                        this.setState({
                            failMsg: {
                                isOpen: true,
                                msg: err.message
                            },
                        })
                        }
                    )
            } else {
                this.setState({
                    failMsg: {
                        isOpen: true,
                        msg: "Please check ReCAPTCHA field"
                    },
                })
            }

        }
    }
    closeFailMsg = () => {
        this.setState({
            failMsg: {
                isOpen: false,
                msg: ''
            }
        })
    }
    render() {
        const { form, formErrors, failMsg } = this.state
        return (
            <Container className="container my-5">
                <Row className="justify-content-center">
                    <Col sm="12" md="8">
                        <Card>
                            <CardBody>
                                <CardTitle className="text-center" tag="h3">Register</CardTitle>
                                <Form onSubmit={this.handleRegisterSubmit}>
                                    <FormGroup>
                                        <Label htmlFor="email">Email address</Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Ex. example@gmail.com"
                                            value={form.email}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup >
                                        <Label htmlFor="password">Password</Label>
                                        <Input
                                            type="password"
                                            name="password"
                                            placeholder="Enter password"
                                            value={form.password}
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <FormText className="pl-2" color="danger">
                                            {formErrors.minimum}
                                        </FormText>
                                        <FormText className="pl-2" color="danger">
                                            {formErrors.uppercase}
                                        </FormText>
                                        <FormText className="pl-2" color="danger">
                                            {formErrors.lowercase}
                                        </FormText>
                                        <FormText className="pl-2" color="danger">
                                            {formErrors.numeric}
                                        </FormText>
                                    </FormGroup>
                                    <Row form>
                                        <Col xs="12" sm="6">
                                            <FormGroup>
                                                <Label htmlFor="fname">First Name</Label>
                                                <Input
                                                    id="fname"
                                                    name="fname"
                                                    type="text"
                                                    placeholder="Enter first name"
                                                    value={form.fname}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <FormGroup>
                                                <Label htmlFor="lname">Last Name</Label>
                                                <Input
                                                    id="lname"
                                                    name="lname"
                                                    type="text"
                                                    placeholder="Enter last name"
                                                    value={form.lname}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md="12" lg="6">
                                            <FormGroup>
                                                <Label htmlFor="street">Street Address</Label>
                                                <Input
                                                    id="street"
                                                    name="street"
                                                    type="text"
                                                    placeholder="Enter your street"
                                                    value={form.street}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" lg="6">
                                            <FormGroup>
                                                <Label htmlFor="">(Optional) Apartment, unit, or floor #</Label>
                                                <Input
                                                    id="address2"
                                                    name="address2"
                                                    type="text"
                                                    placeholder="Enter your address 2"
                                                    value={form.address2}
                                                    onChange={this.handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup>
                                                <Label htmlFor="city">City/Town</Label>
                                                <Input
                                                    id="city"
                                                    name="city"
                                                    type="text"
                                                    placeholder="Enter city"
                                                    value={form.city}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup>
                                                <Label htmlFor="state">Province/State</Label>
                                                <Input
                                                    id="state"
                                                    name="state"
                                                    type="text"
                                                    placeholder="Enter province"
                                                    value={form.state}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup>
                                                <Label htmlFor="postcode">Postal code</Label>
                                                <Input
                                                    id="postcode"
                                                    name="postcode"
                                                    type="text"
                                                    placeholder="Enter postal"
                                                    value={form.postcode}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup>
                                                <Label htmlFor="country">Country</Label>
                                                <Input
                                                    id="country"
                                                    name="country"
                                                    type="text"
                                                    placeholder="Enter country"
                                                    value={form.country}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Label htmlFor="phone">Phone</Label>
                                        <Input
                                            id="phone"
                                            name="phone"
                                            type="tel"
                                            placeholder="(Example) +1 999 999 9999"
                                            value={form.phone}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </FormGroup>
                                    <div className="my-3 text-center">
                                        <ReCAPTCHA
                                            className="d-inline-block"
                                            onChange={this.onChange}
                                            ref={this._reCaptchaRef}
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        />
                                    </div>
                                    {
                                        failMsg.isOpen && (
                                            <Alert isOpen={failMsg.isOpen} color="danger" toggle={this.closeFailMsg}>
                                                {failMsg.msg}
                                            </Alert>
                                        )
                                    }
                                    <center>
                                        <Button color="primary">
                                            REGISTER
                                        </Button>
                                    </center>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}