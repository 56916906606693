import React, { Component } from 'react';
import { Container } from "reactstrap";
import ReactHelmet from '../../components/Helmet'
import { PageHeader, PageImg } from "../styles/Pages";

import iotAppJpgImg from "../../assets/images/iot-application-04.jpg";

export class PetTrackingSystem extends Component {
  
    render() {
        return (
            <>
                <ReactHelmet
                    keywords="iot, internet of things, cloud, secure, application"
                    description="Pet’s are part of the family and it is important for us to keep them safe all the time and knowing about their whereabout."
                    title="Pet Tracking System | Gateway Alfa | iotLINKS"
                />
                <PageHeader>
                    <h1>Pet Tracking System</h1>
                </PageHeader>
                <Container>
                    <PageImg src={iotAppJpgImg} />
                    <p>
                        Pet’s are part of the family and it is important for us to keep them safe all the time and knowing about
                        their whereabout. Our LORA system can track the pet around the house and if the leave the area you can be
                        notified. You can define the safety fence around the house through the App and get notified if your pet
                        crosses the fence.
                    </p>
                    <br /><br />
                </Container>
            </>
        );
    }
}