import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types'
import RouteWithProps from './helpers/RouteWithProps'
import PrivateRoute from './helpers/PrivateRoute'
import * as authentication from './utils/authAPI'
import './custom.css'
import { Home } from './views/Home';
import { IoTSolution } from './views/IoTSolution';
import { IoTDevelopment } from './views/IoTDevelopment';
import { InternetOfThings } from './views/InternetOfThings';
import { IoTConsultation } from './views/IoTConsultation';
import { LiveLabTry } from './views/LiveLabTry';
import { LiveLabWaiting } from './views/LiveLabWaiting';
import { LiveLab } from './views/LiveLab';
import { LiveLabFeedback } from './views/LiveLabFeedback';
import { Dashboard } from './views/Dashboard';
import { DeviceRegister } from './views/DeviceRegister';
import { DeviceSetting } from './views/DeviceSetting';
import { PortSetting } from './views/PortSetting';
import { HistoricalChart } from './views/HistoricalChart';
import { RealTimeAD } from './views/RealTimeAD';
import { RealTimeSerial } from './views/RealTimeSerial';
import { RealTimeTemp } from './views/RealTimeTemp';
import { Signup } from './views/Signup';
import { Login } from './views/Login';
import { FAQ } from './views/FAQ';
import { TermsConditions } from './views/TermsConditions';
import { IoTApplication } from './views/IoTApplication';
import { WaterPump } from './views/iot-applications/WaterPump';
import { SolarPanelsPower } from './views/iot-applications/SolarPanelsPower';
import { PetTrackingSystem } from './views/iot-applications/PetTrackingSystem';
import { RemoteAccessControl } from './views/iot-applications/RemoteAccessControl';
import { AirQualityControl } from './views/iot-applications/AirQualityControl';
import { IndustrialApplications } from './views/iot-applications/IndustrialApplications';
import { AgriculturalSystem } from './views/iot-applications/AgriculturalSystem';
import { GatewayAlpha } from './views/GatewayAlpha';
import { GatewayBeta } from './views/GatewayBeta';
import { EmailVerification } from './views/EmailVerification';
import { ForgotPassword } from './views/ForgotPassword';
import { UpdatePassword } from './views/UpdatePassword';
import { Profile } from './views/Profile';
import { NavMenu } from './components/NavMenu';
import { Footer } from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import OneSignal from 'react-onesignal';

export default class App extends Component {
    constructor(props) {
        super(props)
        let authState = authentication.getAuthState()
        this.state = {
            auth: {
                loggedIn: authState.userToken !== null,
                username: authState.username,
                userToken: authState.userToken,
            }
        };
        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
    }
    componentDidMount() {
        OneSignal.init({
            appId: (process.env.NODE_ENV === 'development')
                ? process.env.REACT_APP_ONESIGNAL_APP_ID_LOCALHOST
                : process.env.REACT_APP_ONESIGNAL_APP_ID_LIVE
        });
    }
    getChildContext() {
        return {
            auth: {
                loggedIn: this.state.auth.loggedIn,
                username: this.state.auth.username,
                userToken: this.state.auth.userToken,
            }
        }
    }
    login(username, password) {
        return authentication.login(username, password)
            .then(result => {
                this.setState({
                    auth: {
                        ...this.state.auth,
                        loggedIn: true,
                        username: result.username,
                        userToken: result.userToken,
                    }
                })
            })
            .catch(err => {
                this._logout()
                return Promise.reject(err)
            })
    }

    logout() {
        return authentication.logout().then(result => {
            this._logout()
        })
    }
    _logout() {
        this.setState({
            auth: {
                ...this.state.auth,
                loggedIn: false,
                username: null,
                userToken: null,
            }
        })
    }
    render() {
        return (
            <Router>
                <NavMenu logout={this.logout} />
                <Switch>
                    <ScrollToTop>
                        <Route exact path='/' component={Home} />
                        <RouteWithProps path='/login' component={Login} props={{ login: this.login }} />
                        <PrivateRoute exact path='/dashboard' component={Dashboard} />
                        <PrivateRoute path='/profile' component={Profile} />
                        <PrivateRoute exact path='/device-register' component={DeviceRegister} />
                        <PrivateRoute exact path='/device-setting' component={DeviceSetting} />
                        <PrivateRoute exact path='/port-setting' component={PortSetting} />
                        <PrivateRoute exact path='/historical-chart' component={HistoricalChart} />
                        <PrivateRoute exact path='/realtime-adconverter' component={RealTimeAD} />
                        <PrivateRoute exact path='/realtime-temperature' component={RealTimeTemp} />
                        <PrivateRoute exact path='/realtime-serial' component={RealTimeSerial} />
                        <Route exact path='/iot-development' component={IoTDevelopment} />
                        <Route exact path='/iot-solution' component={IoTSolution} />
                        <Route exact path='/internet-of-things' component={InternetOfThings} />
                        <Route exact path='/iot-consultation' component={IoTConsultation} />
                        <Route exact path='/faq' component={FAQ} />
                        <Route exact path='/iot-applications' component={IoTApplication} />
                        <Route exact path='/iot-applications/water-pump-control' component={WaterPump} />
                        <Route exact path='/iot-applications/solar-panels-power-monitoring-cleaning' component={SolarPanelsPower} />
                        <Route exact path='/iot-applications/pet-tracking-system' component={PetTrackingSystem} />
                        <Route exact path='/iot-applications/remote-access-control' component={RemoteAccessControl} />
                        <Route exact path='/iot-applications/air-quality-control' component={AirQualityControl} />
                        <Route exact path='/iot-applications/industrial-applications' component={IndustrialApplications} />
                        <Route exact path='/iot-applications/agricultural-irrigation-system' component={AgriculturalSystem} />
                        <Route exact path='/gateway-alpha' component={GatewayAlpha} />
                        <Route exact path="/gateway-alfa"><Redirect to="/gateway-alpha" /></Route>
                        <Route exact path='/gateway-beta' component={GatewayBeta} />
                        <Route exact path='/register' component={Signup} />
                        <Route exact path='/email-verification' component={EmailVerification} />
                        <Route exact path='/forgot-password' component={ForgotPassword} />
                        <Route exact path='/update-password' component={UpdatePassword} />
                        <Route exact path='/terms-and-conditions' component={TermsConditions} />
                        <Route exact path='/try-live-lab' component={LiveLabTry} />
                        <Route exact path='/live-lab-waiting' component={LiveLabWaiting} />
                        <Route exact path='/live-lab' component={LiveLab} /> 
                        <Route exact path='/live-lab-feedback' component={LiveLabFeedback} />
                        <Route exact path="/overview"><Redirect to="/" /></Route>
                    </ScrollToTop>
                </Switch>

                <Footer />
            </Router>
        );
    }
}
App.childContextTypes = {
    auth: PropTypes.shape({
        loggedIn: PropTypes.bool,
        username: PropTypes.string,
        userToken: PropTypes.string,
    })
}