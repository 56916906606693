import React, { Component } from 'react';
import { Container, Collapse } from 'reactstrap';
import styled from "styled-components";

import faqJpgImg from "../assets/images/faq.jpg";

export class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: 0,
        }
        this.toggle = this.toggle.bind(this);
    }
    toggle(e) {
        let event = e.target.dataset.event;
        this.setState({ collapse: this.state.collapse === Number(event) ? 0 : Number(event) });
    }

    render() {

        console.log(process.env.NODE_ENV);

        const items = [
            {
                id: 1,
                question: "Can I implement any application by using only the Gateway devices?",
                answer: "No, some of the applications are possible, but you may need accessories and extra modules such as sensors and actuator to completely realize your application.",
            },
            { 
                id: 2,
                question: "How should I ask my technical questions?",
                answer: "Simply go to our Forum and you may find the answer, or you can submit your inquiry there. ",
            },
            {
                id: 3,
                question: "Is iotLINKS an open-source application? ",
                answer: "No, iotLINKS uses proprietary software tools and it is not open source.",
            },
            {
                id: 4,
                question: "Do I need to know coding or special cloud development skills to use iotLINKS? ",
                answer: "No, iotLINKS is a plug and play system, even 10 years old can set up an IoT application.",
            },
            { 
                id: 5,
                question: "How should I start making my IoT application?",
                answer: "Simply buy our low-cost Gateway and register it in the cloud, then you can set up your system by some clicks and basic settings on our platform.",
            },
            {
                id: 6,
                question: "How long I need to setup my IoT system?",
                answer: "A basic setup is usually can be done in 20 minutes or so",
            },
            {
                id: 7,
                question: "How many Gateways I can have under my account?",
                answer: "There is no limit, you can have multiple Gateways at multiple locations, and all monitored from your Dashboard.",
            },
            {
                id: 8,
                question: "Can I assign Nicknames to my devices and Gateways?",
                answer: "Yes, every device has different ports, you can assign different nicknames to each port.",
            },
            {
                id: 9,
                question: "Can I save my data on iotLINKS cloud?",
                answer: "Yes, you have different options in recording and saving data on our online-database and you can setup and retrieve data as needed.",
            },
            {
                id: 10,
                question: "Does iotLINKS have its own cloud or uses a second part provider?",
                answer: "iotLINKS uses AWS cloud in the background.",
            }
            ,
            {
                id: 11,
                question: "Is iotLINKS a secure platform?",
                answer: "Yes, we are using the highest security standards in our system and authentications and our cloud relies on AWS security and reliability.",
            },
            {
                id: 12,
                question: "Can I share my account with another party to use?",
                answer: "No, this is strictly prohibited and is against our policy, so if you need to have multiple access to the same account, please contact us to help you.",
            },
            {
                id: 13,
                question: "Can I create rules to implement control systems?",
                answer: "Yes, you can create rules based on values of ports and conditions and based on that create actions on other ports.",
            },
            {
                id: 14,
                question: "I am using Gateway Alfa along WIFI connection at home, do I need to pay for Data usage? ",
                answer: "No, Data is provided by WIFI from your home and there will be no Data charges.",
            }
        ]
        const { collapse } = this.state;
        return (
            <>
                <div className="blog-bg"></div>
                <Container>
                    <div className="my-5">
                        <center>
                            <h1 className="h2 text-light mb-5">Frequently Asked Questions</h1>
                            <img
                                className="shadow rounded cover"
                                src={faqJpgImg}
                                alt="iotLINKS FAQ"
                                height="500px"
                                width="100%"
                            />
                        </center>
                    </div>
                </Container>

                <Container className="my-5">
                    {items.map((el) => {
                        return (
                            <div className="mb-3" key={el.id}>
                                <Accordion onClick={this.toggle} data-event={el.id} className="shadow-sm">
                                    <div onClick={this.toggle} data-event={el.id}>
                                        {
                                            collapse === el.id ? (
                                                <>
                                                    <i className="fas fa-angle-up mr-2 text-primary"></i>
                                                    <span className="font-weight-bold text-primary" onClick={this.toggle} data-event={el.id}>{el.question}</span>
                                                </>
                                            ) : (
                                                    <>
                                                        <i className="fas fa-angle-down mr-2 text-dark"></i>           
                                                        <span className="font-weight-bold text-dark" onClick={this.toggle} data-event={el.id}>{el.question}</span>
                                                    </>
                                                )
                                        }
                                    </div>
                                    <Collapse isOpen={collapse === el.id}>
                                        <div className="mt-3 text-muted"><p>{el.answer}</p></div>
                                    </Collapse>
                                </Accordion>
                            </div>
                        )
                    })}
                </Container>
            </>
        );
    }
}
const Accordion = styled.div`
    background-color: white;
    padding: 24px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #9C9C9C;
`;