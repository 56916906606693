import React, { Component } from "react";
import { Container, Card, CardBody, CardTitle, CardText, Row, Col, Button, Input, Form, Label, FormText, Alert, InputGroupAddon, InputGroup } from 'reactstrap';
import { Link } from "react-router-dom";
import * as deviceApi from '../utils/deviceAPI'
import { getUserByEmail } from '../utils/userAPI'
import PropTypes from 'prop-types'

export class DeviceRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            isSuccess: true,
            isCopied: false,
            form: {
                email: '',
                accountId: '',
                nickname: '',
            },
        };
        this.timezone = React.createRef();
        this.uniqueKey = React.createRef();
        this.getUniqueKey = this.getUniqueKey.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.getUserInfo()
    }
    getUserInfo = async () => {
        let user = this.context.auth.username
        if (user) {
            getUserByEmail(user).then((data) => {
                this.setState({
                    form: {
                        email: user,
                        accountId: data.accountID,
                    }
                });
            }).catch(err => {
                console.log(err.message)
            })
        }
    }
    handleChange = e => {
        const value = e.target.value;
        const name = e.target.name
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }
    onDismiss = () => {
        this.setState({ isVisible: false })
    }
    handleSubmit = e => {
        e.preventDefault();
        const { email, nickname, accountId } = this.state.form
        const time = this.timezone.current.value;
        this.onDismiss()
        this.setState({ isCopied: false })

        deviceApi.posthttp(email, accountId, nickname, time).then((data) => {
            if (data.thingName === "000000000000") this.setState({ isSuccess: false });
            else {
                this.setState({ isSuccess: true, deviceid: data.thingName })
                this.getUniqueKey();
            }
            this.setState({
                msg: data.responseMessage,
                isVisible: true
            })
        })
            .catch(error => {
                console.log(error.message)
                this.setState({
                    isSuccess: false,
                    isVisible: true,
                    msg: error.message
                })
            })
    }

    getUniqueKey() {
        const key = this.state.deviceid;
        let withEmptySpace = key.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        this.uniqueKey.current.value = withEmptySpace;
    }
    copyToClipboard = () => {
        let value = this.uniqueKey.current.value;
        let noSpacesString = value.replace(/ /g, '');
        navigator.clipboard.writeText(noSpacesString);
        this.setState({ isCopied: true });
    }
    render() {
        const { isVisible, isSuccess, form, isCopied } = this.state;
        let date = new Date();
        const localOffset = -(date.getTimezoneOffset() / 60);
        return (
            <Container className="my-5">
                <div className="mb-4">
                    <h1 className="h2">Device Register</h1>
                </div>
                <Card>
                    <CardBody>
                        <CardTitle tag="h5">To activate your device</CardTitle>
                        <CardText>Before using the service, you need to get a unique serial key to connect your device and the cloud.</CardText>
                        <hr />
                        <Form onSubmit={this.handleSubmit}>
                            <Row form>
                                <Col className="my-2" sm={12} md={4}>
                                    <Label for="nickname">Account ID</Label>
                                    <Input
                                        type="text"
                                        name="accountId"
                                        id="accountId"
                                        placeholder="Account ID"
                                        value={form.accountId || ''}
                                        onChange={this.handleChange}
                                        readOnly
                                    />
                                </Col>
                                <Col className="my-2" sm={12} md={4}>
                                    <Label for="nickname">Device Nickname</Label>
                                    <Input
                                        type="text"
                                        name="nickname"
                                        id="nickname"
                                        maxLength="30"
                                        placeholder="Device Nickname"
                                        value={form.nickname || ''}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                                <Col className="my-2" sm={12} md={4}>
                                    <Label for="timezone">Time Zone Difference [hour]</Label>
                                    <Input
                                        type="number"
                                        name="timezone"
                                        id="timezone"
                                        innerRef={this.timezone}
                                        defaultValue={localOffset}
                                        min="-12"
                                        max="12"
                                        step="0.5"
                                    />
                                </Col>
                            </Row>
                            <Button className="my-2" color="primary">REGISTER</Button>
                        </Form>
                        <hr />
                        <div className="d-inline-block">
                            <div className="m-1">
                                <FormText>Device unique key will be generated.</FormText>
                            </div>
                            <InputGroup>
                                <Input innerRef={this.uniqueKey} placeholder="XXXX XXXX XXXX" readOnly />
                                <InputGroupAddon addonType="prepend">
                                    <Button onClick={this.copyToClipboard} color={isCopied ? "success" : "primary"}>{isCopied ? <span>Copied</span> : <span>Copy</span>}</Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                        <Alert className="mt-3" isOpen={isVisible} toggle={this.onDismiss} color={isSuccess ? 'success' : 'danger'}>
                            <h4 className="alert-heading">{isSuccess ? 'Succesfull' : 'Something went wrong'}</h4>
                            <hr />
                            {
                                isSuccess ?
                                    <div>{form.nickname} is successfully generated. If you want to change detail settings of each ports, please click this link: <Link to="/device-setting">Go to device setting</Link>. </div>
                                    : <div>Sorry, please try again in few seconds. If you still face a same problem, please contact here. </div>
                            }
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
DeviceRegister.contextTypes = {
    auth: PropTypes.object
}