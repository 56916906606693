import styled from "styled-components";

export const PageHeader = styled.div`
    height: ${props => !props.bg ? '150px' : '300px'};
    background: url(${props => props.bg}) no-repeat center #3182CE;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
        color: white;
        ${props => props.bg ? 'text-shadow: 2px 2px 0 black' : ''};
        @media only screen and (max-width: 768px) {
            margin: 0 20px;
            text-align: center;
        }
    }
`;

export const PageImg = styled.div`
    height: 400px;
    background: url(${props => props.src}) no-repeat center;
    background-size: cover;
    margin: 50px 0;
`;

export const PageH2 = styled.h2`
    margin: 30px 0;
`;

export const PageH3 = styled.h3`
    margin: 40px 0 20px;
`;