import React, { Component } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input, Container, FormFeedback, Alert } from 'reactstrap';
import { putHttp, getUserByEmail } from '../utils/userAPI'
import styled from 'styled-components';

export class FormProfileGeneral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            isSuccess: false,
            successMsg: {
                isOpen: false,
                msg: ''
            },
            failMsg: {
                isOpen: false,
                msg: ''
            },
            form: {
                accountNum: '',
                email: '',
                fname: '',
                lname: '',
                street: '',
                address2: '',
                city: '',
                state: '',
                postcode: '',
                country: '',
                phone: '',
            },
            formValidity: {
                fname: true,
                lname: true,
                street: true,
                city: true,
                state: true,
                postcode: true,
                country: true,
                phone: true,
            },
            canSubmit: true,
        };
        this.closeFailMsg = this.closeFailMsg.bind(this);
    }
    componentDidMount() {
        this.getUserInfo()
    }
    getUserInfo = async () => {
        let user = this.props.user
        if (user) {
            getUserByEmail(user).then((data) => {
                this.setState({
                    form: {
                        email: user,
                        accountNum: data.accountID,
                        fname: data.firstName,
                        lname: data.lastName,
                        street: data.streetAddress,
                        address2: data.streetAddressTwo,
                        city: data.city,
                        state: data.stateProvince,
                        postcode: data.zipPostalCode,
                        country: data.country,
                        phone: data.phoneNumber,
                    }
                });
            }).catch(err => {
                this.setState({
                    failMsg: {
                        isOpen: true,
                        msg: err.message
                    }
                })
                console.log(err.message)
            })
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target
        if (name === "phone") {
            const regex = /^[+\d]?(?:[\d\s]*)$/;
            if (regex.test(event.target.value)) {
                let val = event.target.value;
                this.setState({
                    form: {
                        ...this.state.form,
                        phone: val
                    }
                }, function () { this.validateField(name, value) })
            }
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value
                }
            }, () => this.validateField(name, value))
        }
    }

    validateField(name, value) {
        if (Object.keys(this.state.formValidity).includes(name)) {
            if (value === "") {
                this.setState({
                    formValidity: {
                        ...this.state.formValidity,
                        [name]: false
                    }
                }, () => this.canSubmit())
            } else {
                this.setState({
                    formValidity: {
                        ...this.state.formValidity,
                        [name]: true
                    }
                }, () => this.canSubmit())
            }
        }
    }
    canSubmit() {
        this.setState({
            canSubmit: this.state.formValidity.fname
                && this.state.formValidity.lname
                && this.state.formValidity.phone
                && this.state.formValidity.street
                && this.state.formValidity.city
                && this.state.formValidity.state
                && this.state.formValidity.postcode
                && this.state.formValidity.country
        })
    }

    closeFailMsg = () => {
        this.setState({
            failMsg: {
                isOpen: false,
                msg: ''
            }
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        let user = this.props.user
        const data = {
            userEmail: this.state.form.email,
            firstName: this.state.form.fname,
            lastName: this.state.form.lname,
            phoneNumber: this.state.form.phone,
            streetAddress: this.state.form.street,
            streetAddressTwo: this.state.form.address2,
            city: this.state.form.city,
            stateProvince: this.state.form.state,
            country: this.state.form.country,
            zipPostalcode: this.state.form.postcode
        }
        if (this.state.canSubmit) {
            putHttp(data, user)
                .then((res) => {
                    this.setState({
                        successMsg: {
                            isOpen: true,
                            msg: res
                        }
                    }, () => {
                        window.setTimeout(() => {
                            this.setState({
                                successMsg: {
                                    isOpen: false,
                                    msg: ''
                                }
                            })
                        }, 3500)
                    })
                })
                .catch(err => {
                    this.setState({
                        failMsg: {
                            isOpen: true,
                            msg: err.message
                        }
                    })
                    console.log(err.message)
                })
        }
    }

    render() {
        const { form, formValidity, successMsg, failMsg } = this.state
        return (
            <>
                <Container className="border border-top-0">
                    <Form className="px-2 py-4" onSubmit={this.handleSubmit}>
                        <Row form>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        className={`form-control`}
                                        id="email"
                                        name="email"
                                        type="email"
                                        readOnly
                                        value={form.email}
                                    />

                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label for="accountNum">Account #</Label>
                                    <Input
                                        id="accountNum"
                                        name="accountNum"
                                        type="text"
                                        readOnly
                                        value={form.accountNum}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={12} md={4}>
                                <FormGroup>
                                    <Label for="fname">First Name</Label>
                                    <Input
                                        id="fname"
                                        name="fname"
                                        type="text"
                                        value={form.fname}
                                        onChange={this.handleChange}
                                        invalid={!formValidity.fname}
                                    />
                                    <FormFeedback>Required field*</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={4}>
                                <FormGroup>
                                    <Label for="lname">Last Name</Label>
                                    <Input
                                        id="lname"
                                        name="lname"
                                        type="text"
                                        value={form.lname}
                                        onChange={this.handleChange}
                                        invalid={!formValidity.lname}
                                    />
                                    <FormFeedback>Required field*</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={4}>
                                <FormGroup>
                                    <Label for="phone">Phone</Label>
                                    <Input
                                        id="phone"
                                        name="phone"
                                        type="tel"
                                        placeholder="(Example) +1 999 999 9999"
                                        value={form.phone}
                                        onChange={this.handleChange}
                                        invalid={!formValidity.phone}
                                    />
                                    <FormFeedback>Required field*</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12} lg={6}>

                                <FormGroup>
                                    <Label for="street">Street Address</Label>
                                    <Input
                                        id="street"
                                        name="street"
                                        type="text"
                                        placeholder="Enter your street"
                                        value={form.street}
                                        onChange={this.handleChange}
                                        invalid={!formValidity.street}
                                    />
                                    <FormFeedback>Required field*</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Label for="address2">(Optional) Apartment, unit, or suite #</Label>
                                    <Input
                                        id="address2"
                                        name="address2"
                                        type="text"
                                        placeholder="Enter your address 2"
                                        value={form.address2}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={12} md={6} lg={3}>
                                <FormGroup>
                                    <Label for="city">City/Town</Label>
                                    <Input
                                        id="city"
                                        name="city"
                                        type="text"
                                        placeholder="Enter city"
                                        value={form.city}
                                        onChange={this.handleChange}
                                        invalid={!formValidity.city}
                                    />
                                    <FormFeedback>Required field*</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                <FormGroup>
                                    <Label for="state">Province/State</Label>
                                    <Input
                                        id="state"
                                        name="state"
                                        type="text"
                                        placeholder="Enter province"
                                        value={form.state}
                                        onChange={this.handleChange}
                                        invalid={!formValidity.state}
                                    />
                                    <FormFeedback>Required field*</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                <FormGroup>
                                    <Label for="postcode">Postal/Zip code</Label>
                                    <Input
                                        id="postcode"
                                        name="postcode"
                                        type="text"
                                        placeholder="Enter postal"
                                        value={form.postcode}
                                        onChange={this.handleChange}
                                        invalid={!formValidity.postcode}
                                    />
                                    <FormFeedback>Required field*</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                <FormGroup>
                                    <Label for="country">Country</Label>
                                    <Input
                                        id="country"
                                        name="country"
                                        type="text"
                                        placeholder="Enter country"
                                        value={form.country}
                                        onChange={this.handleChange}
                                        invalid={!formValidity.country}
                                    />
                                    <FormFeedback>Required field*</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            failMsg.isOpen && (
                                <Alert isOpen={failMsg.isOpen} color="danger" toggle={this.closeFailMsg}>
                                    {failMsg.msg}
                                </Alert>
                            )
                        }
                        <Button color="primary">UPDATE INFORMATION</Button>
                    </Form>
                </Container>
                {
                    successMsg.isOpen && (
                        <StyledAlert
                            color="success"
                            isOpen={successMsg.isOpen}
                        >
                            {successMsg.msg}
                        </StyledAlert>
                    )
                }

            </>
        );
    }
}
const StyledAlert = styled(Alert)`
  padding: 20px;
  position: fixed;
  left: 0 !important;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
`;
export default FormProfileGeneral;