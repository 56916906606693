import React, { Component } from 'react';
import { Container, Col, Button, Label, Input, Form } from 'reactstrap';
import styled from "styled-components";

export class LiveLabWaiting extends Component {

    constructor(props) {
        super(props);
        const queueVal = +sessionStorage.getItem('lineNumber') || 0;
        const mins = (queueVal !== 0) ? 2 * queueVal : 2;
        this.state = {
            waitingMsg: '',
            count: (queueVal !== 0) ? 120 * queueVal : 120,
            sec: '00',
            min: (mins > 9) ? mins : '0' + mins,
            queueValue: queueVal,
            adconvertorVoltageValue: '...',
            formInputs: {
                pulseWidthModulatorDutyCycle: '',
                servomotorPosition: '',
                relayContactOutput: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.location.state || !this.props.location.state.fromTryPage) {

            this.props.history.push({ pathname: '/try-live-lab' });

        } else {

            window.history.replaceState({}, document.title);

            // let user = sessionStorage.getItem('llid');
            let lineNum = sessionStorage.getItem('lineNumber');

            if (lineNum > 0) {
                // (1)-A. If the LineNumber is more then 0, it demonstrates Waiting Time, and starts interval.
                this.displayWaitingTime(lineNum);
                this.waitTimer = setInterval(() => this.requestWaitingTime(), 5000); // Every 10 seconds 
            } else {
                // (1)-B. When lineNum not bigger than 0 ?
                this.readyForLiveLab()
            }

            const llid = sessionStorage.getItem('llid');

            fetch(`livelab/${llid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            })
                .then(res => res.json())
                .then(data => {
                    sessionStorage.setItem('labDeviceName', data);

                    // 120 seconds interval timer
                    this.timer = setInterval(() => this.runTimer(), 1000);

                })
                .catch(err => console.log(err))
        }

        window.addEventListener("beforeunload", this.onWindowUnload);
    }

    runTimer() {
        if (this.state.count >= 0) {
            let minute = Math.floor(this.state.count / 60);
            let seconds =
                this.state.count % 60 < 10
                    ? '0' + (this.state.count % 60)
                    : this.state.count % 60;
            this.setState((prevState) => ({
                min: String(minute).padStart(2, '0'),
                sec: String(seconds).padStart(2, '0'),
                count: prevState.count - 1,
            }));

            let labDevice = sessionStorage.getItem('labDeviceName');
            let llid = sessionStorage.getItem('llid');
            if (labDevice) { // Error riased: title: 'Unauthorized', status: 401 
                fetch(`livelab/controllab/${llid}?currentLLID=${llid}&labThingName=${labDevice}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    /* body: JSON.stringify(labDevice)*/
                })
                    .then(res => res.json())
                    .then(data => {
                        console.log(data);
                        this.setState({
                            adconvertorVoltageValue: (data && data.adconvertorVoltageValue)
                                ? parseFloat(data.adconvertorVoltageValue + 0.2).toFixed(3)
                                : "...",
                            formInputs: {
                                pulseWidthModulatorDutyCycle: data.pulseWidthModulatorDutyCycle,
                                relayContactOutput: data.relayContactOutput,
                                servomotorPosition: (data.pulseWidthModulatorDutyCycle - 15) * 3,
                            }

                        })
                    })
                    .catch(err => console.log(err))
            }
        } else {
            clearInterval(this.timer);
        }
    }

    componentWillUnmount() {
        clearInterval(this.waitTimer);
        clearInterval(this.timer);
        window.removeEventListener("beforeunload", this.onWindowUnload);
    }

    onWindowUnload = e => {
        console.log("Window Unload");
        e.preventDefault();
        this.finishLab();
    };

    finishLab = () => {
        clearInterval(this.timer);
        //// Sends HTTP PUT request Finish Request (URL=siteurl/livelab/finishlab/LLID) 
        let llid = sessionStorage.getItem('llid');
        let labDevice = sessionStorage.getItem('labDeviceName');
        fetch(`livelab/finishlab/${llid}?currentLLID=${llid}&labThingName=${labDevice}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(res => res.json())
            .then(data => {
                console.log(data); // The backend responds with negative number
            })
            .catch(err => console.log(err))
    }

    requestWaitingTime() {
        let lineNum = sessionStorage.getItem('lineNumber');
        let llid = sessionStorage.getItem('llid');

        //  When lineNum > 0 ?
        if (lineNum > 0) {
            // (2)-A-1. Sends HTTP PUT request Waiting Request (URL=siteurl/livelab/{LLID}) 
            fetch(`livelab/${llid}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            })
                .then(res => res.json())
                .then(data => {
                    // (2)-A-2. Update LineNumber
                    sessionStorage.setItem('lineNumber', data);
                    // (2)-A-3. Update Waiting Time on the screen 
                    this.displayWaitingTime(data);
                })
                .catch(err => console.log(err))

        } else {
            // (2)-B. When lineNum not bigger than 0 ?
            this.readyForLiveLab()
        }
    }

    displayWaitingTime(lineNum) {
        let timeLeft = lineNum * 2
        if (timeLeft === 0) {
            let msg = 'You will be redirected to the live labatory in a minute.'
            this.setState({
                waitingMsg: msg
            })
        } else {
            let msg = timeLeft + ' minutes left. Please stay on the line'
            this.setState({
                waitingMsg: msg
            })
        }

    }

    readyForLiveLab() {
        let llid = sessionStorage.getItem('llid');
        // (3) Sends HTTP GET request Ready to Live (URL=siteurl/livelab/{LLID}) 
        fetch(`livelab/${llid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(res => res.json())
            .then(data => {
                // (4) Get LabDeviceName response, and Stores to session labDeviceName
                sessionStorage.setItem('labDeviceName', data);
                // (5) Calls Live Lab page
                this.props.history.push({
                    pathname: '/live-lab',
                    state: {
                        fromWaitingPage: true
                    }
                });
            })
            .catch(err => console.log(err))
    }

    render() {
        const { min, sec, formInputs, adconvertorVoltageValue } = this.state
        return (
            <>
                <Container className='my-5'>
                    <H1>Waiting Live Lab</H1>
                    <small>Number of people in the queue: {this.state.queueValue}</small>
                    <Content>
                        <Col className='my-3' sm={12} md={8}>
                            <Video>
                                <iframe
                                    className='m-0 p-0 d-block border-0'
                                    src='https://open.ivideon.com/embed/v2/?server=100-sXrsflyxFuj25d7UOIbkYp&camera=0&width=&height=&lang=en&autoplay=1&ap'
                                    width={'100%'}
                                    height={450}
                                    frameBorder={0}
                                    allowFullScreen
                                    title='Live lab realtime video'
                                />
                            </Video>
                        </Col>
                        <Col className='my-3' sm={12} md={4}>
                            <FormContainer>
                                <Form>
                                    <FormGroupEl className='d-flex flex-column' timer="true">
                                        <small>Waiting Time</small>
                                        <strong>
                                            {min}:{sec}
                                        </strong>
                                    </FormGroupEl>
                                    <FormGroupEl>
                                        <Label className='font-weight-bold' for='relayContactOutput'>LED Light</Label>
                                        <br />
                                        <div className='toggle-switch'>
                                            <input
                                                type='checkbox'
                                                id='relayContactOutput'
                                                name='relayContactOutput'
                                                className='toggle-switch-checkbox'
                                                disabled
                                                style={{ pointerEvents: "none" }}
                                                checked={formInputs.relayContactOutput || false}
                                            />
                                            <label className='toggle-switch-label' htmlFor='relayContactOutput'
                                                style={{ pointerEvents: "none" }}>
                                                <OnOff
                                                    className='toggle-switch-inner'
                                                    data-yes='ON'
                                                    data-no='OFF'
                                                />
                                                <span className='toggle-switch-switch' />
                                            </label>
                                        </div>
                                        <SwitchText on={(formInputs.relayContactOutput) ? "true" : ""}>
                                            {formInputs.relayContactOutput ? 'Energized' : 'Deenergized'}
                                        </SwitchText>
                                        <Label for='servomotorPosition' className='font-weight-bold'>Servomotor Position (0~180)</Label>
                                        <InputContainer>
                                            <InputPulse
                                                type='number'
                                                id='servomotorPosition'
                                                name='servomotorPosition'
                                                min='0'
                                                max='180'
                                                required
                                                disabled
                                                value={
                                                    (parseInt(formInputs.servomotorPosition))
                                                        ? parseInt(formInputs.servomotorPosition)
                                                        : ''
                                                }
                                            />
                                            <UpdateButton disabled>Set</UpdateButton>
                                        </InputContainer>
                                    </FormGroupEl>
                                    <FormGroupEl className='d-flex flex-column' timer="true">
                                        <small>Voltage [V]</small>
                                        <strong>{adconvertorVoltageValue}</strong>
                                    </FormGroupEl>
                                </Form>
                            </FormContainer>
                        </Col>
                    </Content>
                </Container>
            </>
        );
    }
}

const H1 = styled.h1`
    font-size: 2.4rem;
    font-weight: 700;
    color: #314673;
    margin-bottom: 0;
`;

const Content = styled.div`
    margin-top: 10px;
    display: flex;
`;

const Video = styled.div`
    overflow: hidden;
    border-radius: 10px;
    height: 100%;
    position: relative;
    span {
        position: absolute;
        top: 0; left: 0;
        bottom: 0; right: 0;
        background: #007bff;
        mix-blend-mode: multiply;
    }
    div {
        width: 100px; height: 100px;
        border: solid 1px #72b4fe;
        background: transparent;
        border-radius: 100%;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        :before {
            content: "";
            display: block;
            background: #72b4fe;
            position: absolute;
            top: 8px; left: 8px;
            bottom: 8px; right: 8px;
            border-radius: 100%;
            box-shadow: 0 0 15px 0 #72b4fe77;
        }
        i {
            display: block;
            position: absolute;
            top: 52%; left: 56%;
            transform: translate(-50%, -50%) scaleX(1.5);
            color: white;
            font-size: 3rem;
        }
    }
`;

const FormContainer = styled.div`
    background: #f3f3f3;
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
`;

const FormGroupEl = styled.div`
    padding: 20px;
    ${props => (props.timer)
        ? "background:#c4c4c4;color:white;text-align:center;"
        : ""
    }
    small {
        font-size: 1rem;
        font-weight: bold;
    }
    strong {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: bold;
    }
`;

const SwitchText = styled.div`
    font-size: smaller;
    margin-bottom: 20px;
    ${props => (props.on)
        ? "color:#2884d9;"
        : ""
    }
`;

const InputContainer = styled.div`
    display: flex;
`;

const UpdateButton = styled(Button)`
    float: right;
    background: #c4c4c4;
    color: white;
    width: 150px;
    border: none;
    border-radius: 10px;
    pointer-events: none;
    margin-left: 10px;
    :disabled {
        background: #c4c4c4;
        opacity: 1;
    }
`;

const OnOff = styled.span`
    :before {
        background: #90CAF9;
    }
`;

const InputPulse = styled(Input)`
    border-radius: 10px;
    border: none;
    :disabled {
        background: white;
        opacity: 1;
        color: #ccc;
    }
`;