import React, { Component } from "react";
import { Container, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types'
import { FormProfilePwd } from '../views/FormProfilePwd';
import { FormProfileGeneral } from '../views/FormProfileGeneral';


export class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };
        this.toggle = this.toggle.bind(this);
    }
    componentDidMount() {

    }

    toggle(tab) {
        this.setState({
            activeTab: tab
        });
    }


    render() {
        return (
            <Container className="my-5">
                <div className="mb-4">
                    <h1 className="h2">Profile Setting</h1>
                </div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className="pointer"
                            active={this.state.activeTab === '1'}
                            onClick={() => { this.toggle('1'); }}
                        >
                            General Setting
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className="pointer"
                            active={this.state.activeTab === '2'}
                            onClick={() => { this.toggle('2'); }}
                        >
                            Update Password
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <FormProfileGeneral user={this.context.auth.username} />
                    </TabPane>
                    <TabPane tabId="2">
                        <FormProfilePwd user={this.context.auth.username} />
                    </TabPane>
                </TabContent>
            </Container>
        );
    }

}
Profile.contextTypes = {
    auth: PropTypes.object
}
