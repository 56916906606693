import { Component } from "react";
import { withRouter } from "react-router";
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }
    render() {
        return this.props.children;
    }
}
export default withRouter(ScrollToTop);

//import React, { Component } from 'react';
//import { useLocation } from 'react-router-dom'

//export class ScrollToTop extends Component {
//    componentDidMount() {
//        window.scrollTo(0, 0)
//    }
//    getSnapshotBeforeUpdate(prevProps, prevState) {
//        const { location = {} } = prevProps;
//        if (this.props.location.pathname !== location.pathname) {
//            window.scrollTo(0, 0);
//        }
//    }
//    render() {
//        return this.props.children;
//    }
//}