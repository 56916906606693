import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { hydrate, render } from "react-dom";
import App from './App';
import { unregister } from './registerServiceWorker';
import TagManager from 'react-gtm-module';

TagManager.initialize({
    gtmId: 'GTM-N3J67VZ'
});

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}

unregister();