import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Container, Card, CardTitle, CardBody, Form, Input, Label, FormGroup, Alert, Button } from "reactstrap";
import { Redirect } from 'react-router';
import PropTypes from 'prop-types'

export class Login extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            form: {
                email: "",
                password: "",
            },
            failMsg: {
                isOpen: false,
                msg: ''
            },
        };
    }

    handleChange = (event) => {
        let { name, value } = event.target
        if (name === "email") {
            value = value.toLowerCase();
        }
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }


    handleLogin = async (e) => {
        //7sarabeuty_rpg@taikz.com ()
        //kay.k@arshon.com
        //awajdi@relay-bossku3.com
        //Test@1234
        //1234Test
        e.preventDefault();
        this.setState({failMsg: {isOpen: false}})
        let data = this.state.form
        this.props.login(data)
            .then(() => this.setState({ redirectToReferrer: true }))
            .catch(err => {
                this.setState({
                    failMsg: {
                        isOpen: true,
                        msg: err.message
                    },
                })
            })
    }
    closeFailMsg = () => {
        this.setState({
            failMsg: {
                isOpen: false,
                msg: ''
            }
        })
    }
    render() {
        const { from } = this.props.location.state || '/'
        const { redirectToReferrer, form, failMsg } = this.state
        return (
            <>
                <Container className="container my-5">
                    <div>
                        {redirectToReferrer && (
                            <Redirect to={from || '/dashboard'} />
                        )}
                        {/*{from && (*/}
                        {/*    <p>*/}
                        {/*        You need to log in if you want to see*/}
                        {/*        <code>{from.pathname}</code>*/}
                        {/*    </p>*/}
                        {/*)}*/}
                        {this.context.auth.loggedIn ? (
                            <p>
                                You're already logged in.
                            </p>
                        ) : (
                                <Row className="justify-content-center">
                                    <Col sm="12" md="8">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="text-center" tag="h3">Login</CardTitle>
                                            </CardBody>
                                            <CardBody>
                                                <Form onSubmit={this.handleLogin}>
                                                    <FormGroup>
                                                        <Label htmlFor="email">Email address</Label>
                                                        <Input
                                                            id="email"
                                                            name="email"
                                                            type="text"
                                                            placeholder="Enter email"
                                                            value={form.email}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label htmlFor="password">Password</Label>
                                                        <Input
                                                            id="password"
                                                            type="password"
                                                            name="password"
                                                            placeholder="Enter password"
                                                            value={form.password}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Button block color="primary">
                                                            LOGIN
                                                        </Button>
                                                    </FormGroup>
                                                </Form>
                                                {
                                                    failMsg.isOpen && (
                                                        <Alert isOpen={failMsg.isOpen} color="danger" toggle={this.closeFailMsg}>
                                                            {failMsg.msg}
                                                        </Alert>
                                                    )
                                                }
                                            </CardBody>
                                        </Card>
                                        <Row className="mt-4">
                                            <Col xs="12" sm="6">
                                                <Link to="/forgot-password">Forgot password?</Link>
                                            </Col>
                                            <Col className="text-sm-right" xs="12" sm="6">
                                                <Link to="/register">Don't have an account?</Link>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            )}
                    </div>
                </Container>             
                </>
        );
    }
}
Login.contextTypes = {
    auth: PropTypes.object
}
Login.propTypes = {
    login: PropTypes.func
}
